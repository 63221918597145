const timeRegExp = /(^([0-2]?[0-9]h(\s)[0-6]?[0-9]m)$)|(^([0-6]?[0-9]m)$)|(^([0-2]?[0-9]h)$)/;

const prettifyNumber = (number: number) => {
  if (number < 10) return `0${number}`;
  return `${number}`;
};

export const getTimeFromHoursAndMinutes = (hours: number, minutes: number): number =>
  Number((hours + minutes / 60).toFixed(2));

export const getHoursAndMinutesFromTime = (time: number, full?: boolean): string => {
  const allTimeMinutes = Math.round(time * 60);
  const hours = Math.floor(time);
  const minutes = allTimeMinutes - hours * 60;
  if (full) return `${prettifyNumber(hours)}h ${prettifyNumber(minutes)}m`;
  if (hours === 0) return `${prettifyNumber(minutes)}m`;
  if (minutes === 0) return `${prettifyNumber(hours)}h`;
  return `${prettifyNumber(hours)}h ${prettifyNumber(minutes)}m`;
};

export const validateTime = (value: string, canBeNullable?: boolean): { error?: string; time: number } => {
  const trimmedValue = value.trim();

  if (!timeRegExp.test(trimmedValue)) {
    return { error: `Please enter time value in format like this: "12h 23m" or "12h" or "23m"`, time: 0 };
  }
  const parts = trimmedValue.split(' ');
  if (parts[0] && parts[1]) {
    const hours = Number(parts[0].replace('h', ''));
    const minutes = Number(parts[1].replace('m', ''));
    if (hours === 0 && minutes === 0) {
      return canBeNullable ? { time: 0 } : { error: 'Time must be more then 1 minute', time: 0 };
    }
    if (!(hours <= 24 && hours >= 0)) {
      return { error: 'Time must not be more then 24 hours', time: 0 };
    }
    if (!(minutes <= 60 && minutes >= 0)) {
      return { error: 'Minutes must not be more then 60', time: 0 };
    }

    if (hours === 24 && minutes !== 0) {
      return { error: 'Time must not be more then 24 hours', time: 0 };
    }

    return { time: getTimeFromHoursAndMinutes(hours, minutes) };
  }
  const resString = parts[0];
  const splittedString = resString.split('');
  const letter = splittedString[splittedString.length - 1];
  if (letter === 'h') {
    const hours = Number(resString.replace('h', ''));
    if (hours === 0) return canBeNullable ? { time: 0 } : { error: 'Time must be more then 1 minute', time: 0 };
    if (!(hours <= 24 && hours > 0)) {
      return { error: 'Time must not be more then 24 hours', time: 0 };
    }

    return { time: hours };
  }

  const minutes = Number(resString.replace('m', ''));
  if (minutes === 0) return canBeNullable ? { time: 0 } : { error: 'Time must be more then 1 minute', time: 0 };
  if (!(minutes <= 60 && minutes > 0)) {
    return { error: 'Minutes must not be more then 60', time: 0 };
  }

  return { time: getTimeFromHoursAndMinutes(0, minutes) };
};
