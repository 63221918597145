import styled from 'styled-components';

export const SelectStyles = {
  option: (provided: any, state: any) => ({
    ...provided,
    borderBottom: 'none',
    color: state.isSelected ? '#24C9FC' : '#001E66',
    background: 'none',
    cursor: 'pointer',
    '&:hover': {
      color: '#24C9FC',
    },
  }),
  singleValue: (provided: any, state: any) => ({
    ...provided,
    color: '#004AFF',
    padding: '3px 0',
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    width: '100%',
    height: '100%',
    background: 'rgba(255, 255, 255, 0.8)',
    display: 'flex',
    paddingLeft: '47px',
  }),
  container: (provided: any, state: any) => ({
    height: '100%',
    color: '#fff',
    borderColor: '#004AFF',
    cursor: 'pointer',
    marginBottom: 0,
    zIndex: 1000,
    '&:hover': {
      background: state.selectProps.menuIsOpen ? 'inherit' : '#004AFF',
    },
  }),
  menu: (provided: any, state: any) => ({
    ...provided,
    marginTop: 3,
  }),
  dropdownIndicator: (provided: any, state: any) => ({
    ...provided,
    paddingRight: '40px',
    color: state.selectProps.error ? '#f44336' : '#004AFF',
    transition: 'all .2s ease',
    transform: state.selectProps.menuIsOpen ? 'rotateX(180deg)' : null,
  }),
  placeholder: (provided: any, state: any) => ({
    ...provided,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.15px',
    color: state.selectProps.error ? '#f44336' : '#004AFF',
  }),
  indicatorSeparator: () => null,
};

export const SelectWrapper = styled.div`
  position: relative;
  width: 100%;
  margin: 8px;
  height: 56px;
  margin-bottom: ${({ error, noMargin }: { error: boolean; noMargin: boolean }) =>
    error || noMargin ? '0' : 'inherit'};
  & .Icon {
    top: 20px;
    left: 18px;
    position: absolute;
  }
`;

export const Label = styled.span`
  position: absolute;
  top: 3px;
  left: 47px;
  color: ${({ error }: { error: boolean }) => (error ? '#f44336' : '#004AFF')};
  font-size: 12px;
  letter-spacing: 0.15px;
`;
