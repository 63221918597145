import React from 'react';
import clsx from 'clsx';
import {
  FormControl,
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import {
  Wrapper,
  useStyles,
} from './styles';

const CustomInput = (props: any) => {
  const classes = useStyles();
  const { className, children } = props;
  return (
    <Wrapper>
      <FormControl fullWidth className={clsx(classes.margin, className)} variant="outlined">
        { children }
        <TextField
          {...props}
        />
      </FormControl>
    </Wrapper>
  );
};

export default CustomInput;
