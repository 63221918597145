import { Button } from '@material-ui/core';
import { Timeline } from '@material-ui/lab';
import styled from 'styled-components';

export const Container = styled(Timeline)`
  position: relative;
`;

export const DeleteButton = styled(Button)`
  color: red !important;
  position: absolute !important;
  z-index: 1;
  right: 0;
  top: 0;
  width: fit-content;
`;
