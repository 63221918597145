import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import styled from 'styled-components';

export const Wrapper = styled.div``;

export const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
}));
