import React from 'react';
import NotificationsList from 'components/NotificationsList';
import Dashboard from 'layouts/Dashboard/Dashboard';

const NotificationPage = () => (
  <Dashboard>
    <NotificationsList />
  </Dashboard>
);

export default NotificationPage;
