import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Container, EmptyMessage } from './styles';
import TaskCard from 'components/TaskCard';
import DashboardLayout from 'layouts/Dashboard';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { getRequestProcessedTasks, getEditError } from 'store/tasks/tasksSelectors';

const RequestProcessed = () => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const tasks = useSelector(getRequestProcessedTasks);
  const editError = useSelector(getEditError);

  const handleClose = (_: any, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setTimeout(() => {
      setSnackbarOpen(false);
    }, 6000);
  };

  return (
    <DashboardLayout>
      <Container>
        {tasks.length !== 0 ? (
          tasks.map(task => <TaskCard key={task.id} task={task} setSnackbarOpen={setSnackbarOpen} />)
        ) : (
          <EmptyMessage>No new tasks</EmptyMessage>
        )}
      </Container>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        {editError ? (
          <MuiAlert severity="error">Ooops...error, please reload page and try again</MuiAlert>
        ) : (
          <MuiAlert severity="success">Task was added to archive</MuiAlert>
        )}
      </Snackbar>
    </DashboardLayout>
  );
};

export default RequestProcessed;
