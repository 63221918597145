import React, { useEffect, useRef, useState } from 'react';
import CreateMessageForm from './CreateMessageForm';
import ConfirmModal from '../ConfirmModal';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import { Wrapper, MessagesContainer, MessageBottom, TextElements } from './styles';
import ChatMessages from 'components/ChatMessage';
import { ChatItem, FilesList } from '../../store/chatTask/chatTaskTypes';

const muiBaseTheme = createMuiTheme();

type CommentsProps = {
  taskId?: string;
  data: ChatItem[];
  sendLoading: boolean;
  sendMessage: (value: string, file: FilesList[], filesData: File[]) => void;
  handleRemove: (data: ChatItem) => void;
  handleUpdateMessage: (idMessage: string, message: string, filesList: FilesList[], filesData: File[]) => void;
  handleEditMessage: (files: FilesList[]) => void;
  handleDownloadFile: (file: FilesList) => void;
};

const Comments: React.FC<CommentsProps> = ({
  taskId,
  data,
  sendLoading,
  sendMessage,
  handleRemove,
  handleUpdateMessage,
  handleEditMessage,
  handleDownloadFile,
}) => {
  const bottomRef: any = useRef(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [selectElement, setSelectElement] = useState<ChatItem | null>(null);

  const closeModal = () => {
    setShowModal(false);
    setSelectElement(null);
  };

  const removeElementMessage = (element: ChatItem) => {
    setShowModal(true);
    setSelectElement(element);
  };

  const confirmRemoveElement = () => {
    selectElement && handleRemove(selectElement);
    setShowModal(false);
  };

  const handleCloseEdit = () => {
    setIsEdit(false);
    setSelectElement(null);
    handleEditMessage([]);
  };

  const editElementMessage = (element: ChatItem) => {
    setIsEdit(true);
    setSelectElement(element);
    handleEditMessage(element.files);
  };

  const updateMessage = (description: string, filesList: FilesList[], filesData: File[]) => {
    if (selectElement) {
      handleUpdateMessage(selectElement.id, description, filesList, filesData);
      setIsEdit(false);
    }
  };

  useEffect(() => {
    if (bottomRef) {
      bottomRef.current.scrollTop = bottomRef.current.scrollHeight;
    }
  }, [data]);

  return (
    <ThemeProvider theme={muiBaseTheme}>
      <Wrapper>
        <MessagesContainer ref={bottomRef}>
          {data.length === 0 ? (
            <TextElements>No messages</TextElements>
          ) : (
            data.map(item => (
              <ChatMessages
                key={item.id}
                side={item.align}
                author={item.nameAuthor}
                message={item}
                handleRemove={removeElementMessage}
                handleEdit={editElementMessage}
                handleDownloadFile={handleDownloadFile}
              />
            ))
          )}
          <MessageBottom className="bottomBlock" />
        </MessagesContainer>
        <CreateMessageForm
          isEdit={isEdit}
          editMessage={selectElement?.description}
          idMessage={selectElement?.id}
          loading={sendLoading}
          taskId={taskId}
          sendMessage={sendMessage}
          handleCloseEdit={handleCloseEdit}
          handleUpdateMessage={updateMessage}
        />
      </Wrapper>
      <ConfirmModal
        open={showModal}
        title="Remove message"
        description="Are you sure you want to delete the message?"
        handleClose={closeModal}
        handleConfirm={confirmRemoveElement}
      />
    </ThemeProvider>
  );
};

Comments.defaultProps = {
  taskId: undefined,
};

export default Comments;
