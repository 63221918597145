import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
// Styles
import { Wrapper, LogoWrapper, NameCompany, LogoCompany, SendButton, SpanText, FormWrapper } from './styles';
// Components
import AuthLayout from '../../layouts/Auth';
import CustomInput from '../../components/CustomInput';
import logo from '../../assets/images/logo.svg';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import { IconButton, InputAdornment } from '@material-ui/core';
// Utils
import { validateEmail } from 'utils/validate';
import { login } from 'store/users/usersActions';
import { getIsLoading, getIsLoggedIn, getLoginError } from 'store/users/usersSelectors';

interface State {
  email: string;
  password: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    spinner: {
      margin: '0 auto',
      display: 'block',
      color: '#fff',
      width: '35px !important',
      height: '35px !important',
    },
    helperText: {
      position: 'absolute',
      top: '100%',
    },
  }),
);

const Login = () => {
  // Hooks
  const classes = useStyles();
  const isLoggedIn = useSelector(getIsLoggedIn);
  const isLoading = useSelector(getIsLoading);
  const error = useSelector(getLoginError);
  const dispatch = useDispatch();
  const redirect = useHistory();
  const [loading, setLoading] = useState(false);

  // Form control and handler\
  const { register, handleSubmit, errors } = useForm();
  const onSubmit = async ({ email, password }: State) => {
    setLoading(true);
    await dispatch(login(email, password));
    setLoading(false);
  };

  useEffect(() => {
    if (isLoggedIn && !isLoading) {
      redirect.push('/');
    }
  }, [isLoggedIn, redirect, isLoading]);

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <AuthLayout>
      <Wrapper>
        <LogoWrapper>
          <LogoCompany src={logo} />
          <NameCompany>
            FB <br />
            Task Manager
          </NameCompany>
        </LogoWrapper>
        <FormWrapper id="loginForm" onSubmit={handleSubmit(onSubmit)}>
          <CustomInput
            variant="outlined"
            name="email"
            type="email"
            label="Email"
            autoFocus
            inputRef={register({
              validate: (value): boolean => validateEmail(value),
            })}
            error={Boolean(errors.email)}
            helperText={errors.email ? 'Enter correct email' : null}
            FormHelperTextProps={{ className: classes.helperText }}
          />
          <CustomInput
            variant="outlined"
            type={showPassword ? 'text' : 'password'}
            name="password"
            label="Password"
            inputRef={register({
              validate: (value): boolean => value.length >= 5,
            })}
            error={Boolean(errors.password)}
            helperText={errors.password ? 'Minimun 5 symbols length' : null}
            FormHelperTextProps={{ className: classes.helperText }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton aria-label="toggle password visibility" edge="end" onClick={handleShowPassword}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {error && (
            <Alert variant="outlined" severity="error">
              Invalid Login: {error}
            </Alert>
          )}
        </FormWrapper>
        <SendButton form="loginForm" type="submit" disabled={loading}>
          <SpanText>{loading ? <CircularProgress className={classes.spinner} /> : 'Log in'}</SpanText>
        </SendButton>
      </Wrapper>
    </AuthLayout>
  );
};

export default Login;
