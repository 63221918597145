import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import {
  FlexWrapper,
  ProjectBlock,
  ProjectIconBlock,
  ProjectIcon,
  ProjectContent,
  NameProject,
  DescriptionProject,
  Wrapper,
  TimeText,
  StyledInputName,
  AddTimelogButton,
  ErrorMessageBlock,
  SelectStyles,
  ColumnWrapper,
  BottomWrapper,
} from './styles';
import CreateIcon from '@material-ui/icons/Create';
import SaveIcon from '@material-ui/icons/Save';
import TimelogModal from './TimelogModal';
import { useDispatch, useSelector } from 'react-redux';
import { createTimelog } from 'store/timelogs/timelogsActions';
import TimeIndicator from 'components/TimeIndicator';
import { getHoursAndMinutesFromTime, validateTime } from './TimelogModal/helpers';
import dayjs from 'dayjs';
import { Priority, TaskStore, Status } from 'store/tasks/tasksTypes';
import { getProjects } from 'store/projects/projectsSelectors';
import { getCurrentUser, getWorkers, getUserById } from 'store/users/usersSelectors';
import { PRIORITY_OPTIONS } from 'utils/constants';
import Select from 'react-select';
import { OptionGroup } from 'utils/commonTypes';
import { IconButton, TextField, Tooltip } from '@material-ui/core';
import { fromStoreTaskToAPITask } from 'store/tasks/tasksHelpers';
import { updateTask } from 'store/tasks/tasksActions';
import { STATUSES_OPTIONS } from '../../utils/constants';
import firebase from 'firebase/app';
import SvgSprite from 'components/SvgSprite';
import { Prompt } from 'react-router-dom';
import { NotificationsActive, NotificationsOff } from '@material-ui/icons';

interface TaskHeader {
  task: TaskStore | null;
}

interface InputsStateProps {
  plannedEndDate: { isHovered: boolean; isEditing: boolean; value: Date | null };
  estimate: { isHovered: boolean; isEditing: boolean; value: number };
  worker: { isHovered: boolean; isEditing: boolean; value: string | null };
  priority: { isHovered: boolean; isEditing: boolean; value: Priority };
  status: { isHovered: boolean; isEditing: boolean; value: Status };
  project: { isHovered: boolean; isEditing: boolean; value: string };
}

type InputsStateKeys = 'plannedEndDate' | 'estimate' | 'worker' | 'priority' | 'status' | 'project';

const getDateComleted = ({
  inputsStatus,
  taskStatus,
  taskDateCompleted,
}: {
  inputsStatus: Status;
  taskStatus: Status;
  taskDateCompleted: firebase.firestore.Timestamp | null;
}) => {
  if (inputsStatus !== 'completed') return null;
  if (inputsStatus !== taskStatus) return new Date();
  return taskDateCompleted?.toDate() || null;
};

const InfoTaskHeader: React.FC<TaskHeader> = ({ task }) => {
  const [timelogModalOpen, setTimelogModalOpen] = useState(false);
  const [inputsState, setInputsState] = useState<InputsStateProps>({
    plannedEndDate: { isHovered: false, isEditing: false, value: task?.dateEnd ? task.dateEnd.toDate() : null },
    estimate: { isHovered: false, isEditing: false, value: task?.estimate || 0 },
    worker: { isHovered: false, isEditing: false, value: task?.userId || null },
    priority: { isHovered: false, isEditing: false, value: task?.priority || 'low' },
    status: { isHovered: false, isEditing: false, value: task?.status || 'created' },
    project: { isHovered: false, isEditing: false, value: task?.idProject || '' },
  });

  const [estimateError, setEstimateError] = useState<string | null>(null);
  const [estimateString, setEstimateString] = useState<string>('');

  const dispatch = useDispatch();

  const currentUser = useSelector(getCurrentUser);
  const taskWorker = useSelector(getUserById(inputsState.worker.value || ''));
  const projects = useSelector(getProjects);
  const workers = useSelector(getWorkers);

  const workerOptions: OptionGroup = useMemo(
    () =>
      Object.values(workers).reduce(
        (acc, worker) => ({ ...acc, [worker.id]: { label: worker.name, value: worker.id } }),
        {},
      ),
    [workers],
  );

  const projectOptions: OptionGroup = useMemo(
    () =>
      Object.values(projects).reduce(
        (acc, project) => ({
          ...acc,
          [project.id]: { label: project.name, value: project.id },
        }),
        {},
      ),
    [projects],
  );

  const project = useMemo(() => projects[task?.idProject || ''], [task, projects]);

  const isEditing = useMemo(
    () =>
      inputsState.estimate.isEditing ||
      inputsState.plannedEndDate.isEditing ||
      inputsState.priority.isEditing ||
      inputsState.project.isEditing ||
      inputsState.status.isEditing ||
      inputsState.worker.isEditing,
    [inputsState],
  );

  const isSubscribed = useMemo(() => {
    if (!task || !currentUser) return false;
    return task.subscribedUsers ? task.subscribedUsers.includes(currentUser.id) : false;
  }, [task, currentUser]);

  useEffect(() => {
    setInputsState({
      priority: { isHovered: false, isEditing: false, value: task?.priority || 'low' },
      worker: { isHovered: false, isEditing: false, value: task?.userId || null },
      plannedEndDate: { isHovered: false, isEditing: false, value: task?.dateEnd?.toDate() || null },
      estimate: { isHovered: false, isEditing: false, value: task?.estimate || 0 },
      status: { isHovered: false, isEditing: false, value: task?.status || 'created' },
      project: { isHovered: false, isEditing: false, value: task?.idProject || '' },
    });
    setEstimateString(getHoursAndMinutesFromTime(task?.estimate || 0));
  }, [task]);

  const getIsEditing = useCallback(
    (name: InputsStateKeys) => {
      const keys = Object.keys(inputsState) as (keyof InputsStateProps)[];
      let result = false;
      keys.forEach(key => {
        if (key === name || !inputsState[key].isEditing) return;
        result = true;
      });
      return result;
    },
    [inputsState],
  );

  const onEditClick = useCallback(
    (name: InputsStateKeys) => {
      if (!task || getIsEditing(name)) return;
      if (!inputsState[name].isEditing) {
        setInputsState(prev => ({ ...prev, [name]: { ...prev[name], isEditing: true } }));
        return;
      }
      if (estimateError) return;

      const { task: newTask, id } = fromStoreTaskToAPITask({
        ...task,
        priority: inputsState.priority.value,
        userId: inputsState.worker.value,
        estimate: inputsState.estimate.value,
        status: inputsState.status.value,
        idProject: inputsState.project.value || '',
      });

      dispatch(
        updateTask(
          {
            ...newTask,
            dateCompleted: getDateComleted({
              inputsStatus: inputsState.status.value,
              taskStatus: task.status,
              taskDateCompleted: task.dateCompleted,
            }),
            dateEnd: inputsState.plannedEndDate.value ? new Date(inputsState.plannedEndDate.value) : null,
          },
          id,
        ),
      );
      setInputsState(prev => ({ ...prev, [name]: { ...prev[name], isEditing: false } }));
    },
    [inputsState, estimateError, task, dispatch, getIsEditing],
  );

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement> | { target: { name: string; value: string | null } }) => {
      const { name, value } = e.target;
      if (!inputsState[name as keyof InputsStateProps]?.isEditing) return;

      if (name === 'estimate') {
        setEstimateString(value || '');

        const { error, time } = validateTime(value || '', true);

        if (error) {
          setEstimateError(error);
          return;
        }

        setEstimateError(null);
        setInputsState(prev => ({
          ...prev,
          [name as keyof InputsStateKeys]: {
            ...prev[name as keyof InputsStateProps],
            value: time,
          },
        }));
        return;
      }

      setInputsState(prev => ({
        ...prev,
        [name as keyof InputsStateKeys]: {
          ...prev[name as keyof InputsStateProps],
          value,
        },
      }));
    },
    [inputsState],
  );

  const onHover = useCallback(
    (name: InputsStateKeys) =>
      !getIsEditing(name) && setInputsState(prev => ({ ...prev, [name]: { ...prev[name], isHovered: true } })),
    [getIsEditing],
  );

  const onBlur = useCallback(
    (name: InputsStateKeys) =>
      !getIsEditing(name) && setInputsState(prev => ({ ...prev, [name]: { ...prev[name], isHovered: false } })),
    [getIsEditing],
  );

  const onTimelogModalClose = () => setTimelogModalOpen(false);

  const onTimelogModalSubmit = useCallback(
    async (data: { time: number; workDate: Date; description: string }) => {
      if (!task || !currentUser) return;
      await dispatch(
        createTimelog({
          description: data.description,
          time: data.time,
          workDate: data.workDate,
          creationDate: new Date(),
          userId: currentUser?.id,
          taskId: task.id,
          projectId: task.idProject,
        }),
      );
      onTimelogModalClose();
    },
    [currentUser, task, dispatch],
  );

  const onToggleSubscribed = useCallback(() => {
    if (!task || !currentUser) return;
    const { task: newTask, id } = fromStoreTaskToAPITask({
      ...task,
      subscribedUsers: isSubscribed
        ? task.subscribedUsers.filter(userId => userId !== currentUser.id)
        : [...task.subscribedUsers, currentUser.id],
    });
    dispatch(updateTask(newTask, id));
  }, [task, isSubscribed, currentUser, dispatch]);

  return (
    <>
      <Prompt when={isEditing} message={() => 'Your current changes will be removed. Would you like to continue?'} />
      <FlexWrapper>
        <ColumnWrapper>
          {/* projectName */}
          <ProjectBlock>
            <ProjectIconBlock
              onClick={() => onEditClick('project')}
              onMouseOver={() => onHover('project')}
              onMouseOut={() => onBlur('project')}
              borderColor={inputsState.project.isEditing || inputsState.project.isHovered ? '#3f51b5' : '#4c7ffd'}
              cursor={!getIsEditing('project')}
            >
              {inputsState.project.isEditing && <SaveIcon color="primary" />}
              {inputsState.project.isHovered && !inputsState.project.isEditing && <CreateIcon color="primary" />}
              {!inputsState.project.isEditing && !inputsState.project.isHovered && <ProjectIcon name="folder" />}
            </ProjectIconBlock>
            <ProjectContent>
              <NameProject>Project name:</NameProject>
              {inputsState.project.isEditing ? (
                <Select
                  backspaceRemovesValue
                  escapeClearsValue
                  isSearchable
                  name="worker"
                  onChange={option => onChange({ target: { name: 'project', value: option?.value || null } })}
                  styles={SelectStyles}
                  defaultValue={inputsState.project.value ? projectOptions[inputsState.project.value] : null}
                  options={Object.values(projectOptions)}
                />
              ) : (
                <DescriptionProject>{project?.name || ''}</DescriptionProject>
              )}
            </ProjectContent>
          </ProjectBlock>

          {/* worker */}
          <ProjectBlock>
            <ProjectIconBlock
              onClick={() => onEditClick('worker')}
              onMouseOver={() => onHover('worker')}
              onMouseOut={() => onBlur('worker')}
              borderColor={inputsState.worker.isEditing || inputsState.worker.isHovered ? '#3f51b5' : '#2196f3'}
              cursor={!getIsEditing('worker')}
            >
              {inputsState.worker.isEditing && <SaveIcon color="primary" />}
              {inputsState.worker.isHovered && !inputsState.worker.isEditing && <CreateIcon color="primary" />}
              {!inputsState.worker.isEditing && !inputsState.worker.isHovered && (
                <ProjectIcon name="worker" fill="#2196f3" />
              )}
            </ProjectIconBlock>
            <ProjectContent>
              <NameProject>Worker name:</NameProject>
              {inputsState.worker.isEditing ? (
                <Select
                  name="worker"
                  onChange={option => onChange({ target: { name: 'worker', value: option?.value || null } })}
                  styles={SelectStyles}
                  defaultValue={inputsState.worker.value ? workerOptions[inputsState.worker.value] : null}
                  options={Object.values(workerOptions)}
                />
              ) : (
                <DescriptionProject>{taskWorker?.name || 'No selected'}</DescriptionProject>
              )}
            </ProjectContent>
          </ProjectBlock>

          {/* priority */}
          <ProjectBlock>
            <ProjectIconBlock
              onClick={() => onEditClick('priority')}
              onMouseEnter={() => onHover('priority')}
              onMouseLeave={() => onBlur('priority')}
              borderColor={inputsState.priority.isEditing || inputsState.priority.isHovered ? '#3f51b5' : '#ff5722'}
              cursor={!getIsEditing('priority')}
            >
              {inputsState.priority.isEditing && <SaveIcon color="primary" />}
              {inputsState.priority.isHovered && !inputsState.priority.isEditing && <CreateIcon color="primary" />}
              {!inputsState.priority.isEditing && !inputsState.priority.isHovered && (
                <ProjectIcon name="numbered" fill="#ff5722" />
              )}
            </ProjectIconBlock>
            <ProjectContent>
              <NameProject>Priority:</NameProject>
              {inputsState.priority.isEditing ? (
                <Select
                  name="priority"
                  onChange={option => onChange({ target: { name: 'priority', value: option?.value || 'low' } })}
                  styles={SelectStyles}
                  defaultValue={PRIORITY_OPTIONS[inputsState.priority.value]}
                  options={Object.values(PRIORITY_OPTIONS)}
                />
              ) : (
                <DescriptionProject>{PRIORITY_OPTIONS[inputsState.priority.value]?.label || 'low'}</DescriptionProject>
              )}
            </ProjectContent>
          </ProjectBlock>

          {/* status */}
          <ProjectBlock>
            <ProjectIconBlock
              onClick={() => onEditClick('status')}
              onMouseEnter={() => onHover('status')}
              onMouseLeave={() => onBlur('status')}
              borderColor={inputsState.status.isEditing || inputsState.status.isHovered ? '#3f51b5' : '#ff5722'}
              cursor={!getIsEditing('status')}
            >
              {inputsState.status.isEditing && <SaveIcon color="primary" />}
              {inputsState.status.isHovered && !inputsState.status.isEditing && <CreateIcon color="primary" />}
              {!inputsState.status.isEditing && !inputsState.status.isHovered && (
                <ProjectIcon name="signalCellularAlt" fill="#ff5722" />
              )}
            </ProjectIconBlock>
            <ProjectContent>
              <NameProject>Status:</NameProject>
              {inputsState.status.isEditing ? (
                <Select
                  name="status"
                  onChange={option => onChange({ target: { name: 'status', value: option?.value || 'created' } })}
                  styles={SelectStyles}
                  defaultValue={STATUSES_OPTIONS[inputsState.status.value]}
                  options={Object.values(STATUSES_OPTIONS)}
                />
              ) : (
                <DescriptionProject>
                  {STATUSES_OPTIONS[inputsState.status.value]?.label || 'created'}
                </DescriptionProject>
              )}
            </ProjectContent>
          </ProjectBlock>
        </ColumnWrapper>

        <ColumnWrapper>
          {/* dateCreated */}
          <ProjectBlock>
            <ProjectIconBlock borderColor="#3f8426">
              <ProjectIcon name="calendar" fill="#3f8426" />
            </ProjectIconBlock>
            <ProjectContent>
              <NameProject>Created date:</NameProject>
              <DescriptionProject>
                {task?.dateCreated ? dayjs(task.dateCreated.toDate()).format('MMMM DD YYYY HH:mm') : null}
              </DescriptionProject>
            </ProjectContent>
          </ProjectBlock>

          {/* plannedEndDate */}
          <ProjectBlock>
            <ProjectIconBlock
              onClick={() => onEditClick('plannedEndDate')}
              onMouseEnter={() => onHover('plannedEndDate')}
              onMouseLeave={() => onBlur('plannedEndDate')}
              borderColor={
                inputsState.plannedEndDate.isEditing || inputsState.plannedEndDate.isHovered ? '#3f51b5' : '#ff5722'
              }
              cursor={!getIsEditing('plannedEndDate')}
            >
              {inputsState.plannedEndDate.isEditing && <SaveIcon color="primary" />}
              {inputsState.plannedEndDate.isHovered && !inputsState.plannedEndDate.isEditing && (
                <CreateIcon color="primary" />
              )}
              {!inputsState.plannedEndDate.isEditing && !inputsState.plannedEndDate.isHovered && (
                <ProjectIcon name="calendar" fill="#ff5722" />
              )}
            </ProjectIconBlock>
            <ProjectContent>
              <NameProject>Planned end date:</NameProject>
              {inputsState.plannedEndDate.isEditing ? (
                <TextField
                  name="plannedEndDate"
                  fullWidth
                  size="small"
                  hiddenLabel
                  className="dateInput"
                  variant="filled"
                  color="primary"
                  autoComplete="off"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={onChange}
                  defaultValue={
                    inputsState.plannedEndDate.value
                      ? dayjs(new Date(inputsState.plannedEndDate.value)).format('YYYY-MM-DD')
                      : null
                  }
                />
              ) : (
                <DescriptionProject>
                  {inputsState.plannedEndDate.value
                    ? dayjs(inputsState.plannedEndDate.value).format('MMMM DD YYYY HH:mm')
                    : null}
                </DescriptionProject>
              )}
            </ProjectContent>
          </ProjectBlock>

          {/* dateCompleted */}
          <ProjectBlock>
            <ProjectIconBlock borderColor="#ff5722">
              <ProjectIcon name="calendar" fill="#ff5722" />
            </ProjectIconBlock>
            <ProjectContent>
              <NameProject>Completed date:</NameProject>
              <DescriptionProject>
                {task?.dateCompleted ? dayjs(task?.dateCompleted.toDate()).format('MMMM DD YYYY HH:mm') : null}
              </DescriptionProject>
            </ProjectContent>
          </ProjectBlock>

          {/* dateProcessed */}
          <ProjectBlock>
            <ProjectIconBlock borderColor="#3f8426">
              <ProjectIcon name="calendar" fill="#3f8426" />
            </ProjectIconBlock>
            <ProjectContent>
              <NameProject>Processed date:</NameProject>
              <DescriptionProject>
                {task?.dateProcessed ? dayjs(task.dateProcessed.toDate()).format('MMMM DD YYYY HH:mm') : null}
              </DescriptionProject>
            </ProjectContent>
          </ProjectBlock>
        </ColumnWrapper>

        <ColumnWrapper bottom>
          {/* totalTime */}
          <ProjectBlock>
            <ProjectIconBlock
              borderColor="#1976d2"
              cursor={!isEditing}
              onClick={() => !isEditing && setTimelogModalOpen(true)}
            >
              <ProjectIcon name="timelapse" fill="#1976d2" />
            </ProjectIconBlock>
            <Wrapper>
              <NameProject>Time spent on task:</NameProject>
              <TimeText>{getHoursAndMinutesFromTime(task?.totalTime || 0)} </TimeText>
              <AddTimelogButton
                type="button"
                disabled={isEditing}
                onClick={() => !isEditing && setTimelogModalOpen(true)}
              >
                <SvgSprite className="icon" fill="#fff" name="plus" width={14} height={14} />
              </AddTimelogButton>
            </Wrapper>
          </ProjectBlock>

          {/* estimate */}
          <ProjectBlock>
            <ProjectIconBlock
              onClick={() => onEditClick('estimate')}
              onMouseEnter={() => onHover('estimate')}
              onMouseLeave={() => onBlur('estimate')}
              borderColor={inputsState.estimate.isEditing || inputsState.estimate.isHovered ? '#3f51b5' : '#1976d2'}
              cursor={!getIsEditing('estimate')}
            >
              {inputsState.estimate.isEditing && <SaveIcon color="primary" />}
              {inputsState.estimate.isHovered && !inputsState.estimate.isEditing && <CreateIcon color="primary" />}
              {!inputsState.estimate.isEditing && !inputsState.estimate.isHovered && (
                <ProjectIcon name="accessTimeFilled" fill="#1976d2" />
              )}
            </ProjectIconBlock>
            <Wrapper>
              <NameProject>Estimate</NameProject>
              {inputsState.estimate.isEditing ? (
                <>
                  <StyledInputName value={estimateString} name="estimate" onChange={onChange} type="text" />
                  {estimateError ? <ErrorMessageBlock>{estimateError}</ErrorMessageBlock> : null}
                </>
              ) : (
                <DescriptionProject>{getHoursAndMinutesFromTime(inputsState.estimate.value || 0)}</DescriptionProject>
              )}
            </Wrapper>
          </ProjectBlock>

          {/* timeIndicator */}
          <ProjectBlock className="timeIndicatorBlock">
            {Boolean(Number(task?.estimate || 0)) && (
              <TimeIndicator
                className="timeIndicator"
                estimate={Number(task?.estimate || 0)}
                totalTime={task?.totalTime || 0}
              />
            )}
          </ProjectBlock>

          {/* Subscribe */}
          <ProjectBlock className="timeIndicatorBlock">
            <Tooltip title={isSubscribed ? 'Unsubscribe' : 'Subscribe'}>
              <IconButton onClick={onToggleSubscribed}>
                {isSubscribed ? <NotificationsOff color="primary" /> : <NotificationsActive color="primary" />}
              </IconButton>
            </Tooltip>
          </ProjectBlock>
        </ColumnWrapper>
      </FlexWrapper>

      <BottomWrapper>
        {/* totalTime */}
        <ProjectBlock className="item">
          <ProjectIconBlock
            borderColor="#1976d2"
            cursor={!isEditing}
            onClick={() => !isEditing && setTimelogModalOpen(true)}
          >
            <ProjectIcon name="timelapse" fill="#1976d2" />
          </ProjectIconBlock>
          <Wrapper>
            <NameProject>Time spent on task:</NameProject>
            <TimeText>{getHoursAndMinutesFromTime(task?.totalTime || 0)} </TimeText>
            <AddTimelogButton
              type="button"
              disabled={isEditing}
              onClick={() => !isEditing && setTimelogModalOpen(true)}
            >
              <SvgSprite className="icon" fill="#fff" name="plus" width={14} height={14} />
            </AddTimelogButton>
          </Wrapper>
        </ProjectBlock>

        {/* timeIndicator */}
        <ProjectBlock className="item">
          {Boolean(Number(task?.estimate || 0)) && (
            <TimeIndicator
              className="timeIndicator"
              estimate={Number(task?.estimate || 0)}
              totalTime={task?.totalTime || 0}
            />
          )}
        </ProjectBlock>

        {/* estimate */}
        <ProjectBlock className="item">
          <ProjectIconBlock
            onClick={() => onEditClick('estimate')}
            onMouseEnter={() => onHover('estimate')}
            onMouseLeave={() => onBlur('estimate')}
            borderColor={inputsState.estimate.isEditing || inputsState.estimate.isHovered ? '#3f51b5' : '#1976d2'}
            cursor={!getIsEditing('estimate')}
          >
            {inputsState.estimate.isEditing && <SaveIcon color="primary" />}
            {inputsState.estimate.isHovered && !inputsState.estimate.isEditing && <CreateIcon color="primary" />}
            {!inputsState.estimate.isEditing && !inputsState.estimate.isHovered && (
              <ProjectIcon name="accessTimeFilled" fill="#1976d2" />
            )}{' '}
          </ProjectIconBlock>
          <Wrapper>
            <NameProject>Estimate</NameProject>
            {inputsState.estimate.isEditing ? (
              <>
                <StyledInputName value={estimateString} name="estimate" onChange={onChange} type="text" />
                {estimateError ? <ErrorMessageBlock>{estimateError}</ErrorMessageBlock> : null}
              </>
            ) : (
              <DescriptionProject>{getHoursAndMinutesFromTime(inputsState.estimate.value || 0)}</DescriptionProject>
            )}
          </Wrapper>
        </ProjectBlock>

        {/* Subscribe */}
        <ProjectBlock className="timeIndicatorBlock">
          <Tooltip title={isSubscribed ? 'Unsubscribe' : 'Subscribe'}>
            <IconButton onClick={onToggleSubscribed}>
              {isSubscribed ? <NotificationsOff color="primary" /> : <NotificationsActive color="primary" />}
            </IconButton>
          </Tooltip>
        </ProjectBlock>
      </BottomWrapper>

      <TimelogModal open={timelogModalOpen} handleClose={onTimelogModalClose} onSubmit={onTimelogModalSubmit} />
    </>
  );
};

export default InfoTaskHeader;
