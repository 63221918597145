import { combineReducers } from 'redux';
import { UsersState, SET_ALL_USERS, User, CurrentUserState, LOGIN, LOGOUT, SET_ERROR, SET_LOADING } from './usersTypes';

const currentUserInitialState = { id: null, isLoggedIn: false, error: null, isLoading: true };

const currentUserReducer = (
  state: CurrentUserState = currentUserInitialState,
  action: { payload: any; type: string },
): CurrentUserState => {
  switch (action.type) {
    case LOGIN:
      return { ...state, ...action.payload, isLoggedIn: true };

    case SET_ERROR:
      return { ...state, error: action.payload, isLoggedIn: false };

    case LOGOUT:
      return { ...currentUserInitialState, isLoading: false };

    case SET_LOADING:
      return { ...state, isLoading: action.payload };

    default:
      return state;
  }
};

const usersReducer = (state: UsersState = {}, action: { payload: any; type: string }): UsersState => {
  switch (action.type) {
    case SET_ALL_USERS:
      return {
        ...action.payload.reduce(
          (acc: { [key: string]: User }, user: User) => ({
            ...acc,
            [user.id]: {
              ...user,
            },
          }),
          {},
        ),
      };

    case LOGOUT:
      return {};

    default:
      return state;
  }
};

export default combineReducers({ users: usersReducer, currentUser: currentUserReducer });
