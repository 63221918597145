import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Dashboard from 'layouts/Dashboard/Dashboard';
import TaskCard from './TaskCard';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Pagination from '@material-ui/lab/Pagination';
import { Wrapper, Title, SubTitle, FilterWrapper, Filter, FilterTitle, EmptyMessage } from './styles';
import useRule from 'hooks/useRule';
import CustomSelect from 'components/CustomSelect';
import SvgSprite from 'components/SvgSprite';
import { getSortedTasks, getEditError } from 'store/tasks/tasksSelectors';
import { TASK_SORT_OPTIONS } from 'utils/constants';
import { SortOptions } from 'store/tasks/tasksTypes';
import { getProjectById } from 'store/projects/projectsSelectors';

const ROWS_PER_PAGE = 5;

const Project = () => {
  const { id }: { id: string } = useParams();
  const rule = useRule();

  const [page, setPage] = useState<number>(1);

  const [sortOption, setSortOption] = useState<SortOptions | null>(null);

  const projectTasks = useSelector(getSortedTasks(sortOption, id));
  const projectData = useSelector(getProjectById(id));
  const editError = useSelector(getEditError);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);

  const handlerChangePage = (_: any, value: number) => setPage(value);
  const handleClose = (_?: any, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setTimeout(() => {
      setSnackbarOpen(false);
    }, 6000);
  };

  return (
    <Dashboard>
      <Wrapper>
        <Title>Project: {projectData?.name}</Title>
        <FilterWrapper>
          <SubTitle>Tasks list:</SubTitle>
          <Filter>
            <FilterTitle>Filter by:</FilterTitle>
            <CustomSelect
              options={Object.values(TASK_SORT_OPTIONS)}
              defaultValue={sortOption ? TASK_SORT_OPTIONS[sortOption].label : null}
              name="filter-by"
              iconName="filter"
              height={0}
              width={0}
              onChange={(data: any) => setSortOption(data.value)}
            />
            <SvgSprite className="icon" name="filter" />
          </Filter>
        </FilterWrapper>

        {projectTasks.length !== 0 ? (
          (ROWS_PER_PAGE > 0
            ? projectTasks.slice((page - 1) * ROWS_PER_PAGE, (page - 1) * ROWS_PER_PAGE + ROWS_PER_PAGE)
            : projectTasks
          ).map(item => <TaskCard key={item.id} task={item} setSnackbarOpen={setSnackbarOpen} rule={rule} />)
        ) : (
          <EmptyMessage>No tasks</EmptyMessage>
        )}

        <Pagination
          onChange={handlerChangePage}
          count={Math.ceil(projectTasks.length / ROWS_PER_PAGE)}
          page={page}
          defaultPage={1}
          variant="outlined"
          shape="rounded"
        />
      </Wrapper>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        {editError ? (
          <MuiAlert severity="error">Ooops...error, please reload page and try again</MuiAlert>
        ) : (
          <MuiAlert severity="success">Task was added to archive</MuiAlert>
        )}
      </Snackbar>
    </Dashboard>
  );
};

export default Project;
