import React, { useState, useCallback, ChangeEvent, useEffect } from 'react';
import Linkify from 'react-linkify';
import FileList from 'components/FileList';
import InfoTaskHeader from 'components/InfoTaskHeader';
import {
  Wrapper,
  NameBlock,
  StyledInputName,
  StyledInputDescr,
  BlockItem,
  Label,
  DescriptionBlock,
  EditButton,
  StyledTaskNumber,
} from './styles';
import CreateIcon from '@material-ui/icons/Create';
import SaveIcon from '@material-ui/icons/Save';
import { TaskStore } from 'store/tasks/tasksTypes';
import { useDispatch } from 'react-redux';
import { fromStoreTaskToAPITask } from 'store/tasks/tasksHelpers';
import { updateTask } from 'store/tasks/tasksActions';
import { Prompt } from 'react-router-dom';

type InfoTaskProps = {
  task: TaskStore | null;
};

const componentDecorator = (href: any, text: any, key: any) => (
  <a rel="noreferrer" href={href} key={key} target="_blank">
    {text}
  </a>
);

export interface InputsStateProps {
  description: { isEditing: boolean; value: string };
  name: { isEditing: boolean; value: string };
}

export type InputsStateKeys = 'description' | 'name';

const ProjectInfoTask: React.FC<InfoTaskProps> = ({ task }) => {
  const dispatch = useDispatch();

  const [inputsState, setInputsState] = useState<InputsStateProps>({
    description: { isEditing: false, value: task?.description || '' },
    name: { isEditing: false, value: task?.name || '' },
  });

  useEffect(
    () =>
      setInputsState({
        description: { isEditing: false, value: task?.description || '' },
        name: { isEditing: false, value: task?.name || '' },
      }),
    [task],
  );

  const onEditClick = useCallback(
    (name: InputsStateKeys) => {
      if (!task) return;
      if (!inputsState[name].isEditing) {
        setInputsState(prev => ({ ...prev, [name]: { ...prev[name], isEditing: true } }));
        return;
      }
      const { task: newTask, id } = fromStoreTaskToAPITask({
        ...task,
        description: inputsState.description.value,
        name: inputsState.name.value,
      });
      dispatch(updateTask(newTask, id));
      setInputsState(prev => ({ ...prev, [name]: { ...prev[name], isEditing: false } }));
    },
    [inputsState, task, dispatch],
  );

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      if (!inputsState[name as keyof InputsStateProps]?.isEditing) return;
      setInputsState(prev => ({
        ...prev,
        [name as keyof InputsStateKeys]: { ...prev[name as keyof InputsStateProps], value },
      }));
    },
    [inputsState],
  );

  return (
    <Wrapper>
      <NameBlock>
        <EditButton onClick={() => onEditClick('name')} type="button">
          {!inputsState.name.isEditing ? <CreateIcon /> : <SaveIcon />}
        </EditButton>

        <StyledTaskNumber>{task?.taskNumber || null}</StyledTaskNumber>
        <Prompt
          when={inputsState.description.isEditing || inputsState.name.isEditing}
          message={() => 'Your current changes will be removed. Would you like to continue?'}
        />
        <StyledInputName
          name="name"
          onChange={onChange}
          disabled={!inputsState.name.isEditing}
          value={inputsState.name.value}
          multiline
        />
      </NameBlock>
      <InfoTaskHeader task={task} />
      <BlockItem>
        <Label>
          <EditButton onClick={() => onEditClick('description')} type="button">
            {!inputsState.description.isEditing ? <CreateIcon /> : <SaveIcon />}
          </EditButton>
          Description
        </Label>
        <DescriptionBlock>
          {!inputsState.description.isEditing ? (
            <pre>
              <Linkify componentDecorator={componentDecorator}>{inputsState.description.value}</Linkify>
            </pre>
          ) : (
            <StyledInputDescr
              name="description"
              onChange={onChange}
              disabled={!inputsState.description.isEditing}
              value={inputsState.description.value}
              multiline
            />
          )}
        </DescriptionBlock>
      </BlockItem>
      <FileList task={task} />
    </Wrapper>
  );
};

export default ProjectInfoTask;
