import React, { useEffect } from 'react';
import { Route, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getIsLoggedIn, getIsLoading } from 'store/users/usersSelectors';
import useRule from 'hooks/useRule';

const ProtectedRoute = (props: any) => {
  const isLoggedIn = useSelector(getIsLoggedIn);
  const isLoading = useSelector(getIsLoading);
  const history = useHistory();
  const rule = useRule();

  const { rules } = props;

  useEffect(() => {
    if ((!isLoggedIn && !isLoading) || (!isLoading && rule && !rules.includes(rule))) {
      history.push('/');
    }
  }, [isLoggedIn, history, isLoading, rule, rules]);

  return <Route {...props} />;
};

export default ProtectedRoute;
