import styled from 'styled-components';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import FormHelperText from '@material-ui/core/FormHelperText';

export const TextAreaWrapper = styled.div`
  width: 100%;
  margin: 8px;
  margin-bottom: ${({ error }: {error: boolean}) => (error ? '#f44336' : '#004AFF')};
`;

export const TextAreaHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  color: ${({ error }: {error: boolean}) => (error ? '#f44336' : '#004AFF')};
  background: rgba(255, 255, 255, 0.8);
  padding: 16px;
`;

export const TextAreaContent = styled(TextareaAutosize)`
  width: 100%;
  border: none;
  padding: 14px 17px 22px 17px;
  margin-top: 3px;
  background: rgba(255, 255, 255, 0.8);
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #001E66;
  outline: none;
`;

export const ErrorText = styled(FormHelperText)`
  color: #f44336 !important;
  margin-left: 14px !important;
`;
