import { storage } from 'utils/firebase';
import { FileLocal } from './tasksTypes';

const fileRef = storage.ref();

export const addFiles = (files: File[], projectName: string, taskId: string): FileLocal[] => {
  try {
    if (files.length !== 0) {
      const filesData = files.map(file => {
        const filePath = `taskFiles/${projectName}/${taskId}/${new Date().getTime()}-${file.name}`;
        fileRef.child(filePath).put(file);
        return {
          path: filePath,
          name: file.name,
          type: file.type,
        };
      });

      return filesData;
    }

    return [];
  } catch (error) {
    return [];
  }
};

export const deleteFile = (path: string) => fileRef.child(path).delete();

export const getDownloadLink = (path: string) => fileRef.child(path).getDownloadURL();
