import { getAllProjects } from 'store/projects/projectsActions';
import { getAllRecurringTasks, getAllTasks } from 'store/tasks/tasksActions';
import { Rule } from 'store/users/usersTypes';
import { RULES_OPTIONS } from './constants';
import { getAllNotifications } from 'store/notifications/notificationsActions';
import { getTimedoctorConfig, getTimeDoctorUsers } from 'store/timedoctor/timedoctorActions';

const initFetchData = (dispatch: any, rule: Rule, projects: string[], userId: string) => {
  dispatch(getAllTasks(rule === RULES_OPTIONS.admin.value ? null : projects));
  dispatch(getAllProjects(rule === RULES_OPTIONS.admin.value ? null : projects));
  dispatch(getAllNotifications(userId));
  if (rule === 'admin') {
    dispatch(getAllRecurringTasks());
    dispatch(getTimeDoctorUsers());
    dispatch(getTimedoctorConfig());
  }
};

export default initFetchData;
