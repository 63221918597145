import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Item = styled.li`
  background: #fff;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const TimeIndicatorCont = styled.div`
  min-width: 51px;
  margin-right: 20px;
`;

export const TopWrapper = styled.div`
  display: flex;
  padding: 16px 22px 13px 24px;
  justify-content: space-between;
`;

export const MoreWrapper = styled.ul<{ open: boolean }>`
  height: ${props => (props.open ? 'auto' : '0')};
  padding: ${props => (props.open ? '10px 24px' : '0 24px')};
  overflow: hidden;
  transition: padding 200ms ease;
`;

export const TimeList = styled.ul`
  width: 100%;
`;

export const TimeItem = styled.li`
  display: flex;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const TimeItemHeader = styled.li`
  display: flex;
  font-weight: 700;
  margin-bottom: 15px;
`;

export const TimeItemCont = styled.div`
  width: calc(100% / 4);

  pre {
    overflow: hidden;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
  }
`;

export const MoreButton = styled.button`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #004aff;
  border: none;
  background: none;
  cursor: pointer;
`;

export const LeftWrapper = styled.div`
  display: flex;
`;

export const NameCont = styled(Link)`
  text-decoration: none;
  color: #000;
  display: block;
  width: 215px;
`;

export const UserCont = styled.div`
  width: 190px;
`;

export const StatusCont = styled.div`
  width: 115px;
`;

export const TimeCont = styled.div`
  width: auto;
  margin-right: 5px;
`;
