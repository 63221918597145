import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BlockItem, Label, List, ListItem, DownloadLink, FileRemove, RemoveIcon, EditButton } from './styles';
import CreateIcon from '@material-ui/icons/Create';
import SaveIcon from '@material-ui/icons/Save';
import UploadInput from 'components/UploadInput';
import { TaskStore } from 'store/tasks/tasksTypes';
import { getDownloadLink, deleteFile, addFiles } from 'store/tasks/filesHelpers';
import { updateTask } from '../../store/tasks/tasksActions';
import { fromStoreTaskToAPITask } from '../../store/tasks/tasksHelpers';
import { getProjectById } from 'store/projects/projectsSelectors';

interface IFileList {
  task: TaskStore | null;
}

const FileList: React.FC<IFileList> = ({ task }) => {
  const dispatch = useDispatch();
  const [filesList, setFilesList] = useState<File[]>([]);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const project = useSelector(getProjectById(task?.idProject || ''));

  useEffect(() => {
    setFilesList([]);
  }, []);

  const onEditClick = useCallback(() => {
    if (!project || !task) return;
    if (!isEditing) {
      setIsEditing(true);
      return;
    }
    const files = addFiles(filesList, project.name, task.id);
    const { task: newTask, id } = fromStoreTaskToAPITask({ ...task, files: [...task.files, ...files] });
    dispatch(updateTask(newTask, id));
    setFilesList([]);
    setIsEditing(false);
  }, [project, task, dispatch, filesList, isEditing]);

  const handleDownloadFile = useCallback(async (path: string) => {
    try {
      const link: any = await getDownloadLink(path);
      window.open(link);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleRemoveFile = useCallback(
    async (path: string) => {
      if (!task) return;
      await deleteFile(path);
      const { task: newTask, id } = fromStoreTaskToAPITask({
        ...task,
        files: [...task.files.filter(item => item.path !== path)],
      });
      dispatch(updateTask(newTask, id));
    },
    [task, dispatch],
  );

  return (
    <BlockItem>
      <Label>
        <EditButton onClick={onEditClick} type="button">
          {!isEditing ? <CreateIcon /> : <SaveIcon />}
        </EditButton>
      </Label>
      <List>
        {isEditing ? (
          <UploadInput
            isEdit
            onChange={newFiles => setFilesList(prev => [...prev, ...newFiles])}
            filesList={filesList}
            removeFileList={setFilesList}
          />
        ) : null}
        {task && task?.files && task?.files.length !== 0 ? (
          task.files.map((item: any) => (
            <ListItem key={item.path}>
              <DownloadLink onClick={() => handleDownloadFile(item.path)} target="_blank">
                {item.name}
              </DownloadLink>
              {isEditing ? (
                <FileRemove onClick={() => handleRemoveFile(item.path)} type="button">
                  <RemoveIcon name="cancel" />
                </FileRemove>
              ) : null}
            </ListItem>
          ))
        ) : (
          <>
            <p>No files</p>
          </>
        )}
      </List>
    </BlockItem>
  );
};

export default FileList;
