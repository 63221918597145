import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import UnregisteredRoute from './UnregisterRoute';
// pages
import Login from 'pages/Login';
import CreateTicketsPage from 'pages/CreateTicketsPage';
import RequestProcessed from 'pages/RequestProcessed';
import ProjectsList from 'pages/ProjectsList';
import Project from 'pages/Project';
import ProjectTaskPage from 'pages/ProjectTaskPage';
import CreateProjectPage from 'pages/CreateProjectPage';
import DashboardPage from 'pages/DashboardPage';
import UserManegmentPage from 'pages/UserManegmentPage';
import EditUserPage from 'pages/EditUserPage';
import TimelogsDashboard from 'pages/TimelogsDashboard';
import { Wrapper } from './styles';
import NotFoundPage from 'pages/NotFoundPage';
import AddUserPage from 'pages/AddUserPage';
import EditProjectPage from 'pages/EditProjectPage';
import { RULES_OPTIONS } from 'utils/constants';
import RecurringTasksPage from 'pages/RecurringTasksPage';
import NotificationPage from 'pages/NotificationPage';
import AddRecurringTaskPage from 'pages/AddRecurringTaskPage';
import EditRecurringTaskPage from 'pages/EditRecurringTaskPage';
import TimedoctorConfigPage from 'pages/TimedoctorConfigPage';

const ADMIN = RULES_OPTIONS.admin.value;
const CLIENT = RULES_OPTIONS.client.value;
const DEV = RULES_OPTIONS.dev.value;

const Router = () => (
  <Wrapper>
    <Switch>
      <UnregisteredRoute path="/" exact component={Login} />
      <UnregisteredRoute path="/singin" exact component={Login} />
      <ProtectedRoute path="/add-ticket" exact component={CreateTicketsPage} rules={[ADMIN, CLIENT, DEV]} />
      <ProtectedRoute path="/dashboard" exact component={DashboardPage} rules={[ADMIN, CLIENT, DEV]} />
      <ProtectedRoute path="/request-processed" exact component={RequestProcessed} rules={[ADMIN, DEV]} />
      <ProtectedRoute path="/projects-list" exact component={ProjectsList} rules={[ADMIN, CLIENT, DEV]} />
      <ProtectedRoute path="/recurring-tasks" exact component={RecurringTasksPage} rules={[ADMIN]} />
      <ProtectedRoute path="/recurring-tasks/add" exact component={AddRecurringTaskPage} rules={[ADMIN]} />
      <ProtectedRoute path="/recurring-tasks/:id" exact component={EditRecurringTaskPage} rules={[ADMIN]} />
      <ProtectedRoute path="/timedoctor-config" exact component={TimedoctorConfigPage} rules={[ADMIN]} />
      <ProtectedRoute path="/projects-list/:id" exact component={EditProjectPage} rules={[ADMIN]} />
      <ProtectedRoute path="/project/:id" exact component={Project} rules={[ADMIN]} />
      <ProtectedRoute path="/project/task/:id" exact component={ProjectTaskPage} rules={[ADMIN, CLIENT, DEV]} />
      <ProtectedRoute path="/create-project" exact component={CreateProjectPage} rules={[ADMIN]} />
      <ProtectedRoute path="/users" exact component={UserManegmentPage} rules={[ADMIN]} />
      <ProtectedRoute path="/users/add" exact component={AddUserPage} rules={[ADMIN]} />
      <ProtectedRoute path="/users/:id" exact component={EditUserPage} rules={[ADMIN]} />
      <ProtectedRoute path="/statistic" exact component={TimelogsDashboard} rules={[ADMIN, CLIENT, DEV]} />
      <ProtectedRoute path="/notifications" exact component={NotificationPage} rules={[ADMIN, CLIENT, DEV]} />
      <Route path="*" component={NotFoundPage} />
    </Switch>
  </Wrapper>
);
export default Router;
