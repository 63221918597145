import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import Linkify from 'react-linkify';
import {
  Author,
  TextMessage,
  MessageBlock,
  MessageWrapper,
  MessageFlex,
  EditBlock,
  EditIcon,
  ShowBlock,
  ShowItem,
  FilesListWrapper,
  Date,
} from './styles';
import { FileContent, FileIcon, FileItem, FileList, FileName, FileWrapper } from '../Comments/styles';
import { ChatItem, FilesList } from '../../store/chatTask/chatTaskTypes';

interface ChatMessagesProps {
  message: ChatItem;
  side: string;
  author: string;
  handleRemove?: (data: ChatItem) => void;
  handleEdit?: (data: ChatItem) => void;
  handleDownloadFile: (file: FilesList) => void;
}

const componentDecorator = (href: any, text: any, key: any) => (
  <a rel="noreferrer" href={href} key={key} target="_blank">
    {text}
  </a>
);

const ChatMessages: React.FC<ChatMessagesProps> = ({ message, side, author, handleRemove, handleEdit, handleDownloadFile }) => {
  const [showValue, setShowValue] = useState<boolean>(false);

  const toggleEdit = () => {
    setShowValue(!showValue);
  };

  const removeMessage = () => {
    handleRemove && handleRemove(message);
    setShowValue(false);
  };

  const editMessage = () => {
    handleEdit && handleEdit(message);
    setShowValue(false);
  };

  return (
    <Grid container spacing={2} justify={side === 'right' ? 'flex-end' : 'flex-start'}>
      <MessageWrapper side={side === 'right'}>
        <MessageBlock side={side === 'right'}>
          <MessageFlex>
            <Date>{message.date}</Date>
            <Author>{author}</Author>
            <Linkify componentDecorator={componentDecorator}>
              <TextMessage>{message.description}</TextMessage>
            </Linkify>
          </MessageFlex>
          {side === 'right' && showValue && (
            <ShowBlock>
              <ShowItem onClick={editMessage}>Edit</ShowItem>
              <ShowItem onClick={removeMessage}>Remove</ShowItem>
            </ShowBlock>
          )}
          {side === 'right' && (
            <>
              <EditBlock onClick={toggleEdit}>
                <EditIcon name="more" />
              </EditBlock>
            </>
          )}
        </MessageBlock>
        {message.files && message.files.length > 0 && (
          <FilesListWrapper>
            <FileList className={`file-list-wrapper ${side === 'right' && 'right'}`}>
              {message.files &&
                message.files.map(file => (
                  <FileItem key={file.name} className="file-item" onClick={() => handleDownloadFile(file)}>
                    <FileWrapper className="file-wrapper">
                      <FileContent>
                        <FileIcon name="files" className="file-docs" />
                      </FileContent>
                      <FileName className="file-name">{file.name}</FileName>
                    </FileWrapper>
                  </FileItem>
                ))}
            </FileList>
          </FilesListWrapper>
        )}
      </MessageWrapper>
    </Grid>
  );
};

ChatMessages.defaultProps = {
  handleRemove: undefined,
  handleEdit: undefined,
};

export default ChatMessages;
