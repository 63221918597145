import firebase from 'firebase/app';

const namespace = 'TASKS';

export const SET_ALL_TASKS = `${namespace}/SET_ALL_TASKS`;
export const ADD_TASK = `${namespace}/ADD_TASK`;
export const SET_EDIT_ERROR = `${namespace}/SET_EDIT_ERROR`;

export const SET_ALL_RECURRING_TASKS = `${namespace}/SET_ALL_RECURRING_TASKS`;
export const ADD_RECURRING_TASK = `${namespace}/ADD_RECURRING_TASK`;
export const REMOVE_RECURRING_TASK = `${namespace}/REMOVE_RECURRING_TASK`;

export const SET_CREATED_TASK_NUMBER = `${namespace}/SET_CREATED_TASK_NUMBER`;

export interface FileLocal {
  name: string;
  path: string;
  type: string;
}

export type Priority = 'low' | 'medium' | 'high';

export type Status = 'completed' | 'created' | 'canceled' | 'in_work' | 'testing' | 'backlog';

export type SortOptions = 'name' | 'dateCreated' | 'dateEnd' | 'status';

export interface Task {
  dateCreated: firebase.firestore.Timestamp;
  dateCompleted: firebase.firestore.Timestamp | null;
  dateEnd: firebase.firestore.Timestamp | null;
  dateProcessed: firebase.firestore.Timestamp | null;
  description: string;
  estimate: number;
  idProject: string;
  isArchive: boolean;
  name: string;
  priority: Priority;
  status: Status;
  timeDoctorId: number | null;
  totalTime: number;
  userId: string | null;
  files: FileLocal[];
  taskNumber: number | null;
  subscribedUsers: string[];
}

export interface TaskUpdate {
  dateCreated: Date;
  dateCompleted: Date | null;
  dateEnd: Date | null;
  dateProcessed: Date | null;
  description: string;
  estimate: number;
  idProject: string;
  isArchive: boolean;
  name: string;
  priority: Priority;
  status: Status;
  timeDoctorId: number | null;
  totalTime: number;
  userId: string | null;
  files: FileLocal[];
  taskNumber: number | null;
  subscribedUsers: string[];
}

export interface TaskCreate {
  name: string;
  description: string;
  priority: Priority;
  idProject: string;
  projectName: string;
  estimate?: number;
  dateEnd: Date;
  status: Status;
  userId: string | null;
  subscribedUsers: string[];
  files: File[];
}

export type TaskStore = Task & { id: string };

export interface Tasks {
  [key: string]: TaskStore;
}

export type PeriodOptions = 'week' | 'month';

export interface RecurringTaskCreate {
  name: string;
  description: string;
  project: string;
  worker: string;
  status: Status;
  estimate: number;
  deadline: number;
  periodSelect: PeriodOptions;
  periodNumber: number;
  subscribedUsers: string[];
}

export type RecurringTaskStore = RecurringTaskCreate & { id: string; createdAt: firebase.firestore.Timestamp };

export interface RecurringTasks {
  [key: string]: RecurringTaskStore;
}

export interface TasksState {
  tasks: Tasks;
  recurringTasks: RecurringTasks;
  editError: boolean;
  createdTaskNumber: number | null;
}
