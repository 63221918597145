import React, { useMemo } from 'react';
import Dashboard from 'layouts/Dashboard';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { Redirect, useParams } from 'react-router-dom';
import { UserTitle } from './style';
import UserManegmentForm from 'components/UserManegmentForm';
import AddUserPage from '../AddUserPage';

const EditUserPage = () => {
  const { id }: { id: string } = useParams();
  const { users } = useSelector((state: RootState) => state.users);
  const user = useMemo(() => users[id], [users, id]);

  if (id === 'add') {
    return <AddUserPage />;
  }

  return (
    <>
      {user || !(Object.keys(users).length > 0) ? (
        <Dashboard>
          <UserTitle>{users[id]?.name}</UserTitle>
          {Object.keys(users).length > 0 && <UserManegmentForm user={users[id]} />}
        </Dashboard>
      ) : (
        <Redirect to="/users" />
      )}
    </>
  );
};
export default EditUserPage;
