import React from 'react';
import Dashboard from 'layouts/Dashboard';
import ProjectItem from './ProjectItem';
import { Wrapper } from './styles';
import { useSelector } from 'react-redux';
import { ProjectStore } from 'store/projects/projectsTypes';
import { getAllProjects } from '../../store/projects/projectsSelectors';

export const RenderList = ({ projects }: { projects: ProjectStore[] }) => (
  <>
    {projects.map(item => (
      <>{item.isArchive ? null : <ProjectItem key={item.id} project={item} />}</>
    ))}
  </>
);

const ProjectsList = () => {
  const projects = useSelector(getAllProjects);

  return (
    <Dashboard>
      <Wrapper>
        <RenderList projects={Object.values(projects)} />
      </Wrapper>
    </Dashboard>
  );
};

export default ProjectsList;
