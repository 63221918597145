import React from 'react';
import DashboardLayout from 'layouts/Dashboard';
import { AddButton, ButtonText } from './styles';
import { useSelector } from 'react-redux';
import RecurringTasksList from 'components/RecurringTasks/RecurringTasksList';
import { getRecurringTasks } from 'store/tasks/tasksSelectors';
import { useHistory } from 'react-router-dom';

const RecurringTasksPage = () => {
  const recurringTasks = useSelector(getRecurringTasks);
  const history = useHistory();

  return (
    <DashboardLayout>
      <AddButton onClick={() => history.push('/recurring-tasks/add')}>
        <ButtonText>Add recurring task</ButtonText>
      </AddButton>
      <RecurringTasksList tasks={recurringTasks} />
    </DashboardLayout>
  );
};

export default RecurringTasksPage;
