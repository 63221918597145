import { auth, db } from 'utils/firebase';
import { LOGIN, SET_ALL_USERS, User, SET_ERROR, LOGOUT, SET_LOADING, UserData } from './usersTypes';
import { AppThunk } from '../index';
import API, { setAuth, deleteAuth } from 'utils/api';
import getIdToken from 'utils/getIdToken';
import { RULES_OPTIONS } from '../../utils/constants';

const usersRef = db.collection('users');

const setAllUsers = (users: User[]): { payload: User[]; type: string } => ({
  payload: users,
  type: SET_ALL_USERS,
});

const setCurrentUser = (payload: any): { payload: any; type: string } => ({
  payload,
  type: LOGIN,
});

const setLoginError = (payload: string): { payload: string; type: string } => ({
  payload,
  type: SET_ERROR,
});

const setLoading = (payload: boolean): { payload: boolean; type: string } => ({
  payload,
  type: SET_LOADING,
});

const logoutAction = (): { type: string } => ({ type: LOGOUT });

export const getAllUsers = (): AppThunk => async dispatch => {
  try {
    const allUsers = await usersRef.get();

    const allUsersData: User[] = allUsers.docs.map(item => {
      const user = item.data();
      return {
        email: user.email || '',
        id: user.id || item.id,
        isDelete: user.isDelete || false,
        name: user.name || '',
        projects: user.projects || [],
        rule: user.rule || RULES_OPTIONS.client.value,
        isWorker: user.isWorker || false,
        idCompany: user.idCompany || null,
        idTimeDoctor: user.idTimeDoctor || null,
        isMailSubscribed: user.isMailSubscribed || false,
      };
    });

    dispatch(setAllUsers(allUsersData));
  } catch (error) {
    console.log(error);
  }
};

export const createUser = (userData: UserData, syncWithTimeDoctor?: boolean): AppThunk => async dispatch => {
  try {
    const token = await getIdToken();
    if (!token) {
      dispatch(logout());
      throw Error('Auth failed');
    }
    await API.post('/user', { ...userData, syncWithTimeDoctor });
    dispatch(getAllUsers());
  } catch (error) {
    console.log(error);
  }
};

export const editUser = (
  userData: UserData & { id?: string },
  syncWithTimeDoctor?: boolean,
): AppThunk => async dispatch => {
  try {
    const token = await getIdToken();
    if (!token) {
      dispatch(logout());
      throw Error('Auth failed');
    }
    if (!userData.id) return;
    await API.put(`/user/${userData.id}`, { ...userData, syncWithTimeDoctor });
    dispatch(getAllUsers());
  } catch (error) {
    console.log(error);
  }
};

export const toggleDisabled = (userId: string): AppThunk => async dispatch => {
  try {
    const token = await getIdToken();
    if (!token) {
      dispatch(logout());
      throw Error('Auth failed');
    }
    await API.put(`/user/toggle_disabled/${userId}`);
    await dispatch(getAllUsers());
  } catch (error) {
    console.log(error);
  }
};

export const deleteFromTimeDoctor = (userId: string): AppThunk => async dispatch => {
  try {
    const token = await getIdToken();
    if (!token) {
      dispatch(logout());
      throw Error('Auth failed');
    }
    await API.delete(`/user/time_doctor/${userId}`);
  } catch (error) {
    console.log(error);
  }
};

export const inviteUser = (userId: string): AppThunk => async dispatch => {
  try {
    const token = await getIdToken();
    if (!token) {
      dispatch(logout());
      throw Error('Auth failed');
    }
    await API.get(`/user/invite/${userId}`);
  } catch (error) {
    console.log(error);
  }
};

export const login = (email: string, password: string): AppThunk => async dispatch => {
  try {
    const data = await auth.signInWithEmailAndPassword(email, password);
    const token = await data.user?.getIdToken();
    const id = data.user?.uid;

    if (!token || !id) throw Error('Auth failed');

    setAuth(token);

    dispatch(setCurrentUser({ id }));
    dispatch(getAllUsers());
  } catch (error: any) {
    console.log(error);
    dispatch(setLoginError(error.message));
  }
};

export const logout = (): AppThunk => async dispatch => {
  try {
    await auth.signOut();
  } catch (error) {
    console.log(error);
  } finally {
    deleteAuth();
    dispatch(logoutAction());
  }
};

export const getUser = (): AppThunk => async dispatch => {
  dispatch(setLoading(true));
  auth.onAuthStateChanged(async user => {
    try {
      if (!user) throw Error('Auth failed');
      const token = await getIdToken();
      if (!token) throw Error('Auth failed');
      const response = await API.get('/auth/user');
      const id = response.data?.userId;
      if (!id) throw Error('Auth failed');

      dispatch(setCurrentUser({ id }));
      dispatch(getAllUsers());
    } catch (error) {
      dispatch(logout());
    } finally {
      dispatch(setLoading(false));
    }
  });
};
