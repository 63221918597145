import React from 'react';
import Dashboard from 'layouts/Dashboard';
import UserManegmentList from './UserManegmentList';

const UserManegmentPage = () => (
  <Dashboard>
    <UserManegmentList />
  </Dashboard>
);

export default UserManegmentPage;
