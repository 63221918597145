import React, { ChangeEvent, useCallback, useState } from 'react';
import DashboardLayout from 'layouts/Dashboard';
import { IconButton, InputAdornment, Snackbar, Switch, TextField } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { useDispatch, useSelector } from 'react-redux';
import {
  getTimedoctorConfig as getTimedoctorConfigSelector,
  getTimedoctorError,
  getTimedoctorLoading,
} from 'store/timedoctor/timedoctorSelectors';
import { setTimedoctorEnabled, updateTimedoctorConfig } from 'store/timedoctor/timedoctorActions';
import LoaderPage from 'components/LoaderPage';
import {
  Bold,
  CheckboxContainer,
  DateContainer,
  ErrorMessageBlock,
  FormWrapper,
  InputWrapper,
  SendButton,
  SpanText,
  useStyles,
} from './styles';
import { useForm } from 'react-hook-form';
import { validateEmail } from 'utils/validate';
import SvgSprite from 'components/SvgSprite';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { TimedoctorQuery } from 'store/timedoctor/timedoctorTypes';
import dayjs from 'dayjs';

const TimedoctorConfigPage = () => {
  const classes = useStyles();

  const [showPassword, setShowPassword] = useState(false);
  const [errorPopupOpen, setErrorPopupOpen] = useState(false);

  const dispatch = useDispatch();
  const config = useSelector(getTimedoctorConfigSelector);
  const loading = useSelector(getTimedoctorLoading);
  const error = useSelector(getTimedoctorError);

  const { register, errors, handleSubmit, reset } = useForm({
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const onEnableTimedoctorChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      dispatch(setTimedoctorEnabled(e.target.checked));
    },
    [dispatch],
  );

  const onSubmit = useCallback(
    (data: TimedoctorQuery) => {
      dispatch(updateTimedoctorConfig(data));
      setErrorPopupOpen(true);
      reset();
    },
    [dispatch, reset],
  );

  return (
    <>
      {!config ? (
        <LoaderPage />
      ) : (
        <DashboardLayout>
          <CheckboxContainer>
            <Switch
              disabled={loading}
              color="primary"
              checked={config.enabled}
              onChange={onEnableTimedoctorChange}
              defaultChecked={config.enabled}
            />
            <div>Toggle Timedoctor using</div>
          </CheckboxContainer>

          <FormWrapper className={classes.root} onSubmit={handleSubmit(onSubmit)}>
            <InputWrapper>
              <TextField
                fullWidth
                name="email"
                label="Email"
                variant="filled"
                color="primary"
                autoComplete="off"
                inputRef={register({
                  validate: (value): boolean => validateEmail(value),
                })}
                error={Boolean(errors.email)}
              />
              {errors.email ? <ErrorMessageBlock>Enter a valid email</ErrorMessageBlock> : null}
              <SvgSprite className="inputIcon" width={28} height={28} fill="#004AFF" name="post_add" />
            </InputWrapper>

            <InputWrapper>
              <TextField
                fullWidth
                name="password"
                label="Password"
                variant="filled"
                type={showPassword ? 'text' : 'password'}
                color="primary"
                autoComplete="off"
                inputRef={register({
                  validate: (value): boolean => Boolean(value),
                })}
                error={Boolean(errors.password)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        edge="end"
                        onClick={() => setShowPassword(prev => !prev)}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {errors.password ? <ErrorMessageBlock>Enter a password</ErrorMessageBlock> : null}
              <SvgSprite className="inputIcon" width={28} height={28} fill="#004AFF" name="post_add" />
            </InputWrapper>

            <SendButton type="submit" disabled={loading}>
              <SpanText>Submit</SpanText>
            </SendButton>
          </FormWrapper>

          <DateContainer>
            Token created at{' '}
            <Bold>{dayjs(config?.createdAt.toDate() || new Date()).format('DD-MM-YYYY HH:mm:ss.SSS')}</Bold>
          </DateContainer>

          <DateContainer>
            Token expires at{' '}
            <Bold expire>{dayjs(config?.expiresAt.toDate() || new Date()).format('DD-MM-YYYY HH:mm:ss.SSS')}</Bold>
          </DateContainer>

          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={errorPopupOpen && Boolean(error)}
            autoHideDuration={5000}
            onClose={() => setErrorPopupOpen(false)}
          >
            <MuiAlert variant="filled" severity="error">
              {error}
            </MuiAlert>
          </Snackbar>
        </DashboardLayout>
      )}
    </>
  );
};

export default TimedoctorConfigPage;
