export const SET_LOADING_CHAT = 'SET_LOADING_CHAT';
export const SET_CHAT_DATA = 'SET_CHAT_DATA';
export const SET_LOADING_SEND_MESSAGE = 'SET_LOADING_SEND_MESSAGE';
export const SET_FILES_INFO = 'SET_FILES_INFO';
export const SET_LOADING_DOCS = 'SET_LOADING_DOCS';
export const SET_BAD_TYPES_FILES = 'SET_BAD_TYPES_FILES';

export interface FilesList extends File {
  lastModified: number
  name: string
  size: number
  type: string
  path: string
  token: string
}

export interface ChatItem {
  id: string
  author: string
  date: string
  time: number
  nameAuthor: string
  align: 'left' | 'right'
  description: string
  files: FilesList[]
}

export interface ChatTaskState {
  loadingChat: boolean
  loadingSendMessage: boolean
  loadingDocs: boolean
  chatData: ChatItem[]
  filesList: FilesList[]
  badTypesFiles: FilesList[]
}

interface SetLoadingChatAction {
  type: typeof SET_LOADING_CHAT
  payload: boolean
}

interface SetChatDataAction {
  type: typeof SET_CHAT_DATA
  payload: ChatItem[]
}

interface SetLoadingSendMessageAction {
  type: typeof SET_LOADING_SEND_MESSAGE
  payload: boolean
}

interface SetFilesListAction {
  type: typeof SET_FILES_INFO
  payload: FilesList[]
}

interface SetLoadingDocsAction {
  type: typeof SET_LOADING_DOCS
  payload: boolean
}

interface SetBadTypesFiles {
  type: typeof SET_BAD_TYPES_FILES,
  payload: FilesList[],
}

export type ChatTaskActions =
  SetLoadingChatAction |
  SetChatDataAction |
  SetLoadingSendMessageAction |
  SetFilesListAction |
  SetBadTypesFiles |
  SetLoadingDocsAction;
