import styled from 'styled-components';
import { Alert } from '@material-ui/lab';

export const CustomAlert = styled(Alert)`

  &.MuiPaper-root {
    padding: 25px 25px;
  }

  & .MuiAlert-message {
    padding: 0;
  }

  & .MuiDialogTitle-root {
    padding: 0px 24px;
  }

  & .MuiDialogActions-root {
    justify-content: center;
  }
`;
