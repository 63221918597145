import { IconButton } from '@material-ui/core';
import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
} from '@material-ui/lab';
import styled from 'styled-components';

export const CustomTimelineItem = styled(TimelineItem)`
  justify-content: center;

  &:not(:last-child) .MuiTimelineContent-root {
    margin-bottom: 20px;
  }
`;

export const CustomTimelineOppositeContent = styled(TimelineOppositeContent)`
  flex: none !important;
  margin: 0 !important;
  flex-grow: 1 !important;
`;

export const CustomTimelineDot = styled(TimelineDot)`
  background-color: #ffffff !important;
  border: 4px solid #004aff !important;
`;

export const CustomTimelineConnector = styled(TimelineConnector)`
  background-color: #004aff !important;
  width: 3px !important;
`;

export const CustomTimelineContent = styled(TimelineContent)`
  flex-grow: 1.5 !important;
`;

export const ContentWrapper = styled.div`
  position: relative;
  cursor: pointer;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  width: fit-content;
  margin-left: 10px;
  flex: none !important;
  max-width: 507px;
  padding: 16px 32px 20px 13px;
  line-height: 18px;
  font-size: 12px;
  white-space: pre-wrap; /* CSS3 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
`;

export const DeleteButton = styled(IconButton)`
  position: absolute !important;
  right: 0;
  top: 50%;
  transform: translate(100%, -50%);
`;
