import styled from 'styled-components';

export const Card = styled.div`
  display: flex;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.14), 0px 0px 5px rgba(0, 0, 0, 0.12), 0px 0px 4px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  background-color: white;
  margin-bottom: 20px;
  align-items: center;
  
  
  a {
    display: flex;
    text-decoration: none;
    width: 100%;
    padding: 10px;
  }
`;

export const Label = styled.span`
  display: block;
  color: silver;
  font-size: 10px;
  text-transform: uppercase;
  margin-bottom: 5px;
`;

export const Name = styled.span`
  font-size: 16px;
  font-weight: 600;
  color: #3a3a3a;
`;

export const Coll = styled.div`
  margin-right: 25px;
  flex-grow: 2;
  
  &:first-child {
    width: 100px;
    max-width: 300px;
  }
  
  &.description {
    width: 200px;
    max-width: 300px;
  }
  
  &:nth-child(3) {
    width: 120px;
  }

  &:last-child {
    padding-top: 10px;
    text-align: center;
  }
`;

export const CollStatus = styled.div`
  margin-right: 20px;
  flex-grow: 2;
    
  ${Name} {
    color: #FF8A00;
  }
`;

export const CollPriority = styled.div`
  flex-grow: 2;
  
  ${Name} {
    color: ${({ color = '' }: { color?: string }) => color};
  }
`;

export const ArchiveBtn = styled.button`
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
`;
