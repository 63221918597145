import styled from 'styled-components';

export const InputWrapper = styled.div`
  width: 100%;
  margin: 8px;
  position: relative;
`;

export const InputHeader = styled.div`
  background: rgba(255, 255, 255, 0.8);
  width: 100%;
  display: flex;
  align-items: center;
  color: #004AFF;
  padding: 16px;
  & .Icon {
      margin-right: 16px;
  }
`;

export const FileInputLabel = styled.label<{
  getRootProps?:any,
  isDragActive: boolean,
  }>`
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 16px 10px;
  cursor: pointer;
  color: ${(props) => (props.isDragActive ? '#004AFF' : 'grey')};
  font-size: 14px;
  font-weight: 700;
  margin-top: 3px;
  border: ${(props) => (props.isDragActive ? '3px dashed blue' : '3px dashed grey')};
  
  & svg {
      margin-right: 10px;
  }
`;

export const FileInput = styled.input<{
  getInputProps?:any
}>`
  outline: none;
`;

export const FilesList = styled.div`  
  padding: 8px 16px;
  display: flex;
  flex-direction: column;
  width: 100%;
  background: rgba(255, 255, 255, 0.8);
  & span {
    position: relative;
    color: #004AFF;
    padding: 6px;
  }
`;

export const CloseBtn = styled.span`
  cursor: pointer;
  &::before {
    content: "";
    position: absolute;
    width: 2px;
    height: 12px;
    background-color: #004AFF;
    top: 0;
    right: 0;
    transform: rotate(40deg);
  }
  &::after {
    content: "";
    position: absolute;
    width: 2px;
    height: 12px;
    background-color: #004AFF;
    top: 0;
    right: 0;
    transform: rotate(-40deg);
}
`;

export const ErrorMessageBlock = styled.div`
  font-size: 0.75rem;
  line-height: 16px;
  letter-spacing: 0.1px;
  font-weight: 500;
  width: 100%;
  height: auto;
  padding-top: 5px;
  padding-left: 10px;
  padding-bottom: 5px;
  color: red;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
