import { createGlobalStyle } from 'styled-components';
import { reset } from 'styled-reset';

export const GlobalStyle = createGlobalStyle`
  ${reset}
  
  html,
  body,
  #root {
    font-family: 'Josefin Sans', sans-serif;
    font-family: 'Open Sans', sans-serif;
    height: 100%;
    scroll-behavior: smooth;
  }
  
  * {
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif !important;

    &::-webkit-scrollbar-track {
      background-color: #FFF;
    }

    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: rgba(0,74,255, 0.5);
    }
  }
`;
