import styled from 'styled-components';

export const Wrapper = styled.div`
  max-width: 478px;
  width: 100%;
  background: linear-gradient(178.8deg, #FFFFFF 2.58%, rgba(255, 255, 255, 0.6) 98.97%);
  border-radius: 10px;
  padding: 24px 41px;
`;

export const LogoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 35px;
`;

export const LogoCompany = styled.img`
  width: 46px;
  height: 46px;
  margin-bottom: 17px;
`;

export const NameCompany = styled.span`
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #001E66;
`;

export const SpanText = styled.span`
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
  position: relative;
  z-index: 2;
`;

export const SendButton = styled.button`
  width: 100%;
  height: 41px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #004AFF;
  border-radius: 10px;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #FFFFFF;
  border: none;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90.56deg, #004AFF -0.92%, #24C9FC 100%);
    opacity: 0;
    transition: all .3s;
  }
  
  &:hover {
    &:before {
      opacity: 1;
    } 
  }
`;

export const FormWrapper = styled.form`
  margin-bottom: 20px;
`;
