import React from 'react';
import { Link } from 'react-router-dom';
import { Wrapper, Text, LinkItem, Icon } from './styles';
import { useDispatch } from 'react-redux';
import { logout } from 'store/users/usersActions';

const LogOutPanel = () => {
  const dispatch = useDispatch();

  const logOut = () => {
    dispatch(logout());
  };

  return (
    <Wrapper>
      <LinkItem>
        <Link to="#" onClick={logOut}>
          <Icon name="log_out" />
          <Text>Log out</Text>
        </Link>
      </LinkItem>
    </Wrapper>
  );
};

export default React.memo(LogOutPanel);
