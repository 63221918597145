import {
  TasksState,
  SET_ALL_TASKS,
  ADD_TASK,
  SET_EDIT_ERROR,
  SET_ALL_RECURRING_TASKS,
  ADD_RECURRING_TASK,
  REMOVE_RECURRING_TASK,
  SET_CREATED_TASK_NUMBER,
} from './tasksTypes';
import { Action } from 'utils/commonTypes';
import { LOGOUT } from '../users/usersTypes';

const initialState = {
  tasks: {},
  recurringTasks: {},
  editError: false,
  createdTaskNumber: null,
};

const tasksReducer = (state: TasksState = initialState, action: Action): TasksState => {
  switch (action.type) {
    case SET_ALL_TASKS:
      return {
        ...state,
        tasks: { ...action.payload },
      };

    case ADD_TASK:
      return {
        ...state,
        tasks: { ...state.tasks, [action.payload.id]: action.payload },
      };

    case SET_EDIT_ERROR:
      return {
        ...state,
        editError: action.payload,
      };

    case SET_ALL_RECURRING_TASKS:
      return {
        ...state,
        recurringTasks: { ...action.payload },
      };

    case ADD_RECURRING_TASK:
      return {
        ...state,
        recurringTasks: { ...state.recurringTasks, [action.payload.id]: action.payload },
      };

    case REMOVE_RECURRING_TASK: {
      const newRecurringTasks = { ...state.recurringTasks };
      delete newRecurringTasks[action.payload];

      return { ...state, recurringTasks: { ...newRecurringTasks } };
    }

    case SET_CREATED_TASK_NUMBER:
      return { ...state, createdTaskNumber: action.payload };

    case LOGOUT:
      return initialState;

    default:
      return state;
  }
};

export default tasksReducer;
