import React from 'react';
import { useSelector } from 'react-redux';
import { NotificationAction } from 'store/notifications/notificationsType';
import { getProjectById } from 'store/projects/projectsSelectors';
import { getTaskById } from 'store/tasks/tasksSelectors';
import { getUserById } from 'store/users/usersSelectors';
import { SideWrapper, Highlighted, TopWrapper } from './styles';

interface CommentContentProps {
  taskId: string;
  action: NotificationAction;
  message: string;
  triggeredBy: string;
  projectId: string;
}

const CommentContent = ({ taskId, action, message, triggeredBy, projectId }: CommentContentProps) => {
  const user = useSelector(getUserById(triggeredBy));
  const task = useSelector(getTaskById(taskId));
  const project = useSelector(getProjectById(projectId));

  return (
    <div>
      <TopWrapper>
        <SideWrapper left>
          <span>Task:</span>
          <span>Project:</span>
          <span>Author:</span>
          <span>Action:</span>
        </SideWrapper>
        <SideWrapper>
          <span>{task?.name || ''}</span>
          <span>{project?.name || ''}</span>
          <span>{user?.name || ''}</span>
          <Highlighted action={action}>
            {action === 'commented' && 'Created'}
            {action === 'commentUpdate' && 'Edited'}
            {action === 'commentDelete' && 'Removed'}
          </Highlighted>
        </SideWrapper>
      </TopWrapper>

      <p>{message}</p>
    </div>
  );
};

export default CommentContent;
