import { RootState } from '../index';
import { ProjectStore } from './projectsTypes';

export const getProjects = (state: RootState): { [key: string]: ProjectStore } =>
  Object.keys(state.projects.projects).reduce((acc, key) => {
    const project = state.projects.projects[key];
    if (project.isEnabled && !project.isArchive) {
      return { ...acc, [key]: { ...project } };
    }
    return { ...acc };
  }, {});

export const getAllProjects = (state: RootState) => state.projects.projects;

export const getNotArchiveProjects = (state: RootState): { [key: string]: ProjectStore } => {
  const projects = getAllProjects(state);

  return Object.keys(projects).reduce((acc, key) => {
    const project = projects[key];
    if (!project.isArchive) {
      return { ...acc, [key]: { ...project } };
    }
    return { ...acc };
  }, {});
};

export const getProjectById = (id: string) => (state: RootState) => state.projects.projects[id] || null;
