import React, { useCallback } from 'react';
import Dashboard from 'layouts/Dashboard';
import AddProjectForm from 'components/AddProjectForm';
import { useDispatch, useSelector } from 'react-redux';
import { updateProject } from 'store/projects/projectsActions';
import { Redirect, useParams } from 'react-router-dom';
import { getProjectById, getProjects } from 'store/projects/projectsSelectors';

const CreateProjectPage = () => {
  const dispatch = useDispatch();
  const { id }: { id: string } = useParams();

  const projects = useSelector(getProjects);
  const project = useSelector(getProjectById(id));

  const onSubmit = useCallback(
    async (data: any) => {
      await dispatch(updateProject({ ...project, ...data }, project.id));
    },
    [dispatch, project],
  );

  return (
    <>
      {project || !(Object.keys(projects).length > 0) ? (
        <Dashboard>
          {Object.keys(projects).length > 0 && (
            <AddProjectForm onSubmit={onSubmit} submitText="Save" project={project} />
          )}
        </Dashboard>
      ) : (
        <Redirect to="/projects-list" />
      )}
    </>
  );
};

export default CreateProjectPage;
