import React from 'react';
import {
  Wrapper,
} from './styles';

interface ContainerProps {
  children: React.ReactNode
}

const Container: React.FC<ContainerProps> = ({ children }) => (
  <Wrapper>
    { children }
  </Wrapper>
);

export default Container;
