import styled from 'styled-components';
import SvgSprite from '../../SvgSprite';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Text = styled.div`
  font-size: 14px;
  line-height: 20px;
  text-transform: capitalize;
  color: #FFFFFF;
`;

export const LinkItem = styled.div`
  margin-right: 29px;

  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    border-radius: 3px;
    transition: all .3s;
    padding: 3px;
  }
  
  &:last-child {
    margin-right: 0;
  }
`;

export const Icon = styled(SvgSprite)`
  fill: white;
  margin-right: 5px;
  width: 17px;
  height: 13px;
`;
