import styled from 'styled-components';

export const Container = styled.li`
  padding: 20px 40px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;

export const SideContainer = styled.div`
  display: flex;
`;

export const InfoContainer = styled.p`
  margin-right: 20px;
  width: 100px;
`;

export const EditButton = styled.button`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #004aff;
  border: none;
  background: none;
  cursor: pointer;
`;

export const DeleteButton = styled.button`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: red;
  border: none;
  background: none;
  cursor: pointer;
`;
