import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import Dashboard from 'layouts/Dashboard';
import RecurringTasksForm from 'components/RecurringTasks/RecurringTasksForm';
import { createRecurringTask } from 'store/tasks/tasksActions';
import { RecurringTaskCreate } from 'store/tasks/tasksTypes';

const AddRecurringTaskPage = () => {
  const dispatch = useDispatch();

  const onCreateRecurringTask = useCallback(
    (data: RecurringTaskCreate) => {
      dispatch(createRecurringTask(data));
    },
    [dispatch],
  );

  return (
    <Dashboard>
      <RecurringTasksForm onSubmit={onCreateRecurringTask} />
    </Dashboard>
  );
};
export default AddRecurringTaskPage;
