import React from 'react';
import Dashboard from 'layouts/Dashboard';
import AddTaskForm from './AddTaskForm';

const CreateTicketsPage = () => (
  <Dashboard>
    <AddTaskForm />
  </Dashboard>
);

export default CreateTicketsPage;
