import React from 'react';
import { NavLink } from 'react-router-dom';
import { Card, Name, Icon, Counter, CustomBadge } from './styles';

interface RouterCardProps {
  to: string;
  name: string;
  icon: string;
  className?: string;
  widthIcon: number;
  heightIcon: number;
  counter?: number;
  badge?: string | number;
}

const RouterCard: React.FC<RouterCardProps> = ({
  to,
  name,
  icon,
  className,
  widthIcon,
  heightIcon,
  counter,
  badge,
}) => (
  <Card className={className}>
    <NavLink to={to} exact activeClassName="selected">
      <Icon name={icon} width={widthIcon} height={heightIcon} />
      <Name>{name}</Name>
      {counter !== 0 && counter !== undefined && <Counter>{counter}</Counter>}
    </NavLink>
    {Boolean(badge) && (
      <CustomBadge badgeContent={badge} color="secondary">
        <span />
      </CustomBadge>
    )}
  </Card>
);

RouterCard.defaultProps = {
  className: undefined,
  counter: undefined,
  badge: undefined,
};

export default RouterCard;
