/* eslint-disable consistent-return */
import React, { useRef, useState, useEffect, useCallback, FormEvent } from 'react';
import _ from 'lodash';
import clsx from 'clsx';
import {
  SendForm,
  LabelBlock,
  FilesWrapper,
  FileLabel,
  FileList,
  FileItem,
  FileName,
  FileIcon,
  FileContent,
  FileRemove,
  RemoveIcon,
  FileWrapper,
  FileLoadingText,
  ErrorMessageBlock,
} from './styles';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import SendIcon from '@material-ui/icons/Send';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import CloseIcon from '@material-ui/icons/Close';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { useDispatch, useSelector } from 'react-redux';
import {
  addFilesAndCreateMessage,
  removeFileFromMessage,
  removeFileFromStorage,
  setFilesList,
  setBadTypesFiles,
} from 'store/chatTask/chatTaskActions';
import { RootState } from '../../store';
import { FilesList } from 'store/chatTask/chatTaskTypes';
// Utils
import { checkTypesFilesUpload } from 'utils/checkTypesFilesUpload';
import { TYPES_FOR_INPUT_TEXT } from 'utils/typesForCheck';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      margin: theme.spacing(1),
      marginTop: theme.spacing(5),
    },
    textField: {
      width: '100%',
    },
  }),
);

type ComponentProps = {
  isEdit: boolean;
  editMessage?: string;
  idMessage?: string;
  sendMessage: (value: string, files: FilesList[], filesData: File[]) => void;
  loading: boolean;
  taskId?: string;
  handleCloseEdit: () => void;
  handleUpdateMessage: (value: string, filesList: FilesList[], filesData: File[]) => void;
};

const CreateMessageForm: React.FC<ComponentProps> = ({
  isEdit = false,
  editMessage,
  idMessage,
  taskId,
  sendMessage,
  loading,
  handleCloseEdit,
  handleUpdateMessage,
}) => {
  const dispatch = useDispatch();
  const { filesList, loadingDocs, badTypesFiles } = useSelector((state: RootState) => state.chatTask);
  const [filesData, setFilesData] = useState<File[]>(filesList);

  useEffect(() => {
    if (filesData.length === 0) {
      setFilesData(filesList);
    }
  }, [filesList, filesData.length]);

  const classes = useStyles();
  const formRef: any = useRef(null);
  const submitButton: any = useRef(null);
  const fileInputRef: any = useRef(null);
  const [message, setMessage] = useState<string>(isEdit && editMessage ? editMessage : '');
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    if (isEdit && editMessage) {
      setMessage(editMessage);
    }
  }, [isEdit, editMessage]);

  const changeMessage = (event: any) => {
    const { value } = event.target;
    if (!value || value.length < 1) setError(true);
    else setError(false);
    setMessage(value);
  };

  const sendMessageKey = (event: any) => {
    const { keyCode, shiftKey } = event.nativeEvent;
    if (keyCode === 13 && shiftKey) {
      submitButton.current.click();
    }
  };

  const clearValue = () => {
    setMessage('');
    handleCloseEdit();
  };

  const sendForm = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (error || !message) return;
      if (isEdit) {
        dispatch(setBadTypesFiles([]));
        handleUpdateMessage(message, filesList, filesData);
      } else {
        const sendFiles = filesList !== null ? filesList : [];
        dispatch(setBadTypesFiles([]));
        sendMessage(message, sendFiles, filesData);
      }

      setMessage('');
      setFilesData([]);
      dispatch(setFilesList([]));
    },
    [dispatch, filesData, filesList, handleUpdateMessage, isEdit, message, sendMessage, error],
  );

  const addFileList = async (event: any) => {
    const { files }: { files: FileList } = event.target;
    const fileValues = Object.values(files) as Array<File>;

    const prepearedFiles = checkTypesFilesUpload(fileValues, TYPES_FOR_INPUT_TEXT);
    let fileToUpload: any[] = [];
    if (prepearedFiles.available.length) {
      fileToUpload = prepearedFiles.available;
    }
    if (!prepearedFiles.notAvailable.length) {
      dispatch(setBadTypesFiles([]));
    } else {
      dispatch(setBadTypesFiles(prepearedFiles.notAvailable));
    }

    if (filesList.length > 0) {
      const filesListCopy: any[] = [...filesData];
      const concatFiles: any = filesListCopy.concat(fileToUpload);
      const resultFiles: any = _.uniqBy(concatFiles, 'name');

      const findElement = filesList.find(item => item.token);
      const token = findElement?.token;

      fileInputRef.current.value = '';

      if (taskId) {
        setFilesData(resultFiles);
        dispatch(addFilesAndCreateMessage(taskId, resultFiles, token));
      }

      return resultFiles;
    }

    fileInputRef.current.value = '';

    if (taskId) {
      setFilesData(fileToUpload);
      dispatch(addFilesAndCreateMessage(taskId, fileToUpload));
    }
    return fileToUpload;
  };

  const removeFile = (file: FilesList) => {
    if (filesList && taskId) {
      const filterFiles = filesData.filter(item => item.name !== file.name);
      setFilesData(filterFiles);
      if (isEdit && editMessage && idMessage) {
        dispatch(removeFileFromMessage(taskId, idMessage, file, true));
      } else {
        dispatch(removeFileFromStorage(taskId, file.path));
      }
    }
  };

  const getPastedValue = async (event: React.ClipboardEvent<HTMLDivElement>) => {
    if (Object.values(event.clipboardData.files).length <= 0) {
      return;
    }
    event.preventDefault();
    const { files } = event.clipboardData;
    const fileValues: any = Object.values(files) as Array<File>;
    const prepearedFiles = checkTypesFilesUpload(fileValues, TYPES_FOR_INPUT_TEXT);
    let fileToUpload: any[] = [];
    if (prepearedFiles.available.length) {
      fileToUpload = prepearedFiles.available;
    }
    if (!prepearedFiles.notAvailable.length) {
      dispatch(setBadTypesFiles([]));
    } else {
      dispatch(setBadTypesFiles(prepearedFiles.notAvailable));
    }
    if (filesList.length > 0) {
      const filesListCopy: any[] = [...filesData];
      const concatFiles: any = filesListCopy.concat(fileToUpload);
      const resultFiles: any = _.uniqBy(concatFiles, 'name');

      const findElement = filesList.find(item => item.token);
      const token = findElement?.token;

      if (taskId) {
        setFilesData(resultFiles);
        dispatch(addFilesAndCreateMessage(taskId, resultFiles, token));
      }

      return;
    }

    if (taskId) {
      setFilesData(fileToUpload);
      dispatch(addFilesAndCreateMessage(taskId, fileToUpload));
    }
  };

  return (
    <SendForm onSubmit={sendForm} ref={formRef}>
      <input type="file" id="upload-files" multiple hidden ref={fileInputRef} onChange={addFileList} />
      {badTypesFiles.length > 0 && (
        <ErrorMessageBlock>
          This files can not be uploaded {badTypesFiles.join(', ')}. Invalid file type!!!
        </ErrorMessageBlock>
      )}
      <FormControl className={clsx(classes.margin, classes.textField)} variant="outlined">
        <InputLabel htmlFor="outlined-adornment-password">Message</InputLabel>
        <OutlinedInput
          id="outlined-adornment-password"
          type="text"
          onPaste={event => getPastedValue(event)}
          multiline
          inputProps={{
            name: 'message',
          }}
          value={message}
          onChange={changeMessage}
          onKeyUp={sendMessageKey}
          error={error}
          endAdornment={
            <InputAdornment position="end">
              {isEdit && (
                <IconButton aria-label="clear values" edge="end" onClick={clearValue}>
                  <CloseIcon />
                </IconButton>
              )}
              <IconButton aria-label="add file" edge="end">
                <LabelBlock htmlFor="upload-files">
                  <AttachFileIcon />
                </LabelBlock>
              </IconButton>
              <IconButton type="submit" aria-label="toggle password visibility" edge="end" ref={submitButton}>
                {loading ? <CircularProgress size={20} /> : <SendIcon />}
              </IconButton>
            </InputAdornment>
          }
          labelWidth={70}
        />
      </FormControl>
      {loadingDocs && <FileLoadingText>Loading files...</FileLoadingText>}
      {filesList && filesList.length > 0 && (
        <FilesWrapper>
          <FileLabel>Files:</FileLabel>
          <FileList>
            {filesList.map(file => (
              <FileItem key={file.name}>
                <FileWrapper>
                  <FileRemove onClick={() => removeFile(file)} type="button" title="hello">
                    <RemoveIcon name="cancel" />
                  </FileRemove>
                  <FileContent>
                    <FileIcon name="files" />
                  </FileContent>
                  <FileName>{file.name}</FileName>
                </FileWrapper>
              </FileItem>
            ))}
          </FileList>
        </FilesWrapper>
      )}
    </SendForm>
  );
};

CreateMessageForm.defaultProps = {
  idMessage: undefined,
  taskId: undefined,
  editMessage: undefined,
};

export default CreateMessageForm;
