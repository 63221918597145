import React, { memo } from 'react';
import { Wrapper } from './styles';
import RouterCard from '../RouterCard';
import useRule from 'hooks/useRule';
import { useSelector } from 'react-redux';
import { getRequestProcessedTasks } from 'store/tasks/tasksSelectors';
import { RULES_OPTIONS } from 'utils/constants';
import { getUnreadNotificationsLength } from 'store/notifications/notificationsSelectors';

const ListNavigation = () => {
  const rule = useRule();
  const tasks = useSelector(getRequestProcessedTasks);
  const unreadNotificationsLength = useSelector(getUnreadNotificationsLength);

  return (
    <Wrapper>
      <RouterCard to="/dashboard" name="Dashboard" icon="dashboard" widthIcon={18} heightIcon={18} />
      <RouterCard to="/statistic" name="Statistic" icon="show_chart" widthIcon={18} heightIcon={18} />
      {rule !== RULES_OPTIONS.client.value && (
        <RouterCard
          to="/request-processed"
          name="New requests"
          badge={tasks.length}
          icon="bookmarks"
          widthIcon={18}
          heightIcon={18}
        />
      )}

      <RouterCard to="/add-ticket" name="Create ticket" icon="check" widthIcon={22} heightIcon={12} />

      <RouterCard to="/projects-list" name="Projects" icon="list" widthIcon={20} heightIcon={18} />

      {rule === RULES_OPTIONS.admin.value && (
        <RouterCard to="/users" name="User management" icon="group_filled" widthIcon={18} heightIcon={18} />
      )}

      <RouterCard
        to="/notifications"
        name=""
        icon="notifications"
        widthIcon={20}
        heightIcon={18}
        counter={unreadNotificationsLength}
      />
    </Wrapper>
  );
};

export default memo(ListNavigation);
