import React, { MouseEvent, useCallback, useMemo } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { Link } from 'react-router-dom';
import {
  TaskWrapper,
  Name,
  Label,
  Priority,
  TaskTitle,
  TaskWorker,
  TaksDate,
  TaskPriority,
  SubscribeButton,
} from './styles';
import { User } from 'store/users/usersTypes';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentUser } from 'store/users/usersSelectors';
import { TaskStore } from 'store/tasks/tasksTypes';
import { fromStoreTaskToAPITask } from 'store/tasks/tasksHelpers';
import { updateTask } from 'store/tasks/tasksActions';
import { IconButton } from '@material-ui/core';
import { NotificationsActive, NotificationsOff } from '@material-ui/icons';

interface IProps {
  task: TaskStore;
  worker: User | undefined;
}

const TaskItem: React.FC<IProps> = ({ worker, task }) => {
  const colorPriority = useMemo(() => {
    switch (task.priority) {
      case 'medium':
        return '#ffb60c';
      case 'low':
        return '#15771d';
      default:
        return '#FF0C3E';
    }
  }, [task.priority]);

  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUser);

  const isSubscribed = useMemo(() => {
    if (!currentUser) return false;
    return task.subscribedUsers ? task.subscribedUsers.includes(currentUser.id) : false;
  }, [task.subscribedUsers, currentUser]);

  const onToggleSubscribed = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      e.preventDefault();
      if (!task || !currentUser) return;
      const { task: newTask, id } = fromStoreTaskToAPITask({
        ...task,
        subscribedUsers: isSubscribed
          ? task.subscribedUsers.filter(userId => userId !== currentUser.id)
          : [...task.subscribedUsers, currentUser.id],
      });
      dispatch(updateTask(newTask, id));
    },
    [task, isSubscribed, currentUser, dispatch],
  );

  return (
    <TaskWrapper>
      <Link to={`/project/task/${task.id}`}>
        <SubscribeButton>
          <Tooltip title={isSubscribed ? 'Unsubscribe' : 'Subscribe'}>
            <IconButton onClick={onToggleSubscribed}>
              {isSubscribed ? (
                <NotificationsOff color="primary" fontSize="small" />
              ) : (
                <NotificationsActive color="primary" fontSize="small" />
              )}
            </IconButton>
          </Tooltip>
        </SubscribeButton>

        <TaskTitle>
          <strong>{task.taskNumber}</strong>
          {task.name}
        </TaskTitle>
        <TaskWorker>{worker && <Name>{worker.name}</Name>}</TaskWorker>
        <TaksDate>
          <Name>Created:</Name>
          <Label>{task.dateCreated ? dayjs(task.dateCreated.toDate()).format('MMMM DD YYYY HH:mm') : null}</Label>
        </TaksDate>
        <TaksDate>
          <Name>Deadline:</Name>
          <Label>{task.dateEnd ? dayjs(task.dateEnd.toDate()).format('MMMM DD YYYY HH:mm') : null}</Label>
        </TaksDate>
        {task.dateCompleted && (
          <TaksDate>
            <Name>Completed:</Name>
            <Label>{task.dateCompleted ? dayjs(task.dateCompleted.toDate()).format('MMMM DD YYYY HH:mm') : null}</Label>
          </TaksDate>
        )}
        <TaskPriority color={colorPriority}>
          <Tooltip title={`Priority - ${task.priority}`}>
            <Priority>{task.priority}</Priority>
          </Tooltip>
        </TaskPriority>
      </Link>
    </TaskWrapper>
  );
};

export default TaskItem;
