import React, { MouseEvent, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  ItemBlock,
  IconBlock,
  Icon,
  ContentBlock,
  ItemName,
  Description,
  EditButton,
  ArchiveButton,
  DisableButton,
} from './styles';
import { ProjectStore } from 'store/projects/projectsTypes';
import DraftsIcon from '@material-ui/icons/Drafts';
import ArchiveConfirmModal from 'components/ArchiveConfirmModal';
import { useDispatch } from 'react-redux';
import { updateProject } from 'store/projects/projectsActions';
import { fromStoreProjectToAPIProject } from 'store/projects/projectHelpers';
import useRule from 'hooks/useRule';
import { RULES_OPTIONS } from 'utils/constants';

type ProjectItemProps = {
  project: ProjectStore;
};

const ProjectItem: React.FC<ProjectItemProps> = ({ project }) => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const rule = useRule();

  const onArchiveClick = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setOpen(true);
  }, []);

  const handleConfirm = useCallback(() => {
    const { project: newProject, id } = fromStoreProjectToAPIProject({ ...project, isArchive: true });
    dispatch(updateProject(newProject, id));
    setOpen(false);
  }, [dispatch, project]);

  const handleToggleEnabled = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      const { project: newProject, id } = fromStoreProjectToAPIProject({ ...project, isEnabled: !project.isEnabled });
      dispatch(updateProject(newProject, id));
    },
    [dispatch, project],
  );

  return (
    <>
      <ItemBlock>
        <Link to={`/project/${project.id}`}>
          <IconBlock>
            <Icon name="folderNew" />
          </IconBlock>
          <ContentBlock>
            <ItemName>{project.name}</ItemName>
            <Description>{project.description}</Description>
          </ContentBlock>
          {rule === RULES_OPTIONS.admin.value && (
            <>
              <EditButton to={`/projects-list/${project.id}`}>Edit</EditButton>
              <ArchiveButton type="button" onClick={onArchiveClick}>
                <DraftsIcon />
              </ArchiveButton>
              <DisableButton onClick={handleToggleEnabled} isEnabled={project.isEnabled}>
                {project.isEnabled ? 'Disable' : 'Enable'}
              </DisableButton>
            </>
          )}
        </Link>
      </ItemBlock>
      <ArchiveConfirmModal handleConfirm={handleConfirm} open={open} setOpen={setOpen} text="Add project to archive?" />
    </>
  );
};

export default ProjectItem;
