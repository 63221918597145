import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

type ComponentProps = {
  open?: boolean;
  handleClose?: () => void;
  handleConfirm?: () => void;
  title?: string;
  description?: string;
};

const ConfirmModal: React.FC<ComponentProps> = ({ open = false, handleClose, handleConfirm, title, description }) => (
  <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} color="secondary">
        Cancel
      </Button>
      <Button onClick={handleConfirm} color="primary">
        Confirm
      </Button>
    </DialogActions>
  </Dialog>
);

ConfirmModal.defaultProps = {
  open: undefined,
  handleClose: undefined,
  handleConfirm: undefined,
  title: undefined,
  description: undefined,
};

export default ConfirmModal;
