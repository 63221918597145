import { createStore, combineReducers, applyMiddleware, Action } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import reduxLogger from 'redux-logger';
import reduxThunk, { ThunkAction } from 'redux-thunk';
import projectsReducer from './projects/projectsReducer';
import chatTask from './chatTask/chatTaskReducer';
import usersReducer from './users/usersReducer';
import timelogsReducer from './timelogs/timelogsReducer';
import tasksReducer from './tasks/tasksReducer';
import notificationsReducer from './notifications/notificationsReducer';
import timedoctorReducer from './timedoctor/timedoctorReducer';

const rootReducer = combineReducers({
  projects: projectsReducer,
  chatTask,
  users: usersReducer,
  timelog: timelogsReducer,
  tasks: tasksReducer,
  notifications: notificationsReducer,
  timedoctor: timedoctorReducer,
});

let middleware: any = [reduxThunk];
const processNode: any = process.env.NODE_ENV;
if (processNode === 'development') {
  middleware = [...middleware, reduxLogger];
}

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(...middleware)));

export default store;

export type RootState = ReturnType<typeof rootReducer>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
export type AppDispatch = typeof store.dispatch;
