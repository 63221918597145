import { db } from 'utils/firebase';
import { Timelog, SET_TIMELOGS, ADD_TIMELOG, RESET_TIMELOGS, REMOVE_TIMELOG } from './timelogsTypes';
import { AppThunk } from '../index';
import { getDateProcessed, getTimelogsHelper, normalizeTimelogsData } from './timelogsHelpers';
import { Task } from 'store/tasks/tasksTypes';
import { fromStoreTaskToAPITask } from '../tasks/tasksHelpers';
import { updateTask } from '../tasks/tasksActions';
import API from 'utils/api';

const timelogsRef = db.collection('timelogs');
const tasksRef = db.collection('tasks');

export const setTimelogs = (timelogs: Timelog[]): { payload: Timelog[]; type: string } => ({
  type: SET_TIMELOGS,
  payload: timelogs,
});

export const resetTimelogs = (): { type: string } => ({
  type: RESET_TIMELOGS,
});

export const addTimelog = (timelog: Timelog): { payload: Timelog; type: string } => ({
  type: ADD_TIMELOG,
  payload: timelog,
});

export const removeTimelog = (payload: string): { payload: string; type: string } => ({
  type: REMOVE_TIMELOG,
  payload,
});

export const getTimelogs = (id: string, findBy: 'userId' | 'taskId' | 'projectId'): AppThunk => async dispatch => {
  try {
    const timelogs = await timelogsRef.where(findBy, '==', id).get();
    const timelogsData = normalizeTimelogsData(timelogs);
    dispatch(setTimelogs(timelogsData));
  } catch (error) {
    console.log(error);
  }
};

export const getDashboardTimelogs = (data: {
  userId: string;
  projectId: string;
  from: Date;
  to: Date;
}): AppThunk => async dispatch => {
  try {
    const timelogs = await getTimelogsHelper(data);
    const timelogsData = normalizeTimelogsData(timelogs);
    dispatch(setTimelogs(timelogsData));
  } catch (error) {
    console.log(error);
  }
};

export const createTimelog = (data: {
  creationDate: Date;
  workDate: Date;
  taskId: string;
  userId: string;
  projectId: string;
  time: number;
  description: string;
}): AppThunk => async dispatch => {
  try {
    const response = await API.post('/timelog', data);
    const { timelogId } = response.data;

    const timelog = await timelogsRef.doc(timelogId).get();
    const timelogData = timelog.data() as Timelog;

    const task = await tasksRef.doc(data.taskId).get();
    const taskData = task.data() as Task;

    const newTotalTime = taskData.totalTime + data.time;
    const { task: newTask, id } = fromStoreTaskToAPITask({
      ...taskData,
      id: data.taskId,
      totalTime: newTotalTime,
    });

    dispatch(
      updateTask(
        {
          ...newTask,
          dateProcessed: getDateProcessed({
            oldTotalTime: taskData.totalTime,
            newTotalTime,
            oldDateProcessed: taskData.dateProcessed ? taskData.dateProcessed.toDate() : null,
          }),
        },
        id,
      ),
    );

    dispatch(addTimelog({ ...timelogData, id: timelog.id }));
  } catch (error) {
    console.log(error);
  }
};

export const updateTimelog = (data: {
  id: string;
  description: string;
  time: number;
  workDate: Date;
}): AppThunk => async dispatch => {
  try {
    const oldTimelog = await timelogsRef.doc(data.id).get();
    const oldTimelogData = oldTimelog.data() as Timelog;

    const response = await API.put(`/timelog/${data.id}`, {
      description: data.description,
      time: data.time,
      workDate: data.workDate,
    });
    const { timelogId } = response.data;

    const newTimelog = await timelogsRef.doc(timelogId).get();
    const newTimelogData = newTimelog.data() as Timelog;

    const task = await tasksRef.doc(oldTimelogData?.taskId).get();
    const taskData = task.data() as Task;

    const newTotalTime = taskData.totalTime - oldTimelogData.time + newTimelogData.time;

    const { task: newTask, id } = fromStoreTaskToAPITask({
      ...taskData,
      id: task.id,
      totalTime: newTotalTime,
    });

    dispatch(
      updateTask(
        {
          ...newTask,
          dateProcessed: getDateProcessed({
            oldTotalTime: taskData.totalTime,
            newTotalTime,
            oldDateProcessed: taskData.dateProcessed ? taskData.dateProcessed.toDate() : null,
          }),
        },
        id,
      ),
    );

    dispatch(removeTimelog(timelogId));
    dispatch(addTimelog({ ...newTimelogData, id: timelogId }));
  } catch (error) {
    console.log(error);
  }
};

export const deleteTimelog = (id: string): AppThunk => async dispatch => {
  try {
    const timelogRef = timelogsRef.doc(id);
    const timelog = await timelogRef.get();
    const timelogData = timelog.data() as Timelog;

    const response = await API.delete(`/timelog/${id}`);
    const { timelogId } = response.data;

    const task = await tasksRef.doc(timelogData?.taskId).get();
    const taskData = task.data() as Task;

    const newTotalTime = taskData.totalTime - timelogData.time;

    const { task: newTask, id: taskId } = fromStoreTaskToAPITask({
      ...taskData,
      id: task.id,
      totalTime: newTotalTime,
    });

    dispatch(
      updateTask(
        {
          ...newTask,
          dateProcessed: getDateProcessed({
            oldTotalTime: taskData.totalTime,
            newTotalTime,
            oldDateProcessed: taskData.dateProcessed ? taskData.dateProcessed.toDate() : null,
          }),
        },
        taskId,
      ),
    );

    dispatch(removeTimelog(timelogId));
  } catch (error) {
    console.log(error);
  }
};
