import { db } from 'utils/firebase';
import { SET_CONFIG, TimedoctorConfig, TimedoctorQuery, SET_LOADING, SET_ERROR, SET_USERS } from './timedoctorTypes';
import { AppThunk } from '../index';
import API from 'utils/api';

const timedoctorConfigRef = db.collection('config').doc('timedoctor');

export const setTimedoctorConfig = (payload: TimedoctorConfig): { payload: TimedoctorConfig; type: string } => ({
  type: SET_CONFIG,
  payload,
});

export const setTimeDoctorUsers = (payload: string[]): { payload: string[]; type: string } => ({
  type: SET_USERS,
  payload,
});

export const setTimedoctorConfigLoading = (payload: boolean): { payload: boolean; type: string } => ({
  type: SET_LOADING,
  payload,
});

export const setTimedoctorError = (payload: string | null): { payload: string | null; type: string } => ({
  type: SET_ERROR,
  payload,
});

export const getTimedoctorConfig = (): AppThunk => async dispatch => {
  dispatch(setTimedoctorConfigLoading(true));
  try {
    const timedoctorConfig = await timedoctorConfigRef.get();

    dispatch(setTimedoctorConfig(timedoctorConfig.data() as TimedoctorConfig));
  } catch (error) {
    console.log(error);
  }
  dispatch(setTimedoctorConfigLoading(false));
};

export const updateTimedoctorConfig = (data: TimedoctorQuery): AppThunk => async dispatch => {
  dispatch(setTimedoctorConfigLoading(true));
  dispatch(setTimedoctorError(null));
  try {
    await API.post('/timedoctor/config', data);

    dispatch(getTimedoctorConfig());
  } catch (error) {
    dispatch(setTimedoctorError('Email or password is invalid'));
    console.log(error);
  }
  dispatch(setTimedoctorConfigLoading(false));
};

export const setTimedoctorEnabled = (enabled: boolean): AppThunk => async dispatch => {
  dispatch(setTimedoctorConfigLoading(true));
  try {
    await API.put('/timedoctor/config', { enabled });

    dispatch(getTimedoctorConfig());
  } catch (error) {
    console.log(error);
  }
  dispatch(setTimedoctorConfigLoading(false));
};

export const getTimeDoctorUsers = (): AppThunk => async dispatch => {
  try {
    const res = await API.get('/timedoctor/users');
    dispatch(setTimeDoctorUsers(res.data.users));
  } catch (error) {
    console.log(error);
  }
};
