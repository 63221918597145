import React, { useCallback, useMemo, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
// Components
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import CustomTextArea from 'components/CustomTextArea';
import { useStyles, FormWrapper, InputWrapper, DocIcon, SendButton, SpanText } from './styles';
import { ProjectStore } from 'store/projects/projectsTypes';
import { useSelector } from 'react-redux';
import { getNotArchiveProjects } from 'store/projects/projectsSelectors';

interface AddProjectFormProps {
  onSubmit: (data: any) => void;
  submitText: string;
  project?: ProjectStore | null;
}

const AddProjectForm = ({ onSubmit, submitText, project }: AddProjectFormProps) => {
  const classes = useStyles();
  const history = useHistory();

  const [loadingCreated, setLadingCreated] = useState(false);

  const projects = useSelector(getNotArchiveProjects);

  const projectsNames = useMemo(() => Object.keys(projects).map(key => projects[key].name), [projects]);

  // Form control and handler
  const { control, register, handleSubmit, errors } = useForm({
    defaultValues: { name: project?.name || '', description: project?.description || '' },
  });

  // Submit form
  const onSubmitHandler = useCallback(
    async (data: any) => {
      setLadingCreated(true);
      await onSubmit(data);
      setLadingCreated(false);
      history.push('/projects-list');
    },
    [history, onSubmit],
  );

  const validateName = useCallback(
    (value: string): boolean => {
      if (!project) return value.length >= 3 && !projectsNames.includes(value);
      return value.length >= 3 && (!projectsNames.includes(value) || value === project.name);
    },
    [project, projectsNames],
  );

  return (
    <FormWrapper className={classes.root} onSubmit={handleSubmit(onSubmitHandler)}>
      <InputWrapper>
        <TextField
          fullWidth
          label="Project name"
          name="name"
          variant="filled"
          color="primary"
          autoComplete="off"
          inputRef={register({
            validate: validateName,
          })}
          error={Boolean(errors.name)}
          helperText={errors.name ? 'Min 3 symbols and should be unique' : null}
        />
        <DocIcon fill={errors.name ? '#f44336' : '#004AFF'} />
      </InputWrapper>
      <Controller
        as={CustomTextArea}
        showError
        name="description"
        control={control}
        rules={{
          validate: (value): boolean => value.length > 5,
        }}
        error={Boolean(errors.description)}
        defaultValue=""
      />
      <SendButton type="submit">
        <SpanText>{loadingCreated ? <CircularProgress /> : submitText}</SpanText>
      </SendButton>
    </FormWrapper>
  );
};

AddProjectForm.defaultProps = {
  project: null,
};

export default AddProjectForm;
