import React from 'react';
import RouterCard from '../RouterCard';
import { NavigationContainer } from './styles';
import useRule from 'hooks/useRule';
import { RULES_OPTIONS } from 'utils/constants';
import { useLocation } from 'react-router-dom';

const ListNavigationSecondLevel = () => {
  const rule = useRule();
  const location = useLocation();

  return (
    <>
      {(location.pathname === '/users' || location.pathname === '/projects-list') && (
        <NavigationContainer>
          {rule === RULES_OPTIONS.admin.value && (
            <>
              {location.pathname === '/users' && (
                <>
                  <RouterCard
                    to="/timedoctor-config"
                    name="Timedoctor settings"
                    icon="setting"
                    widthIcon={18}
                    heightIcon={18}
                  />
                </>
              )}
              {location.pathname === '/projects-list' && (
                <>
                  <RouterCard to="/create-project" name="Create project" icon="plus" widthIcon={18} heightIcon={18} />
                  <RouterCard
                    to="/recurring-tasks"
                    name="Recurring tasks"
                    icon="check"
                    widthIcon={22}
                    heightIcon={12}
                  />
                </>
              )}
            </>
          )}
        </NavigationContainer>
      )}
    </>
  );
};
export default ListNavigationSecondLevel;
