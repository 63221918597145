import React, { useMemo, useState } from 'react';
import {
  Item,
  MoreButton,
  LeftWrapper,
  NameCont,
  UserCont,
  StatusCont,
  TimeCont,
  TopWrapper,
  MoreWrapper,
  TimeList,
  TimeItem,
  TimeItemCont,
  TimeItemHeader,
  TimeIndicatorCont,
} from './styles';
import { GroupedTimelog } from 'store/timelogs/timelogsTypes';
import { useSelector } from 'react-redux';
import { RootState } from 'store/index';
import { User } from 'store/users/usersTypes';
import dayjs from 'dayjs';
import TimeIndicator from 'components/TimeIndicator';
import { getHoursAndMinutesFromTime } from '../../../InfoTaskHeader/TimelogModal/helpers';
import { TaskStore } from 'store/tasks/tasksTypes';
import { Tooltip } from '@material-ui/core';

interface TimelogsDashboardItemProps {
  item: GroupedTimelog;
}

const getStatusString = (task: TaskStore | null) => {
  if (!task?.status) return '';
  if (task.status === 'created') return 'Created';
  if (task.status === 'completed') return 'Completed';
  if (task.status === 'canceled') return 'Canceled';
  if (task.status === 'in_work') return 'In work';
  if (task.status === 'testing') return 'Testing';
  return '';
};

const TimelogsDashboardItem = ({ item }: TimelogsDashboardItemProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const { tasks } = useSelector((state: RootState) => state.tasks);
  const { users } = useSelector((state: RootState) => state.users);

  const task: TaskStore | null = useMemo(() => tasks[item.taskId] || null, [tasks, item.taskId]);
  const user: User | null = useMemo(() => (task && task.userId ? users[task.userId] : null), [users, task]);

  return (
    <Item>
      <TopWrapper>
        <LeftWrapper>
          <NameCont to={`/project/task/${task?.id}`} target="_blank">
            {task?.name || ''}
          </NameCont>
          <UserCont>{user?.name || ''}</UserCont>
          <StatusCont>{getStatusString(task) || ''}</StatusCont>
          <TimeIndicatorCont>
            {Boolean(task.estimate) && (
              <TimeIndicator className="timeIndicator" estimate={task.estimate || 0} totalTime={task.totalTime || 0} />
            )}
          </TimeIndicatorCont>
          <TimeCont>{getHoursAndMinutesFromTime(item.totalTime)}</TimeCont>
        </LeftWrapper>

        <MoreButton type="button" onClick={() => setOpen(prev => !prev)}>
          {open ? 'Close' : 'More'}
        </MoreButton>
      </TopWrapper>

      <MoreWrapper open={open}>
        <TimeList>
          <TimeItemHeader>
            <TimeItemCont>Created at</TimeItemCont>
            <TimeItemCont>Worked at</TimeItemCont>
            <TimeItemCont>User</TimeItemCont>
            <TimeItemCont>Time</TimeItemCont>
            <TimeItemCont>Description</TimeItemCont>
          </TimeItemHeader>

          {item.timelogs.map(timelog => (
            <TimeItem key={timelog.creationDate.toDate().getTime()}>
              <TimeItemCont>{dayjs(timelog.creationDate.toDate()).format('YYYY-MM-DD HH:mm')}</TimeItemCont>
              <TimeItemCont>{dayjs(timelog.workDate.toDate()).format('YYYY-MM-DD')}</TimeItemCont>
              <TimeItemCont>{users[timelog.userId]?.name}</TimeItemCont>
              <TimeItemCont>{getHoursAndMinutesFromTime(timelog.time)}</TimeItemCont>
              <TimeItemCont>
                <Tooltip title={timelog.description}>
                  <pre>{timelog.description}</pre>
                </Tooltip>
              </TimeItemCont>
            </TimeItem>
          ))}
        </TimeList>
      </MoreWrapper>
    </Item>
  );
};

export default TimelogsDashboardItem;
