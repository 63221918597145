import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 51px;
  height: 16px;
  position: relative;
`;

export const Estimate = styled.div<{ width: number }>`
  background: #0047ff;
  position: absolute;
  height: 50%;
  left: 0;
  top: 0;
  width: ${props => props.width}%;
`;

export const TimeLeft = styled.div<{ width: number }>`
  background: #ffb800;
  position: absolute;
  height: 50%;
  right: 0;
  bottom: 0;
  width: ${props => props.width}%;
`;

export const TimeSpent = styled.div<{ width: number }>`
  background: #52ff00;
  position: absolute;
  height: 50%;
  left: 0;
  bottom: 0;
  width: ${props => props.width}%;
`;

export const TimeExpired = styled.div<{ width: number }>`
  background: #ff0000;
  position: absolute;
  height: 50%;
  right: 0;
  top: 0;
  width: ${props => props.width}%;
`;
