import React from 'react';
import sprite from 'assets/images/icons/sprite.svg';

interface SvgSpriteProps {
  className?: string;
  name: string;
  active?: string;
  width?: number;
  height?: number;
  fill?: string;
  stroke?: string;
  onClick?: () => void;
}

const SvgSprite: React.FC<SvgSpriteProps> = ({ className, name, fill, active, stroke, onClick, ...rest }) => (
  <svg className={className} onClick={onClick} fill={fill} stroke={stroke} {...rest}>
    <use href={`${sprite}#${name}`} />
  </svg>
);

SvgSprite.defaultProps = {
  className: undefined,
  active: undefined,
  width: undefined,
  height: undefined,
  fill: undefined,
  stroke: undefined,
  onClick: undefined,
};

export default SvgSprite;
