import { ProjectsState, SET_PROJECTS, ADD_PROJECT } from './projectsTypes';
import { LOGOUT } from '../users/usersTypes';
import { Action } from 'utils/commonTypes';

const initialState: ProjectsState = {
  projects: {},
};

const projectsReducer = (state = initialState, action: Action): ProjectsState => {
  switch (action.type) {
    case SET_PROJECTS:
      return { ...state, projects: action.payload };

    case ADD_PROJECT:
      return { ...state, projects: { ...state.projects, [action.payload.id]: { ...action.payload } } };

    case LOGOUT:
      return initialState;

    default:
      return state;
  }
};

export default projectsReducer;
