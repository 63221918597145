import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Router from './router';
import LoaderPage from './components/LoaderPage';
import { GlobalStyle } from './globalStyles';
import { getUser } from 'store/users/usersActions';
import { getCurrentUser, getIsLoading } from './store/users/usersSelectors';
import initFetchData from 'utils/initFetchData';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { getCreatedTaskNumber } from 'store/tasks/tasksSelectors';
import { setCreatedTaskNumber } from 'store/tasks/tasksActions';

const App = () => {
  const dispatch = useDispatch();
  const loading = useSelector(getIsLoading);
  const currentUser = useSelector(getCurrentUser);
  const createdTaskNumber = useSelector(getCreatedTaskNumber);

  console.log('createdTaskNumber', createdTaskNumber);

  useEffect(() => {
    dispatch(getUser());
  }, [dispatch]);

  useEffect(() => {
    if (!currentUser) return;
    initFetchData(dispatch, currentUser.rule, currentUser.projects, currentUser.id);
  }, [currentUser, dispatch]);

  return (
    <>
      <GlobalStyle />

      {loading ? <LoaderPage /> : <Router />}

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={Boolean(createdTaskNumber)}
        autoHideDuration={3000}
        onClose={() => dispatch(setCreatedTaskNumber(null))}
      >
        <MuiAlert variant="filled" severity="success">
          The task created successfully <Link to={`/project/task/${createdTaskNumber}`}>{createdTaskNumber}</Link>
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default App;
