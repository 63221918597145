import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { CustomAlert } from './styles';

type IArchive = {
  open: boolean;
  setOpen: Function;
  handleConfirm: () => void;
  text: string;
};

const ArchiveConfirmModal: React.FC<IArchive> = ({ open, setOpen, handleConfirm, text }) => {
  const handlerDiscard = () => setOpen(false);

  return (
    <Dialog
      open={open}
      onClose={handlerDiscard}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <CustomAlert severity="warning">
        <DialogTitle>{text}</DialogTitle>
        <DialogActions>
          <Button onClick={handleConfirm} color="primary" autoFocus>
            Yes
          </Button>
          <Button onClick={handlerDiscard} color="secondary">
            No
          </Button>
        </DialogActions>
      </CustomAlert>
    </Dialog>
  );
};

export default ArchiveConfirmModal;
