import styled from 'styled-components';
import SvgSprite from '../SvgSprite';

export const BlockItem = styled.div`
  margin-bottom: 25px;
`;

export const Label = styled.div`
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 10px;
`;

export const List = styled.div`
  display: flex;
  line-height: 18px;
  font-size: 12px;
  flex-wrap: wrap;
  width: 100%;
  /* gap: 10px; */
`;

export const ListItem = styled.div`
  position: relative;
  display: block;
  margin-bottom: 8px;
  padding: 0 10px;
`;

export const DownloadLink = styled.a`
  cursor: pointer;
  color: -webkit-link;
  text-decoration: underline;

  &:hover {
    color: #2196f3;
  }
`;

export const FileRemove = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: transparent;
  border: 1px solid #fd6c61;
  position: absolute;
  right: -6px;
  top: -5px;
  font-size: 10px;
  font-weight: 700;
  padding: 0;
  cursor: pointer;
  outline: none;
  
  &:hover {
    transform: scale(1.1);
  }
`;

export const RemoveIcon = styled(SvgSprite)`
  fill: #fd6c61;
  width: 7px;
  height: 7px;
`;

export const EditButton = styled.button`
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;

  svg {
    color: #3f51b5;
    width: 18px;
    height: 18px;
  }
`;

export const FileInput = styled.input`
  outline: none;
`;

export const FileInputLabel = styled.label`
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 16px 0;
  cursor: pointer;
  color: #004AFF;
  margin-top: 3px;
  & svg {
      margin-right: 10px;
  }
`;

export const FilesList = styled.div`
  padding: 8px 16px;
  display: flex;
  flex-direction: column;
  width: 100%;
  background: rgba(255, 255, 255, 0.8);
  & span {
    position: relative;
    color: #004AFF;
    padding: 6px;
  }
`;

export const CloseBtn = styled.span`
  cursor: pointer;
  &::before {
    content: "";
    position: absolute;
    width: 2px;
    height: 12px;
    background-color: #004AFF;
    top: 0;
    right: 0;
    transform: rotate(40deg);
  }
  &::after {
    content: "";
    position: absolute;
    width: 2px;
    height: 12px;
    background-color: #004AFF;
    top: 0;
    right: 0;
    transform: rotate(-40deg);
}
`;

export const FormWrapper = styled.form`
  max-width: 510px;
`;
