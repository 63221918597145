import { RootState } from '../index';
import { Timelog, GroupedTimelog } from './timelogsTypes';

export const getTimelogs = (state: RootState) => [
  ...state.timelog.timelogs.sort((a, b) => b.workDate.toMillis() - a.workDate.toMillis()),
];

export const getGroupedTimelogs = (state: RootState) => {
  const timelogs = getTimelogs(state);

  return timelogs.reduce((acc: GroupedTimelog[], timelog: Timelog) => {
    const sameItem = acc.find(item => item.taskId === timelog.taskId);

    if (sameItem) {
      return acc.map(item => {
        if (item.taskId === timelog.taskId) {
          return { ...item, timelogs: [...item.timelogs, timelog], totalTime: item.totalTime + timelog.time };
        }
        return item;
      });
    }
    acc.push({ totalTime: timelog.time, taskId: timelog.taskId, timelogs: [timelog] });
    return acc;
  }, []);
};

export const getTotalTime = (state: RootState) => {
  const timelogs = getTimelogs(state);
  return timelogs.reduce((acc, item) => acc + item.time, 0);
};
