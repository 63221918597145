import axios from 'axios';

const API = axios.create({
  baseURL: process.env.REACT_APP_FUNCTIONS_URL,
});

export const setAuth = (token: string) => {
  API.defaults.headers.Authorization = `Bearer ${token}`;
};

export const deleteAuth = () => {
  delete API.defaults.headers.Authorization;
};

export default API;
