import React, { useCallback, useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Wrapper,
  Coll,
  Title,
  NavWrapper,
  NavButton,
  NavItem,
  TimeItemHeader,
  TimeItemCont,
  TimeList,
  SubscriberItem,
  UnsubscribeButton,
} from './styles';
import DashboardLayout from 'layouts/Dashboard';
import Comments from 'components/Comments';
import ProjectInfoTask from './ProjectInfoTask';
import { RootState } from 'store';
import {
  addNewMessage,
  downloadFileInStorage,
  editMessage,
  getChatData,
  removeMessage,
  setFilesList,
} from 'store/chatTask/chatTaskActions';
import { ChatItem, FilesList } from 'store/chatTask/chatTaskTypes';
import { Chat, WatchLaterOutlined } from '@material-ui/icons';
import { getTimelogs as getTimelogsSelector } from 'store/timelogs/timelogsSelectors';
import { getTimelogs } from 'store/timelogs/timelogsActions';
import TimelogItem from './TimelogItem';
import {
  getCurrentTaskById,
  getCurrentTaskByNumber,
  getAllTasks as getAllTasksSelector,
} from 'store/tasks/tasksSelectors';
import { getAllUsers, getCurrentUser } from 'store/users/usersSelectors';
import { RULES_OPTIONS } from 'utils/constants';
import useRule from 'hooks/useRule';
import { updateTask } from 'store/tasks/tasksActions';
import { fromStoreTaskToAPITask } from 'store/tasks/tasksHelpers';

const ProjectTaskPage = () => {
  const dispatch = useDispatch();
  const timelogs = useSelector(getTimelogsSelector);
  const { chatData, loadingSendMessage } = useSelector((state: RootState) => state.chatTask);
  const currentUser = useSelector(getCurrentUser);

  const [activePage, setActivePage] = useState('comments');
  const rule = useRule();

  const { id }: { id: string } = useParams();
  const tasks = useSelector(getAllTasksSelector);
  const taskById = useSelector(getCurrentTaskById(id));
  const task = useSelector(getCurrentTaskByNumber(Number(id)));

  const users = useSelector(getAllUsers);

  useEffect(() => {
    if (task && currentUser) {
      dispatch(getChatData(task?.id, currentUser?.id));
    }
  }, [dispatch, task, currentUser]);

  const sendMessage = (description: string, files: FilesList[], filesData: File[]) => {
    if (task && currentUser) {
      dispatch(
        addNewMessage(
          task.id,
          currentUser.id,
          description,
          currentUser.name,
          files,
          filesData,
          task.subscribedUsers,
          task.idProject,
        ),
      );
    }
  };
  const handleRemoveMessage = (element: ChatItem) => {
    task &&
      currentUser &&
      dispatch(
        removeMessage(task.id, element.id, element.description, currentUser.id, task.subscribedUsers, task.idProject),
      );
  };

  const handleUpdateMessage = (idMessage: string, description: string, filesList: FilesList[], files: File[]) => {
    task &&
      currentUser &&
      dispatch(
        editMessage(
          task.id,
          idMessage,
          description,
          filesList,
          files,
          currentUser?.id,
          task.subscribedUsers,
          task.idProject,
        ),
      );
  };

  const handleEditMessage = (files: FilesList[]) => {
    dispatch(setFilesList(files ?? []));
  };

  const handleDownloadFile = (file: FilesList) => {
    dispatch(downloadFileInStorage(file.path));
  };

  const onUnsubscribe = useCallback(
    userId => {
      if (!task) return;
      const { task: newTask, id: taskId } = fromStoreTaskToAPITask({
        ...task,
        subscribedUsers: task.subscribedUsers.filter(item => item !== userId),
      });
      dispatch(updateTask(newTask, taskId));
    },
    [dispatch, task],
  );

  useEffect(() => {
    if (!task?.id) return;
    dispatch(getTimelogs(task.id, 'taskId'));
  }, [task, dispatch]);

  if (!task && taskById && Object.keys(tasks).length > 0) {
    return <Redirect to={`/project/task/${taskById.taskNumber}`} />;
  }

  if (!task && Object.keys(tasks).length > 0) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <DashboardLayout>
      <Wrapper>
        <Coll>{task !== null && <ProjectInfoTask task={task} />}</Coll>
        <NavWrapper>
          <NavItem>
            <NavButton
              className={activePage === 'comments' ? 'active' : ''}
              type="button"
              onClick={() => setActivePage('comments')}
            >
              <Chat fontSize="default" />
              <span className="navButtonText">Comments</span>
            </NavButton>
          </NavItem>

          <NavItem>
            <NavButton
              className={activePage === 'timelogs' ? 'active' : ''}
              type="button"
              onClick={() => setActivePage('timelogs')}
            >
              <WatchLaterOutlined fontSize="default" />
              <span className="navButtonText">Log Work</span>
            </NavButton>
          </NavItem>

          {rule === RULES_OPTIONS.admin.value && (
            <NavItem>
              <NavButton
                className={activePage === 'subscribers' ? 'active' : ''}
                type="button"
                onClick={() => setActivePage('subscribers')}
              >
                <WatchLaterOutlined fontSize="default" />
                <span className="navButtonText">Subscribers</span>
              </NavButton>
            </NavItem>
          )}
        </NavWrapper>
        {activePage === 'comments' && (
          <Coll comments>
            <Title>Comments:</Title>
            <Comments
              taskId={task?.id}
              data={chatData}
              sendMessage={sendMessage}
              sendLoading={loadingSendMessage}
              handleRemove={handleRemoveMessage}
              handleUpdateMessage={handleUpdateMessage}
              handleEditMessage={handleEditMessage}
              handleDownloadFile={handleDownloadFile}
            />
          </Coll>
        )}
        {activePage === 'timelogs' && (
          <Coll timelogs>
            <TimeList>
              <TimeItemHeader>
                <TimeItemCont>Created at</TimeItemCont>
                <TimeItemCont>Worked at</TimeItemCont>
                <TimeItemCont>User</TimeItemCont>
                <TimeItemCont>Time</TimeItemCont>
                <TimeItemCont>Description</TimeItemCont>
                <TimeItemCont buttons />
              </TimeItemHeader>

              {timelogs.map(timelog => (
                <TimelogItem key={timelog.id} timelog={timelog} />
              ))}
            </TimeList>
          </Coll>
        )}
        {activePage === 'subscribers' && (
          <Coll>
            <TimeList>
              {task.subscribedUsers.map(userId => (
                <SubscriberItem>
                  <span>{users[userId]?.name || ''}</span>
                  <UnsubscribeButton onClick={() => onUnsubscribe(userId)} type="button">
                    Unsubscribe
                  </UnsubscribeButton>
                </SubscriberItem>
              ))}
            </TimeList>
          </Coll>
        )}
      </Wrapper>
    </DashboardLayout>
  );
};

export default ProjectTaskPage;
