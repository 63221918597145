import React, { useMemo } from 'react';
import { Wrapper, Estimate, TimeLeft, TimeSpent, TimeExpired } from './styles';

interface TimeIndicatorProps {
  estimate: number;
  totalTime: number;
  className?: string;
}

const TimeIndicator = ({ estimate, totalTime, className = '' }: TimeIndicatorProps) => {
  const timeData = useMemo(() => {
    const isExpired = estimate < totalTime;
    const estimateTime = isExpired ? (estimate / totalTime) * 100 : 100;
    const timeExpired = 100 - estimateTime;
    const timeSpent = isExpired ? 100 : (totalTime / estimate) * 100;
    const timeLeft = 100 - timeSpent;
    return {
      estimateTime,
      timeLeft,
      timeSpent,
      timeExpired,
    };
  }, [estimate, totalTime]);
  return (
    <Wrapper className={className}>
      <Estimate width={timeData.estimateTime} />
      <TimeLeft width={timeData.timeLeft} />
      <TimeSpent width={timeData.timeSpent} />
      <TimeExpired width={timeData.timeExpired} />
    </Wrapper>
  );
};

TimeIndicator.defaultProps = {
  className: '',
};

export default TimeIndicator;
