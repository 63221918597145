import { RecurringTaskStore, Status, TaskStore, TaskUpdate, RecurringTaskCreate } from './tasksTypes';
import { STATUSES_OPTIONS } from 'utils/constants';

export const fromStoreTaskToAPITask = (task: TaskStore): { task: TaskUpdate; id: string } => ({
  task: {
    dateCreated: task.dateCreated.toDate(),
    dateCompleted: task.dateCompleted?.toDate() || null,
    dateEnd: task.dateEnd?.toDate() || null,
    dateProcessed: task.dateProcessed?.toDate() || null,
    description: task.description,
    estimate: task.estimate,
    idProject: task.idProject,
    isArchive: task.isArchive,
    name: task.name,
    priority: task.priority,
    status: task.status,
    timeDoctorId: task.timeDoctorId,
    totalTime: task.totalTime,
    userId: task.userId,
    files: [...task.files],
    taskNumber: task.taskNumber,
    subscribedUsers: [...task.subscribedUsers],
  },
  id: task.id,
});

export const fromStoreRecurringTaskToAPI = (task: RecurringTaskStore): { task: RecurringTaskCreate; id: string } => ({
  task: {
    name: task.name,
    deadline: task.deadline,
    description: task.description,
    project: task.project,
    worker: task.worker,
    estimate: task.estimate,
    status: task.status,
    periodSelect: task.periodSelect,
    periodNumber: task.periodNumber,
    subscribedUsers: task.subscribedUsers || [],
  },
  id: task.id,
});

export const getTasksByStatuses = (tasks: TaskStore[]) => {
  const initialAcc = Object.keys(STATUSES_OPTIONS).reduce((acc, key) => ({ ...acc, [key]: [] }), {}) as {
    [key in Status]: TaskStore[];
  };

  return tasks.reduce(
    (acc, task) => ({
      ...acc,
      [task.status]: acc[task.status] ? [...acc[task.status], task] : [task],
    }),
    initialAcc,
  );
};
