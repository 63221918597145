import React, { useMemo } from 'react';
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';
import { FormWrapper, SendButton, InputWrapper, useStyles, SpanText, ErrorMessageBlock, SelectStyles } from './style';
import CustomSelect from 'components/CustomSelect';
import TextField from '@material-ui/core/TextField';
import { useHistory } from 'react-router-dom';
import { Rule, User } from 'store/users/usersTypes';
import { useDispatch, useSelector } from 'react-redux';
import SvgSprite from 'components/SvgSprite';
import { validateEmail } from 'utils/validate';
import { createUser, editUser } from 'store/users/usersActions';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { getProjects } from 'store/projects/projectsSelectors';
import { RULES_OPTIONS } from 'utils/constants';
import { getTimedoctorConfig, getTimeDoctorUsers } from 'store/timedoctor/timedoctorSelectors';

interface Props {
  user?: User | null;
  add?: boolean;
}

interface Option {
  value: string;
  label: string;
}

const UserManegmentForm = ({ user = null, add = false }: Props) => {
  const classes = useStyles();
  const projects = useSelector(getProjects);
  const timeDoctorConfig = useSelector(getTimedoctorConfig);
  const timeDoctorUsers = useSelector(getTimeDoctorUsers);

  const projectsOptions = useMemo(
    () => Object.keys(projects).map(key => ({ value: projects[key].id, label: projects[key].name })),
    [projects],
  );

  const { control, register, errors, handleSubmit } = useForm({
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    defaultValues: {
      firstName: user ? user.name.split(' ')[0] : '',
      lastName: user ? user.name.split(' ')[1] : '',
      email: user ? user.email : '',
      role: user ? RULES_OPTIONS[user.rule] : Object.values(RULES_OPTIONS)[0],
      projects: user
        ? user.projects.reduce((acc: Option[], projectId) => {
            if (projects[projectId]) return [...acc, { value: projectId, label: projects[projectId].name }];
            return acc;
          }, [])
        : [],
      worker: user ? user.isWorker : false,
      isMailSubscribed: user ? user.isMailSubscribed : true,
      syncWithTimeDoctor: false,
    },
  });

  const history = useHistory();
  const dispatch = useDispatch();

  const onSubmit = async (data: {
    email: string;
    firstName: string;
    lastName: string;
    role: Option;
    projects: Option[] | null;
    worker: boolean;
    isMailSubscribed: boolean;
    syncWithTimeDoctor?: boolean;
  }) => {
    if (add) {
      dispatch(
        createUser(
          {
            email: data.email,
            name: `${data.firstName} ${data.lastName}`,
            rule: data.role.value as Rule,
            projects: data.projects ? data.projects.map((item: Option) => item.value) : [],
            worker: data.worker,
            isMailSubscribed: data.isMailSubscribed,
          },
          data.syncWithTimeDoctor,
        ),
      );
    } else {
      dispatch(
        editUser(
          {
            email: data.email,
            name: `${data.firstName} ${data.lastName}`,
            rule: data.role.value as Rule,
            projects: data.projects ? data.projects.map((item: Option) => item.value) : [],
            worker: data.worker,
            id: user?.id,
            isMailSubscribed: data.isMailSubscribed,
          },
          data.syncWithTimeDoctor,
        ),
      );
    }
    history.push('/users');
  };

  return (
    <FormWrapper className={classes.root} onSubmit={handleSubmit(onSubmit)}>
      <InputWrapper>
        <TextField
          fullWidth
          name="firstName"
          label="First name"
          variant="filled"
          color="primary"
          autoComplete="off"
          inputRef={register({
            validate: (value): boolean => value.length >= 4,
          })}
          error={Boolean(errors.firstName)}
        />
        {errors.firstName ? <ErrorMessageBlock>Min 4 symbols</ErrorMessageBlock> : null}
        <SvgSprite className="inputIcon" width={28} height={28} fill="#004AFF" name="post_add" />
      </InputWrapper>

      <InputWrapper>
        <TextField
          fullWidth
          name="lastName"
          label="Last name"
          variant="filled"
          color="primary"
          autoComplete="off"
          inputRef={register({
            validate: (value): boolean => value.length >= 4,
          })}
          error={Boolean(errors.lastName)}
        />
        {errors.lastName ? <ErrorMessageBlock>Min 4 symbols</ErrorMessageBlock> : null}
        <SvgSprite className="inputIcon" width={28} height={28} fill="#004AFF" name="post_add" />
      </InputWrapper>

      <InputWrapper>
        <TextField
          fullWidth
          name="email"
          label="Email"
          variant="filled"
          color="primary"
          autoComplete="off"
          inputRef={register({
            validate: (value): boolean => validateEmail(value),
          })}
          error={Boolean(errors.email)}
        />
        {errors.lastName ? <ErrorMessageBlock>Enter a valid email</ErrorMessageBlock> : null}
        <SvgSprite className="inputIcon" width={28} height={28} fill="#004AFF" name="post_add" />
      </InputWrapper>

      <InputWrapper>
        <Controller
          className="role"
          as={CustomSelect}
          name="role"
          add
          label="Role"
          defaultValue={user ? RULES_OPTIONS[user.rule] : Object.values(RULES_OPTIONS)[0]}
          options={Object.values(RULES_OPTIONS)}
          control={control}
          placeholder="Role"
          height={16}
          width={22}
          error={Boolean(errors.role)}
          stroke="#004AFF"
        />
        <SvgSprite className="inputIcon" width={28} height={28} stroke="#004AFF" name="folders" />
      </InputWrapper>

      <InputWrapper>
        <Controller
          className="projects"
          isMulti
          options={projectsOptions}
          as={Select}
          styles={SelectStyles}
          name="projects"
          label="Projects"
          defaultValue={user ? projectsOptions.filter(o => user.projects.includes(o.value)) : []}
          control={control}
          placeholder="Projects"
          height={16}
          width={22}
          error={Boolean(errors.projects)}
          stroke="#004AFF"
        />
        {errors.projects ? <ErrorMessageBlock>Projects shouldn&apos;t be empty</ErrorMessageBlock> : null}
        <SvgSprite className="inputIcon" width={28} height={28} stroke="#004AFF" name="folders" />
      </InputWrapper>

      <InputWrapper>
        <FormControlLabel
          control={<Checkbox defaultChecked={Boolean(user?.isWorker)} />}
          inputRef={register()}
          label="Allow to assign the task to user"
          name="worker"
          color="#004AFF"
        />
      </InputWrapper>

      <InputWrapper>
        <FormControlLabel
          control={<Checkbox defaultChecked={Boolean(user ? user.isMailSubscribed : true)} />}
          inputRef={register()}
          label="Send daily email notifications"
          name="isMailSubscribed"
          color="#004AFF"
        />
      </InputWrapper>

      {timeDoctorConfig?.enabled && (
        <InputWrapper>
          <FormControlLabel
            control={<Checkbox defaultChecked={user ? timeDoctorUsers.includes(user.email) : false} />}
            inputRef={register()}
            label="Sync with TimeDoctor"
            name="syncWithTimeDoctor"
            color="#004AFF"
            disabled={!!user && timeDoctorUsers.includes(user.email)}
          />
        </InputWrapper>
      )}

      <SendButton type="submit">
        <SpanText>Save</SpanText>
      </SendButton>
    </FormWrapper>
  );
};

UserManegmentForm.defaultProps = {
  user: null,
  add: false,
};

export default UserManegmentForm;
