import { Project, ProjectStore } from './projectsTypes';

export const fromStoreProjectToAPIProject = (project: ProjectStore): { project: Project; id: string } => ({
  project: {
    name: project.name,
    idTimeDoctor: project.idTimeDoctor,
    description: project.description,
    isArchive: project.isArchive,
    isEnabled: project.isEnabled,
  },
  id: project.id,
});
