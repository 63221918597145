import React, { useCallback, useState } from 'react';
import { Backdrop, Fade, Modal, TextField } from '@material-ui/core';
import {
  ModalContainer,
  Title,
  FormWrapper,
  useStyles,
  InputWrapper,
  ErrorMessageBlock,
  SendButton,
  SpanText,
} from './styles';
import SvgSprite from 'components/SvgSprite';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { Timelog } from 'store/timelogs/timelogsTypes';
import { validateTime, getHoursAndMinutesFromTime } from './helpers';

interface TimelogModalProps {
  open: boolean;
  handleClose: () => void;
  onSubmit: (data: { time: number; workDate: Date; description: string }) => void;
  timelog?: Timelog;
}

const TimelogModal = ({ open, handleClose, onSubmit, timelog }: TimelogModalProps) => {
  const classes = useStyles();
  const [timeError, setTimeError] = useState<string>('');

  const { register, errors, handleSubmit } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    defaultValues: {
      time: getHoursAndMinutesFromTime(timelog?.time || 0, true),
      workDate: dayjs(timelog?.workDate.toDate() || new Date()).format('YYYY-MM-DD'),
      description: timelog?.description || '',
    },
    shouldFocusError: true,
  });

  const onSubmitHelper = useCallback(
    (data: { time: string; workDate: string; description: string }) =>
      onSubmit({
        time: validateTime(data.time).time,
        workDate: new Date(data.workDate),
        description: data.description,
      }),
    [onSubmit],
  );

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <ModalContainer>
          <SvgSprite onClick={handleClose} name="cancel" className="close" />
          <Title>Log work</Title>

          <FormWrapper
            className={classes.root}
            onSubmit={e => {
              e.stopPropagation();
              handleSubmit(onSubmitHelper)(e);
            }}
          >
            <InputWrapper>
              <TextField
                fullWidth
                name="time"
                label="Hours"
                variant="filled"
                color="primary"
                autoComplete="off"
                inputRef={register({
                  validate: (value: string): boolean => {
                    const validationResult = validateTime(value);
                    if (validationResult.error) {
                      setTimeError(validationResult.error);
                      return false;
                    }
                    return true;
                  },
                })}
                error={Boolean(errors.time)}
              />
              {errors.time ? <ErrorMessageBlock>{timeError || 'Should be a valid time'}</ErrorMessageBlock> : null}
              <SvgSprite className="inputIcon" width={20} height={20} fill="#004AFF" name="calendar" />
            </InputWrapper>

            <InputWrapper>
              <TextField
                fullWidth
                name="workDate"
                label="Date"
                variant="filled"
                color="primary"
                autoComplete="off"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                defaultValue={dayjs(timelog?.workDate.toDate() || new Date()).format('YYYY-MM-DD')}
                inputRef={register({
                  validate: (value): boolean => {
                    const tomorrow = new Date();
                    tomorrow.setDate(tomorrow.getDate() + 1);
                    tomorrow.setHours(0, 0, 0, 0);
                    return !isNaN(Date.parse(value)) && new Date(value) <= tomorrow;
                  },
                })}
                error={Boolean(errors.workDate)}
              />
              {errors.workDate ? (
                <ErrorMessageBlock>Should be a valid date and less or equal today</ErrorMessageBlock>
              ) : null}
              <SvgSprite className="inputIcon" width={20} height={20} fill="#004AFF" name="calendar" />
            </InputWrapper>

            <InputWrapper>
              <TextField
                fullWidth
                multiline
                name="description"
                label="Description"
                variant="filled"
                color="primary"
                autoComplete="off"
                inputRef={register({
                  validate: (value): boolean => value.length >= 10,
                })}
                error={Boolean(errors.description)}
              />
              {errors.description ? <ErrorMessageBlock>Min 10 symbols</ErrorMessageBlock> : null}
              <SvgSprite className="inputIcon" width={28} height={28} fill="#004AFF" name="post_add" />
            </InputWrapper>

            <SendButton type="submit">
              <SpanText>Submit</SpanText>
            </SendButton>
          </FormWrapper>
        </ModalContainer>
      </Fade>
    </Modal>
  );
};

TimelogModal.defaultProps = {
  timelog: null,
};

export default TimelogModal;
