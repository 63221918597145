import { Project, SET_PROJECTS, ProjectStore, ADD_PROJECT, Projects } from './projectsTypes';
import firebase from 'firebase/app';
import { db } from 'utils/firebase';
import API from 'utils/api';
import { AppThunk } from 'store';
import getIdToken from 'utils/getIdToken';
import { logout } from 'store/users/usersActions';
import { Action } from 'utils/commonTypes';

const projectsRef = db.collection('projects');

export const setProjects = (payload: Projects): Action => ({
  type: SET_PROJECTS,
  payload,
});

export const addProject = (payload: ProjectStore): Action => ({
  type: ADD_PROJECT,
  payload,
});

export const getAllProjects = (projects?: string[] | null): AppThunk => async dispatch => {
  try {
    let projectsResult;
    if (!projects) {
      projectsResult = await projectsRef.where('isArchive', '==', false).get();
    } else {
      projectsResult = await projectsRef
        .where(firebase.firestore.FieldPath.documentId(), 'in', projects)
        .where('isArchive', '==', false)
        .get();
    }
    const projectsData = projectsResult.docs.reduce(
      (acc, item) => ({ ...acc, [item.id]: { ...item.data(), id: item.id } }),
      {},
    ) as Projects;
    dispatch(setProjects(projectsData));
  } catch (error) {
    console.error(error);
  }
};

export const createProject = ({
  name,
  description,
}: {
  name: string;
  description: string;
}): AppThunk => async dispatch => {
  try {
    const token = await getIdToken();
    if (!token) {
      dispatch(logout());
      throw Error('Auth failed');
    }
    const response = await API.post('/project', {
      name,
      description,
      isArchive: false,
      isEnabled: true,
    });
    const { projectId } = response.data;
    if (!projectId) throw Error('Failed');
    const project = await projectsRef.doc(projectId).get();
    const projectData = project.data() as Project;
    dispatch(addProject({ ...projectData, id: project.id }));
  } catch (error) {
    console.log(error);
  }
};

export const updateProject = (data: Project, id: string): AppThunk => async dispatch => {
  try {
    const token = await getIdToken();
    if (!token) {
      dispatch(logout());
      throw Error('Auth failed');
    }
    const res = await API.put(`/project/${id}`, data);

    const { projectId } = res.data;
    const project = await projectsRef.doc(projectId).get();
    const projectData = project.data() as Project;

    dispatch(addProject({ ...projectData, id: project.id }));
  } catch (error) {
    console.log(error);
  }
};
