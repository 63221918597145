import firebase from 'firebase/app';

const namespace = 'TIMEDOCTOR';

export const SET_CONFIG = `${namespace}/SET_CONFIG`;
export const SET_USERS = `${namespace}/SET_USERS`;
export const SET_LOADING = `${namespace}/SET_LOADING`;
export const SET_ERROR = `${namespace}/SET_ERROR`;

export interface TimedoctorConfig {
  token: string;
  companyId: string;
  expiresAt: firebase.firestore.Timestamp;
  createdAt: firebase.firestore.Timestamp;
  enabled: boolean;
}

export interface TimedoctorQuery {
  email: string;
  password: string;
}

export interface TimedoctorState {
  config: TimedoctorConfig | null;
  loading: boolean;
  error: string | null;
  users: string[];
}
