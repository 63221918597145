import { db, storage } from 'utils/firebase';
import { v4 } from 'uuid';
import {
  ChatTaskActions,
  ChatItem,
  FilesList,
  SET_LOADING_CHAT,
  SET_CHAT_DATA,
  SET_LOADING_SEND_MESSAGE,
  SET_FILES_INFO,
  SET_LOADING_DOCS,
  SET_BAD_TYPES_FILES,
} from './chatTaskTypes';
import { AppThunk, RootState } from '../index';
import { getCurrentDate, getCurrentMilliseconds } from 'utils/datesFunctions';
import { createNotifications } from '../notifications/notificationsActions';

const storageRef = storage.ref('messagesFile');
const chartsRef = db.collection('chats');

export const setLoadingChat = (payload: boolean): ChatTaskActions => ({
  type: SET_LOADING_CHAT,
  payload,
});

export const setLoadingSendMessage = (payload: boolean): ChatTaskActions => ({
  type: SET_LOADING_SEND_MESSAGE,
  payload,
});

export const setChatData = (payload: ChatItem[]): ChatTaskActions => ({
  type: SET_CHAT_DATA,
  payload,
});

export const setFilesList = (payload: FilesList[]): ChatTaskActions => ({
  type: SET_FILES_INFO,
  payload,
});
export const setBadTypesFiles = (payload: FilesList[]): ChatTaskActions => ({
  type: SET_BAD_TYPES_FILES,
  payload,
});
export const setLoadingDocs = (payload: boolean): ChatTaskActions => ({
  type: SET_LOADING_DOCS,
  payload,
});

let observerChat = () => {};
export const getChatData = (idChat: string, uid: string): AppThunk => dispatch => {
  try {
    const messageRef = chartsRef.doc(idChat).collection('messages');
    let resultMessages: ChatItem[] = [];
    observerChat = messageRef.onSnapshot(querySnapshot => {
      querySnapshot.docChanges().forEach(change => {
        const data = {
          ...change.doc.data(),
          id: change.doc.id,
        } as ChatItem;
        if (change.type === 'added') {
          const align = data.author === uid ? 'right' : 'left';
          resultMessages.push({
            ...data,
            align,
          });
        }
        if (change.type === 'modified') {
          resultMessages = resultMessages.map(item => {
            if (item.id === data.id) {
              const align = data.author === uid ? 'right' : 'left';
              return {
                ...data,
                align,
              };
            }
            return item;
          });
        }
        if (change.type === 'removed') {
          resultMessages = resultMessages.filter(item => item.id !== data.id);
        }
      });

      const sortMessages = resultMessages.sort((a, b) => a.time - b.time);
      dispatch(setChatData(sortMessages));
    });
  } catch (e) {
    console.log(e);
  }
};

export const removeMessages = (): AppThunk => () => {
  observerChat();
};

export const addNewMessage = (
  idChat: string,
  uid: string,
  description: string,
  nameAuthor: string,
  files: FilesList[],
  filesData: File[],
  subscribers: string[],
  projectId: string,
): AppThunk => async dispatch => {
  try {
    dispatch(setLoadingSendMessage(true));

    const messagesRef = chartsRef.doc(idChat).collection('messages');
    const time: number = getCurrentMilliseconds();
    const date = getCurrentDate('MMMM DD YYYY HH:mm');

    for await (const [index, file] of Object.entries(files)) {
      // @ts-ignore
      const fileData: any = filesData[index];
      await storageRef.child(file.path).put(fileData);
    }

    dispatch(
      createNotifications({
        action: 'commented',
        message: description,
        taskId: idChat,
        triggeredBy: uid,
        subscribers,
        projectId,
      }),
    );

    return await messagesRef.add({
      author: uid,
      time,
      date,
      nameAuthor,
      description,
      files,
    });
  } catch (e) {
    console.log(e);
    return e;
  } finally {
    dispatch(setLoadingSendMessage(false));
  }
};

export const removeMessage = (
  idChat: string,
  idMessage: string,
  description: string,
  userId: string,
  subscribers: string[],
  projectId: string,
): AppThunk => async dispatch => {
  try {
    const messagesRef = chartsRef.doc(idChat).collection('messages');

    dispatch(
      createNotifications({
        action: 'commentDelete',
        message: description,
        taskId: idChat,
        triggeredBy: userId,
        subscribers,
        projectId,
      }),
    );

    return await messagesRef.doc(idMessage).delete();
  } catch (e) {
    console.log(e);
    return e;
  }
};

export const editMessage = (
  idChat: string,
  idMessage: string,
  description: string,
  filesList: FilesList[],
  files: File[],
  userId: string,
  subscribers: string[],
  projectId: string,
): AppThunk => async dispatch => {
  try {
    const messagesRef = chartsRef.doc(idChat).collection('messages');
    const tokenData: any = filesList.find(el => el.token);

    for await (const file of files) {
      const proto = Object.getPrototypeOf(file).toString();
      if (proto === '[object File]') {
        const path = `${idChat}/${tokenData.token}/${file.name}`;
        await storageRef.child(path).put(file);
      }
    }

    dispatch(
      createNotifications({
        action: 'commentUpdate',
        message: description,
        taskId: idChat,
        triggeredBy: userId,
        subscribers,
        projectId,
      }),
    );

    return await messagesRef.doc(idMessage).update({
      description,
      files: filesList,
    });
  } catch (e) {
    console.log(e);
    return e;
  }
};

export const addFilesAndCreateMessage = (
  idTask: string,
  files: File[],
  tokenValue?: string,
): AppThunk => async dispatch => {
  try {
    dispatch(setLoadingDocs(true));
    const resultFiles: FilesList[] = [];
    const token: string = tokenValue ?? v4();

    for (const file of files) {
      const path = `${idTask}/${token}/${file.name}`;

      resultFiles.push({
        ...file,
        lastModified: file.lastModified,
        name: file.name,
        size: file.size,
        type: file.type,
        path,
        token,
      });
    }

    dispatch(setFilesList(resultFiles));
    dispatch(setLoadingDocs(false));
    return resultFiles;
  } catch (e) {
    console.log(e);
    return e;
  }
};

export const removeFileFromStorage = (idTask: string, path: string): AppThunk => async (dispatch, getState) => {
  try {
    const store: RootState = getState();
    const { filesList } = store.chatTask;

    const filterFiles = filesList.filter(item => item.path !== path);
    dispatch(setFilesList(filterFiles));
  } catch (e) {
    console.log(e);
  }
};

export const removeFileFromMessage = (
  idTask: string,
  idMessage: string,
  file: FilesList,
  isSetStore: boolean = false,
): AppThunk => async (dispatch, getState) => {
  try {
    const store: RootState = getState();
    const { chatData } = store.chatTask;
    const messageRef = chartsRef.doc(idTask).collection('messages');

    const updateFilesList: FilesList[] = [];
    let selectMessage = {} as ChatItem;
    chatData.forEach(item => {
      if (item.id === idMessage) {
        const filterFiles = item.files.filter(el => el.path !== file.path);
        updateFilesList.push(...filterFiles);
        selectMessage = item;
      }
    });

    const findElement = selectMessage.files.findIndex(item => item.path === file.path);
    if (isSetStore && findElement > -1) {
      await storageRef.child(file.path).delete();
      await messageRef.doc(idMessage).update({
        files: updateFilesList,
      });
    }
    dispatch(setFilesList(updateFilesList));
  } catch (e) {
    console.log(e);
  }
};

export const downloadFileInStorage = (path: string): AppThunk => async () => {
  try {
    const downloadPath = await storageRef.child(path).getDownloadURL();

    window.open(downloadPath, '__blank');
  } catch (e) {
    console.log(e);
  }
};
