import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import Dashboard from 'layouts/Dashboard';
import RecurringTasksForm from 'components/RecurringTasks/RecurringTasksForm';
import { updateRecurringTask } from 'store/tasks/tasksActions';
import { fromStoreRecurringTaskToAPI } from 'store/tasks/tasksHelpers';
import { RecurringTaskCreate } from 'store/tasks/tasksTypes';
import { getRecurringTaskById, getRecurringTasks } from 'store/tasks/tasksSelectors';
import LoaderPage from 'components/LoaderPage';

const EditRecurringTaskPage = () => {
  const { id: taskId }: { id: string } = useParams();

  const dispatch = useDispatch();

  const task = useSelector(getRecurringTaskById(taskId));
  const allTasks = useSelector(getRecurringTasks);

  const handleEdit = useCallback(
    async (data: RecurringTaskCreate) => {
      if (!task) return;
      const { task: newTask, id } = fromStoreRecurringTaskToAPI({ ...task, ...data });

      await dispatch(updateRecurringTask(newTask, id));
    },
    [task, dispatch],
  );

  return (
    <Dashboard>
      {!task && Object.keys(allTasks).length && <Redirect to="/recurring-tasks" />}
      {!task || !Object.keys(allTasks).length ? (
        <LoaderPage />
      ) : (
        <RecurringTasksForm onSubmit={handleEdit} task={task} />
      )}
    </Dashboard>
  );
};

export default EditRecurringTaskPage;
