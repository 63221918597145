import styled from 'styled-components';

export const Container = styled.div``;

export const LoadingWrapper = styled.div`
  margin: 30px 0;
  display: flex;
  justify-content: center;
`;

export const EmptyMessage = styled.div`
  text-align: center;
  font-size: 24px;
  font-weight: 700;
`;
