import { Badge } from '@material-ui/core';
import styled, { css } from 'styled-components';
import SvgSprite from '../../SvgSprite';

export const Name = styled.div`
  font-size: 14px;
  line-height: 14px;
  color: #273686;
`;

export const Icon = styled(SvgSprite)`
  width: 26px;
  height: 27px;
  fill: #273686;
  margin-right: 7px;

  ${({ width, height }) =>
    css`
      width: ${width}px;
      height: ${height}px;
    `}
`;

export const CustomBadge = styled(Badge)`
  margin: 0 0 10px 5px;
`;

export const Card = styled.div`
  display: flex;
  align-items: center;
  min-height: 42px;

  &.last {
    ${Icon} {
      fill: transparent;
      stroke: #273686;
    }

    a {
      &.selected {
        ${Icon} {
          fill: transparent;
          stroke: #00a7e4;
        }
      }
    }
  }

  a {
    width: 100%;
    min-height: 42px;
    height: 100%;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 9px 16px 6px 16px;
    position: relative;
    background-color: white;
    transition: all 0.3s;
    border-radius: 3px;

    &:hover {
      background-color: rgba(0, 167, 228, 0.1);
    }

    &:before {
      content: '';
      position: absolute;
      top: calc(100%);
      left: 0;
      height: 2px;
      width: 100%;
      background: #4c7ffd;
      opacity: 0;
      transition: all 0.3s;
    }

    &.selected {
      &:before {
        opacity: 1;
      }

      ${Name} {
        color: #4c7ffd;
      }

      ${Icon} {
        fill: #4c7ffd;
      }
    }
  }
`;

export const Counter = styled.div`
  width: 19px;
  height: 19px;
  background: #e41e1e;
  top: 2px;
  left: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  line-height: 119.18%;
  color: #ffffff;
  border-radius: 50%;
  position: absolute;
`;
