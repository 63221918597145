import { TimelogState, SET_TIMELOGS, ADD_TIMELOG, RESET_TIMELOGS, REMOVE_TIMELOG } from './timelogsTypes';
import { LOGOUT } from '../users/usersTypes';

const initialState = {
  timelogs: [],
};

const timelogsReducer = (state: TimelogState = initialState, action: { payload: any; type: string }): TimelogState => {
  switch (action.type) {
    case SET_TIMELOGS:
      return {
        ...state,
        timelogs: [...action.payload],
      };

    case RESET_TIMELOGS:
      return {
        ...state,
        timelogs: [],
      };

    case ADD_TIMELOG:
      return {
        ...state,
        timelogs: [...state.timelogs, action.payload],
      };

    case REMOVE_TIMELOG:
      return {
        ...state,
        timelogs: [...state.timelogs.filter(item => item.id !== action.payload)],
      };

    case LOGOUT:
      return initialState;

    default:
      return state;
  }
};

export default timelogsReducer;
