import { RootState } from '../index';
import { User } from './usersTypes';

export const getAllUsers = (state: RootState) => state.users.users;

export const getWorkers = (state: RootState) => {
  const allUsers = getAllUsers(state);
  return Object.values(allUsers).reduce((acc: { [key: string]: User }, user) => {
    if (user.isWorker) {
      return { ...acc, [user.id]: { ...user } };
    }
    return acc;
  }, {});
};

export const getCurrentUser = (state: RootState) => {
  const allUsers = getAllUsers(state);
  const { id } = state.users.currentUser;

  if (!id) return null;

  return allUsers[id] || null;
};

export const getUserById = (id: string) => (state: RootState) => {
  const allUsers = getAllUsers(state);

  return allUsers[id] || null;
};

export const getLoginError = (state: RootState) => state.users.currentUser.error;
export const getIsLoggedIn = (state: RootState) => state.users.currentUser.isLoggedIn;
export const getIsLoading = (state: RootState) => state.users.currentUser.isLoading;

export const getCurrentUserRule = (state: RootState) => getCurrentUser(state)?.rule || null;
