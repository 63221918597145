import styled from 'styled-components';

export const ItemStyled = styled.li`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #000000;
    padding: 14px 29px 14px 20px;

    &&:not(:last-child) {
        margin-bottom: 9px;
    }
`;

export const LeftWrapper = styled.div`
    display: flex;
`;

export const NameStyled = styled.div`
    width: 302px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;

export const RoleStyled = styled.div`
    width: 73px;
    margin-right: 77px;
`;

export const EditButton = styled.button`
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #004AFF;
    border: none;
    background: none;
    cursor: pointer;
`;

export const ButtonsContainer = styled.div`
    display: flex;
`;

export const DisableButton = styled.button<{ onClick: any; isDelete: boolean; }>`
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: ${(props) => (props.isDelete ? 'green' : 'red')};
    border: none;
    background: none;
    cursor: pointer;
`;
