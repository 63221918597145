import React, { useCallback } from 'react';
import Dashboard from 'layouts/Dashboard';
import AddProjectForm from 'components/AddProjectForm';
import { useDispatch } from 'react-redux';
import { createProject } from 'store/projects/projectsActions';

const CreateProjectPage = () => {
  const dispatch = useDispatch();

  const onSubmit = useCallback(
    async (data: any) => {
      await dispatch(createProject(data));
    },
    [dispatch],
  );

  return (
    <Dashboard>
      <AddProjectForm onSubmit={onSubmit} submitText="Add Project" />
    </Dashboard>
  );
};

export default CreateProjectPage;
