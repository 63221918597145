import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  max-width: 80vw;
`;

export const Coll = styled.div<{ comments?: boolean; timelogs?: boolean }>`
  width: ${props => (props.comments ? '50%' : '100%')};
  padding-right: 20px;
  background: ${props => (props.timelogs ? '#fff' : 'transparent')};

  &:last-child {
    padding-left: 20px;
    margin-top: 40px;
  }

  @media screen and (max-width: 992px) {
    width: 100%;
    margin-bottom: 30px;
  }
`;

export const NavWrapper = styled.ul`
  display: flex;
  align-items: center;
  margin-top: 40px;
`;

export const NavItem = styled.li`
  &:not(:last-child) {
    margin-right: 5px;
  }
`;

export const NavButton = styled.button`
  background: none;
  color: #273686;
  border: none;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  display: flex;
  align-items: center;
  padding-bottom: 6px;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  transition: color 200ms ease, border-color 200ms ease;

  &.active,
  &:hover {
    color: #00a7e4;
    border-color: #00a7e4;
  }

  .navButtonText {
    margin-left: 8px;
  }
`;

export const Title = styled.h3`
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 25px;
`;

export const TimeList = styled.ul`
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
`;

export const TimeItemHeader = styled.li`
  display: flex;
  justify-content: space-between;
  font-weight: 700;
  margin-bottom: 15px;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
`;

export const TimeItemCont = styled.div<{ buttons?: boolean }>`
  width: ${props => (props.buttons ? '85px' : 'calc((100% - 85px) / 5)')};
`;

export const SubscriberItem = styled.li`
  display: flex;
  font-size: 12px;
  line-height: 14px;
`;

export const UnsubscribeButton = styled.button`
  border: none;
  cursor: pointer;
  color: #004aff;
  background: none;
  font-size: 12px;
  line-height: 14px;
  margin-left: 20px;
`;
