import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllNotifications } from 'store/notifications/notificationsSelectors';
import NotificationsItem from './NotificationsItem';
import { Container, DeleteButton } from './styles';
import { deleteAllNotifications } from '../../store/notifications/notificationsActions';

const NotificationsList = () => {
  const dispatch = useDispatch();
  const notifications = useSelector(getAllNotifications);

  const onDelete = useCallback(() => {
    dispatch(deleteAllNotifications());
  }, [dispatch]);

  return (
    <Container>
      <DeleteButton onClick={onDelete}>Clear all</DeleteButton>
      {notifications.map((item, index, arr) => (
        <NotificationsItem key={item.id} notification={item} isLast={arr.length - 1 === index} />
      ))}
    </Container>
  );
};

export default NotificationsList;
