import styled from 'styled-components';
import SvgSprite from '../SvgSprite';

export const Wrapper = styled.div`
  margin-bottom: 50px;
`;

export const MessagesContainer = styled.div`
  padding: 10px;
  scroll-behavior: smooth;
`;

export const TextElements = styled.div`
  text-align: center;
  font-weight: 700;
  font-size: 16px;
  margin: 20px 0;
`;

export const LoaderWrapper = styled.div`
  margin: 30px 0;
  display: flex;
  justify-content: center;
`;

export const SendForm = styled.form``;

export const MessageBottom = styled.div``;

export const LabelBlock = styled.label`
  cursor: pointer;
`;

export const FilesWrapper = styled.div`
  margin: 0 8px;
`;

export const FileLabel = styled.label`
  display: block;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 5px;
`;

export const FileLoadingText = styled.div`
  font-weight: 700;
  font-size: 14px;
  margin: 0 8px;
`;

export const FileList = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const FileItem = styled.div`
  width: 70px;
  height: 70px;
  background-color: white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  position: relative;
  margin-bottom: 40px;
`;

export const FileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 10px 5px;
`;

export const FileContent = styled.div`
  flex-grow: 2;
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
`;

export const FileIcon = styled(SvgSprite)`
  width: 25px;
  height: 25px;
  fill: black;
`;

export const FileName = styled.span`
  display: block;
  text-align: left;
  font-size: 12px;
  font-weight: 700;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const FileRemove = styled.button`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: none;
  background-color: #fd6c61;
  position: absolute;
  right: -7px;
  top: -7px;
  color: white;
  font-size: 12px;
  font-weight: 700;
  padding: 0;
  cursor: pointer;
  
  &:hover,
  &:active {
    outline: none;
  }
`;

export const RemoveIcon = styled(SvgSprite)`
  fill: white;
  width: 9px;
  height: 9px;
`;
export const ErrorMessageBlock = styled.div`
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.1px;
  font-weight: 500;
  width: 100%;
  height: auto;
  padding-top: 5px;
  padding-left: 10px;
  padding-bottom: 5px;
  color: red;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
