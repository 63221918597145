import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { AddUserButton, ButtonText } from './styles';
import { UserManagementItem } from './UserManagementItem';
import { useHistory } from 'react-router-dom';

const UserManegmentList = () => {
  const { users } = useSelector((state: RootState) => state.users);

  const history = useHistory();

  return (
    <>
      <AddUserButton onClick={() => history.push('/users/add')}>
        <ButtonText>Add User</ButtonText>
      </AddUserButton>

      <ul>
        {Object.keys(users).map(userId => (
          <UserManagementItem key={userId} user={users[userId]} />
        ))}
      </ul>
    </>
  );
};

export default UserManegmentList;
