import firebase from 'firebase/app';

const namespace = 'NOTIFICATIONS';

export const SET_ALL_NOTIFICATIONS = `${namespace}/SET_ALL_NOTIFICATIONS`;
export const ADD_NOTIFICATION = `${namespace}/ADD_NOTIFICATION`;
export const REMOVE_NOTIFICATION = `${namespace}/REMOVE_NOTIFICATION`;
export const REMOVE_ALL_NOTIFICATIONS = `${namespace}/REMOVE_ALL_NOTIFICATIONS`;

export type NotificationAction =
  | 'workerUpdate'
  | 'statusUpdate'
  | 'projectUpdate'
  | 'commented'
  | 'commentUpdate'
  | 'commentDelete';

export interface Notification {
  action: NotificationAction;
  isRead: boolean;
  message: string;
  taskId: string;
  triggeredBy: string;
  userId: string;
  projectId: string;
}

export type NotificationAPI = Notification & { date: firebase.firestore.Timestamp };

export type NotificationStore = Notification & { id: string; date: firebase.firestore.Timestamp };

export type Notifications = { [key: string]: NotificationStore };

export interface NotificationsState {
  notifications: Notifications;
}
