import styled from 'styled-components';

export const AddButton = styled.button`
  padding: 0 20px;
  width: fit-content;
  height: 41px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #004aff;
  border-radius: 10px;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
  border: none;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  margin-bottom: 20px;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90.56deg, #004aff -0.92%, #24c9fc 100%);
    opacity: 0;
    transition: all 0.3s;
  }
  &:hover {
    &:before {
      opacity: 1;
    }
  }
`;

export const ButtonText = styled.span`
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  position: relative;
  z-index: 2;
`;
