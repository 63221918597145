import { db } from 'utils/firebase';
import {
  SET_ALL_TASKS,
  Tasks,
  ADD_TASK,
  TaskUpdate,
  TaskStore,
  SET_EDIT_ERROR,
  Task,
  TaskCreate,
  RecurringTasks,
  SET_ALL_RECURRING_TASKS,
  RecurringTaskStore,
  ADD_RECURRING_TASK,
  REMOVE_RECURRING_TASK,
  RecurringTaskCreate,
  SET_CREATED_TASK_NUMBER,
} from './tasksTypes';
import store, { AppThunk } from '../index';
import API from 'utils/api';
import { addFiles } from './filesHelpers';
import { fromStoreTaskToAPITask } from './tasksHelpers';
import getIdToken from 'utils/getIdToken';
import { logout } from 'store/users/usersActions';
import { Dispatch } from 'redux';

const tasksRef = db.collection('tasks');
const recurringTasksRef = db.collection('recurringTasks');

export const setAllTasks = (payload: Tasks): { payload: Tasks; type: string } => ({
  type: SET_ALL_TASKS,
  payload,
});

export const addTask = (payload: TaskStore): { payload: TaskStore; type: string } => ({
  type: ADD_TASK,
  payload,
});

export const setEditError = (payload: boolean): { payload: boolean; type: string } => ({
  type: SET_EDIT_ERROR,
  payload,
});

export const setAllRecurringTasks = (payload: RecurringTasks): { payload: RecurringTasks; type: string } => ({
  type: SET_ALL_RECURRING_TASKS,
  payload,
});

export const addRecurringTask = (payload: RecurringTaskStore): { payload: RecurringTaskStore; type: string } => ({
  type: ADD_RECURRING_TASK,
  payload,
});

export const removeRecurringTask = (payload: string): { payload: string; type: string } => ({
  type: REMOVE_RECURRING_TASK,
  payload,
});

export const setCreatedTaskNumber = (payload: number | null): { payload: number | null; type: string } => ({
  type: SET_CREATED_TASK_NUMBER,
  payload,
});

const tasksChangeHandler = (dispatch: Dispatch, projects?: string[] | null) => {
  if (!projects) {
    tasksRef.onSnapshot(querySnapshot => {
      querySnapshot.docChanges().forEach(change => {
        if (
          (change.type === 'added' && !Object.keys(store.getState().tasks.tasks).includes(change.doc.id)) ||
          change.type === 'modified'
        ) {
          const data = {
            ...change.doc.data(),
            id: change.doc.id,
          } as TaskStore;

          dispatch(addTask(data));
        }
      });
    });
  } else {
    tasksRef.where('idProject', 'in', projects).onSnapshot(querySnapshot => {
      querySnapshot.docChanges().forEach(change => {
        if (
          (change.type === 'added' && !Object.keys(store.getState().tasks.tasks).includes(change.doc.id)) ||
          change.type === 'modified'
        ) {
          const data = {
            ...change.doc.data(),
            id: change.doc.id,
          } as TaskStore;

          dispatch(addTask(data));
        }
      });
    });
  }
};

export const getAllTasks = (projects?: string[] | null): AppThunk => async dispatch => {
  try {
    let tasks;
    if (!projects) {
      tasks = await tasksRef.get();
    } else {
      tasks = await tasksRef.where('idProject', 'in', projects).get();
    }
    const tasksData = tasks.docs.reduce(
      (acc, item) => ({ ...acc, [item.id]: { ...item.data(), id: item.id } }),
      {},
    ) as Tasks;
    dispatch(setAllTasks(tasksData));
    tasksChangeHandler(dispatch, projects);
  } catch (error) {
    console.log(error);
  }
};

export const createTask = (data: TaskCreate): AppThunk => async dispatch => {
  try {
    const token = await getIdToken();
    if (!token) {
      dispatch(logout());
      throw Error('Auth failed');
    }

    const newTask: TaskUpdate = {
      name: data.name,
      description: data.description,
      priority: data.priority,
      idProject: data.idProject,
      dateCreated: new Date(),
      dateCompleted: null,
      dateEnd: new Date(data.dateEnd),
      dateProcessed: null,
      files: [],
      estimate: data.estimate || 0,
      totalTime: 0,
      isArchive: false,
      status: data.status,
      timeDoctorId: null,
      userId: data.userId,
      taskNumber: null,
      subscribedUsers: data.subscribedUsers,
    };
    const res = await API.post('/task', newTask);
    const { taskId } = res.data;

    const task = await tasksRef.doc(taskId).get();
    const taskData = task.data() as Task;

    const files = addFiles(data.files, data.projectName, taskId);

    const { task: updatedTask } = fromStoreTaskToAPITask({ ...taskData, id: taskId });
    dispatch(updateTask({ ...updatedTask, files }, taskId));
    return updatedTask.taskNumber;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const updateTask = (data: TaskUpdate, id: string): AppThunk => async dispatch => {
  try {
    const token = await getIdToken();
    if (!token) {
      dispatch(logout());
      throw Error('Auth failed');
    }
    const res = await API.put(`/task/${id}`, data);

    const { taskId } = res.data;
    const task = await tasksRef.doc(taskId).get();
    const taskData = task.data() as Task;

    dispatch(setEditError(false));
    dispatch(addTask({ ...taskData, id: taskId }));
  } catch (error) {
    dispatch(setEditError(true));
  }
};

export const getAllRecurringTasks = (): AppThunk => async dispatch => {
  try {
    const tasks = await recurringTasksRef.get();
    const tasksData = tasks.docs.reduce(
      (acc, item) => ({ ...acc, [item.id]: { ...item.data(), id: item.id } }),
      {},
    ) as RecurringTasks;

    dispatch(setAllRecurringTasks(tasksData));
  } catch (error) {
    console.log(error);
  }
};

export const createRecurringTask = (data: RecurringTaskCreate): AppThunk => async dispatch => {
  try {
    const token = await getIdToken();
    if (!token) {
      dispatch(logout());
      throw Error('Auth failed');
    }

    const res = await API.post('/recurring-task', data);
    const { taskId } = res.data;

    const task = await recurringTasksRef.doc(taskId).get();
    const taskData = task.data() as RecurringTaskStore;

    dispatch(addRecurringTask({ ...taskData, id: taskId }));
  } catch (error) {
    console.log(error);
  }
};

export const updateRecurringTask = (data: RecurringTaskCreate, id: string): AppThunk => async dispatch => {
  try {
    const token = await getIdToken();
    if (!token) {
      dispatch(logout());
      throw Error('Auth failed');
    }

    const res = await API.put(`/recurring-task/${id}`, data);
    const { taskId } = res.data;

    const task = await recurringTasksRef.doc(taskId).get();
    const taskData = task.data() as RecurringTaskStore;

    dispatch(addRecurringTask({ ...taskData, id: taskId }));
  } catch (error) {
    console.log(error);
  }
};

export const deleteRecurringTask = (id: string): AppThunk => async dispatch => {
  try {
    const token = await getIdToken();
    if (!token) {
      dispatch(logout());
      throw Error('Auth failed');
    }

    const res = await API.delete(`/recurring-task/${id}`);
    const { taskId } = res.data;

    dispatch(removeRecurringTask(taskId));
  } catch (error) {
    console.log(error);
  }
};
