import styled from 'styled-components';

export const TimeItemCont = styled.div<{ buttons?: boolean }>`
  width: ${props => (props.buttons ? '85px' : 'calc((100% - 85px) / 5)')};
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;

  pre {
    overflow: hidden;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
  }
`;

export const TimeItem = styled.li`
  display: flex;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const EditButton = styled.button`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #004aff;
  background: none;
  border: none;
  cursor: pointer;
`;

export const DeleteButton = styled.button`
  background: none;
  border: none;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #ff0000;
  cursor: pointer;
`;
