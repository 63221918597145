import React, { useCallback, useState } from 'react';
import { InputWrapper, InputHeader, FileInput, FileInputLabel, FilesList, CloseBtn, ErrorMessageBlock } from './styles';

import { useDropzone } from 'react-dropzone';
import SvgSprite from 'components/SvgSprite';

// utils
import { checkTypesFilesUpload } from 'utils/checkTypesFilesUpload';
import { TYPES_FOR_INPUT_TEXT } from 'utils/typesForCheck';

type UploadInputProps = {
  isEdit: boolean;
  name?: string;
  onChange: (file: File[]) => void;
  filesList: File[];
  removeFileList: (data: File[]) => void;
};

const UploadInput: React.FC<UploadInputProps> = ({ name, onChange, filesList, removeFileList, isEdit }) => {
  // state for CheckDropZone

  const [badTypesFiles, setBadTypesFiles] = useState<[] | null>(null);

  const onDrop = useCallback(
    (acceptedFiles: any) => {
      const prepearedFiles = checkTypesFilesUpload(acceptedFiles, TYPES_FOR_INPUT_TEXT);
      let fileToUpload: any[] = [];
      if (prepearedFiles.available.length) {
        fileToUpload = prepearedFiles.available;
        onChange(fileToUpload);
      }
      if (!prepearedFiles.notAvailable.length) {
        setBadTypesFiles(null);
      } else {
        setBadTypesFiles(prepearedFiles.notAvailable);
      }
    },
    [onChange],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  const handleDeleteFile = (index: any) => {
    const files = filesList.filter((item: any, idx: number) => idx !== index);
    removeFileList([...files]);
  };

  return (
    <InputWrapper>
      {!isEdit ? (
        <InputHeader>
          <SvgSprite className="Icon" name="file" width={24} height={24} fill="#004AFF" />
          Upload Files
        </InputHeader>
      ) : null}
      <FileInputLabel {...getRootProps()} isDragActive={isDragActive}>
        <SvgSprite name="download 1" width={24} height={24} fill={isDragActive ? '#004AFF' : 'grey'} />
        Select or Drop your files here
      </FileInputLabel>
      <FileInput {...getInputProps()} />
      <FilesList>
        {badTypesFiles && (
          <ErrorMessageBlock>
            This files can not be uploaded {badTypesFiles.join(', ')}. Invalid file type!!!
          </ErrorMessageBlock>
        )}
        {filesList.map((file: File, index: any) => (
          <span key={file.name}>
            {file.name}
            <CloseBtn onClick={() => handleDeleteFile(index)} />
          </span>
        ))}
      </FilesList>
    </InputWrapper>
  );
};

UploadInput.defaultProps = {
  name: undefined,
};

export default UploadInput;
