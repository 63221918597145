import React, { useEffect } from 'react';
import DashboardLayout from 'layouts/Dashboard';
import GetTimelogsForm from 'components/TimelogsDashboard/GetTimelogsForm';
import { useDispatch } from 'react-redux';
import { resetTimelogs } from 'store/timelogs/timelogsActions';
import TimelogsDashboardList from 'components/TimelogsDashboard/TimelogsDashboardList';

const TimelogsDashboard = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetTimelogs());
  }, [dispatch]);

  return (
    <DashboardLayout>
      <GetTimelogsForm />
      <TimelogsDashboardList />
    </DashboardLayout>
  );
};

export default TimelogsDashboard;
