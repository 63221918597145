import React from 'react';
import logo from 'assets/images/logo.svg';
import LogOutPanel from 'components/DashboardLayout/LogOutPanel';
import Container from 'components/DashboardLayout/Container';
import ListNavigation from 'components/DashboardLayout/ListNavigation';

import {
  Wrapper,
  WrapperContainer,
  TopContainer,
  LogoWrapper,
  ContentWrapper,
  Logo,
  LogoName,
  RightContent,
  Content,
} from './styles';
import ListNavigationSecondLevel from 'components/DashboardLayout/ListNavigationSecondLevel';

interface DefaultLayotProps {
  children?: React.ReactNode;
}

const DefaultLayout: React.FC<DefaultLayotProps> = ({ children }) => (
  <Wrapper>
    <WrapperContainer>
      <TopContainer>
        <LogoWrapper>
          <Logo src={logo} />
          <LogoName>FB Task Manager</LogoName>
        </LogoWrapper>
        <LogOutPanel />
      </TopContainer>
      <ContentWrapper>
        <RightContent>
          <Content>
            <ListNavigation />
            <ListNavigationSecondLevel />
            <Container>{children}</Container>
          </Content>
        </RightContent>
      </ContentWrapper>
    </WrapperContainer>
  </Wrapper>
);

DefaultLayout.defaultProps = {
  children: undefined,
};

export default React.memo(DefaultLayout);
