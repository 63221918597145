import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

interface TaskPopupProps {
  open: boolean
  onClose: any
  saveError: boolean
}

const InfoTaskPopup: React.FC<TaskPopupProps> = ({
  open,
  onClose,
  saveError,
}) => (
  <Snackbar
    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    open={open}
    autoHideDuration={5000}
    onClose={onClose}
  >
    {saveError ? (
      <MuiAlert variant="filled" severity="error">
        Task was not updated
      </MuiAlert>
    )
      : (
        <MuiAlert variant="filled" severity="success">
          Task updated successfully
        </MuiAlert>
      )}
  </Snackbar>
);

export default InfoTaskPopup;
