import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import styled from 'styled-components';
// Icons
import { ReactComponent as Doc } from 'assets/images/icons/post_add.svg';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      marginBottom: 0,
    },
    '& .MuiInputBase-root': {
      position: 'relative',
      background: 'rgba(255, 255, 255, 0.8)',
      paddingLeft: '40px',
    },
    '& .MuiFormLabel-root': {
      paddingLeft: '44px',
      fontSize: '16px',
      letterSpacing: '0.15px',
      color: '#004AFF',
    },
    '& .MuiFormLabel-root.Mui-error': {
      color: '#f44336',
    },
    '& .MuiFilledInput-input': {
      color: '#001E66',
    },
    '& .css-yk16xz-control': {
      background: 'rgba(255, 255, 255, 0.8)',
      border: 0,
      width: '100%',
    },
  },
}));

export const FormWrapper = styled.form`
  max-width: 510px;
`;

export const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const DocIcon = styled(Doc)`
  top: 20px;
  left: 20px;
  position: absolute;
  width: 28px;
  height: 28px;
  fill: ${(props) => props.fill};
`;

export const SpanText = styled.span`
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
  position: relative;
  z-index: 2;
  
  svg {
    fill: white;
  }
`;

export const SendButton = styled.button`
  margin: 8px;
  width: 100%;
  height: 51px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #004AFF;
  border-radius: 10px;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #FFFFFF;
  border: none;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90.56deg, #004AFF -0.92%, #24C9FC 100%);
    opacity: 0;
    transition: all .3s;
  }
  
  &:hover {
    &:before {
      opacity: 1;
    } 
  }
  
  .MuiCircularProgress-colorPrimary {
    fill: white;
  }
`;
