import { LOGOUT } from '../users/usersTypes';
import {
  ChatTaskState,
  ChatTaskActions,
  SET_LOADING_CHAT,
  SET_CHAT_DATA,
  SET_LOADING_SEND_MESSAGE,
  SET_FILES_INFO,
  SET_LOADING_DOCS,
  SET_BAD_TYPES_FILES,
} from './chatTaskTypes';

const initialState: ChatTaskState = {
  loadingChat: false,
  loadingSendMessage: false,
  loadingDocs: false,
  chatData: [],
  filesList: [],
  badTypesFiles: [],
};

const chatTaskReducer = (state = initialState, action: ChatTaskActions): ChatTaskState => {
  switch (action.type) {
    case SET_CHAT_DATA:
      return {
        ...state,
        chatData: action.payload,
      };
    case SET_LOADING_CHAT:
      return {
        ...state,
        loadingChat: action.payload,
      };
    case SET_LOADING_SEND_MESSAGE:
      return {
        ...state,
        loadingSendMessage: action.payload,
      };
    case SET_BAD_TYPES_FILES:
      return {
        ...state,
        badTypesFiles: action.payload,
      };
    case SET_FILES_INFO:
      return {
        ...state,
        filesList: action.payload,
      };
    case SET_LOADING_DOCS:
      return {
        ...state,
        loadingDocs: action.payload,
      };

    case LOGOUT:
      return initialState;

    default:
      return { ...state };
  }
};

export default chatTaskReducer;
