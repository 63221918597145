import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RecurringTaskStore } from 'store/tasks/tasksTypes';
import { Container, DeleteButton, EditButton, InfoContainer, SideContainer } from './styles';
import { getProjectById } from 'store/projects/projectsSelectors';
import { getUserById } from 'store/users/usersSelectors';
import ArchiveConfirmModal from 'components/ArchiveConfirmModal';
import { deleteRecurringTask } from 'store/tasks/tasksActions';
import { useHistory } from 'react-router-dom';

interface RecurringTasksItemProps {
  task: RecurringTaskStore;
}

const RecurringTasksItem = ({ task }: RecurringTasksItemProps) => {
  const history = useHistory();

  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

  const dispatch = useDispatch();
  const project = useSelector(getProjectById(task.project));
  const worker = useSelector(getUserById(task.worker));

  const handleDelete = useCallback(() => {
    dispatch(deleteRecurringTask(task.id));
    setDeleteModalOpen(false);
  }, [task, dispatch]);

  return (
    <>
      <Container>
        <SideContainer>
          <InfoContainer>{task.name}</InfoContainer>
          <InfoContainer>{project?.name || ''}</InfoContainer>
          <InfoContainer>{worker?.name || ''}</InfoContainer>
        </SideContainer>

        <SideContainer>
          <EditButton onClick={() => history.push(`/recurring-tasks/${task.id}`)}>Edit</EditButton>
          <DeleteButton onClick={() => setDeleteModalOpen(true)}>Delete</DeleteButton>
        </SideContainer>
      </Container>

      <ArchiveConfirmModal
        open={deleteModalOpen}
        setOpen={setDeleteModalOpen}
        text="Are you sure ti delete this recurring task?"
        handleConfirm={handleDelete}
      />
    </>
  );
};

export default RecurringTasksItem;
