import React from 'react';
import { useSelector } from 'react-redux';
import TimelogsDashboardItem from './TimelogsDashboardItem';
import { List } from './styles';
import { getGroupedTimelogs } from 'store/timelogs/timelogsSelectors';

const TimelogsDashboardList = () => {
  const timelogs = useSelector(getGroupedTimelogs);

  return (
    <List>
      {timelogs.map(item => (
        <TimelogsDashboardItem item={item} key={item.taskId} />
      ))}
    </List>
  );
};

export default TimelogsDashboardList;
