import styled from 'styled-components';
import Badge from '@material-ui/core/Badge';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.14);
`;

export const CustomBadge = styled(Badge)`
  & .MuiBadge-anchorOriginTopRightRectangle {
    top: 11px;
    right: 5px;
  }
`;
