import { RULES_OPTIONS } from 'utils/constants';

const namespace = 'USERS';
export const SET_ALL_USERS = `${namespace}/SET_ALL_USERS`;
export const LOGIN = `${namespace}/LOGIN`;
export const LOGOUT = `${namespace}/LOGOUT`;
export const SET_ERROR = `${namespace}/SET_ERROR`;
export const SET_LOADING = `${namespace}/SET_LOADING`;

export type Rule = keyof typeof RULES_OPTIONS;

export interface User {
  email: string;
  isDelete: boolean;
  id: string;
  name: string;
  projects: string[];
  rule: Rule;
  isWorker: boolean;
  idCompany: string | null;
  idTimeDoctor: number | null;
  isMailSubscribed: boolean;
}

export interface UserData {
  email: string;
  name: string;
  rule: Rule;
  projects: string[];
  worker: boolean;
  isMailSubscribed: boolean;
}

export interface UsersState {
  [key: string]: User;
}

export interface CurrentUserState {
  id: string | null;
  isLoggedIn: boolean;
  error: string | null;
  isLoading: boolean;
}
