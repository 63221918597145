import styled from 'styled-components';
import Input from '@material-ui/core/Input';

export const Wrapper = styled.form`
  padding-right: 15px;
  max-width: 100vw;
`;

export const NameBlock = styled.div`
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 20px;
`;

export const ButtonEdit = styled.button`
  width: 40px;
  height: 40px;
  border: 1px solid #3f51b5;
  outline: none;
  border-radius: 50%;
  background: transparent;
  cursor: pointer;
  margin: 10px 0;
  svg {
    color: #3f51b5;
  }
`;

export const StyledInputName = styled(Input)`
  margin-left: 15px;
  width: 100%;

  &.MuiInputBase-root {
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
  }

  &.MuiInputBase-root.Mui-disabled {
    color: initial;
    border: none;
  }

  &.MuiInput-underline.Mui-disabled:before {
    display: none;
  }
`;

export const StyledInputDescr = styled(Input)`
  width: 100%;

  &.MuiInputBase-root {
    line-height: 18px;
    font-size: 12px;
  }

  &.MuiInputBase-root.Mui-disabled {
    color: initial;
    border: none;
  }

  &.MuiInput-underline.Mui-disabled:before {
    display: none;
  }
`;

export const BlockItem = styled.div`
  margin-bottom: 25px;
`;

export const Label = styled.div`
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 10px;
`;

export const DescriptionBlock = styled.div`
  line-height: 18px;
  font-size: 12px;
  max-width: 50%;
  overflow: hidden;
  white-space: pre-wrap; /* CSS3 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */

  pre {
    white-space: pre-wrap; /* CSS3 */
    white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
    white-space: -pre-wrap; /* Opera 4-6 */
    white-space: -o-pre-wrap; /* Opera 7 */
    word-wrap: break-word; /* Internet Explorer 5.5+ */
  }

  a {
    white-space: pre-wrap; /* CSS3 */
    white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
    white-space: -pre-wrap; /* Opera 4-6 */
    white-space: -o-pre-wrap; /* Opera 7 */
    word-wrap: break-word; /* Internet Explorer 5.5+ */
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
`;

export const BackButton = styled.button`
  border: none;
  outline: none;
  background: transparent;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  text-align: center;
  color: ${props => (props.disabled ? 'grey' : 'red')};
`;

export const EditButton = styled.button`
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;

  svg {
    color: #3f51b5;
    width: 18px;
    height: 18px;
  }
`;

export const StyledTaskNumber = styled.div`
  font-weight: 900;
  padding-left: 10px;
`;
