export const PRIORITY_OPTIONS = {
  low: { value: 'low', label: 'Low' },
  medium: { value: 'medium', label: 'Medium' },
  high: { value: 'high', label: 'High' },
};

export const STATUSES_OPTIONS = {
  created: { value: 'created', label: 'Created' },
  backlog: { value: 'backlog', label: 'Backlog' },
  in_work: { value: 'in_work', label: 'In work' },
  testing: { value: 'testing', label: 'Testing' },
  completed: { value: 'completed', label: 'Completed' },
  canceled: { value: 'canceled', label: 'Canceled' },
};

export const TASK_SORT_OPTIONS = {
  name: { label: 'Task Name', value: 'name' },
  dateCreated: { label: 'Start Date', value: 'dateCreated' },
  dateEnd: { label: 'End Date', value: 'dateEnd' },
  status: { label: 'Status', value: 'status' },
};

export const RULES_OPTIONS = {
  admin: { value: 'admin', label: 'Administrator' },
  dev: { value: 'dev', label: 'Developer' },
  client: { value: 'client', label: 'User' },
};

export const PERIOD_SELECT = {
  week: { value: 'week', label: 'Every week' },
  month: { value: 'month', label: 'Every month' },
};

export const DAYS_OF_WEEK_OPTIONS = [
  { value: 1, label: 'Monday' },
  { value: 2, label: 'Tuesday' },
  { value: 3, label: 'Wednesday' },
  { value: 4, label: 'Thursday' },
  { value: 5, label: 'Friday' },
  { value: 6, label: 'Saturday' },
  { value: 7, label: 'Sunday' },
];

export const MONTH_OPTIONS = {
  1: { value: 1, label: 'First day' },
  31: { value: 31, label: 'Last day' },
  custom: { value: 'custom', label: 'Enter a day' },
};
