import styled from 'styled-components';

export const TopWrapper = styled.div`
  display: flex;
  margin-bottom: 11px;
`;

export const SideWrapper = styled.div<{ left?: boolean }>`
  margin-right: ${pops => (pops.left ? '20px' : 0)};
  display: flex;
  flex-direction: column;
`;

export const Highlighted = styled.span<{ action: string }>`
  color: ${props => {
    if (props.action === 'commented') {
      return '#61FF00';
    }
    if (props.action === 'commentUpdate') {
      return '#004AFF';
    }
    if (props.action === 'commentDelete') {
      return '#FF004D';
    }
    return '#000000';
  }};
`;
