import { RootState } from '../index';
import { SortOptions, TaskStore } from './tasksTypes';
import { getTasksByStatuses } from './tasksHelpers';

export const getAllTasks = (state: RootState) => state.tasks.tasks;

export const getSortedByTimeTasks = (state: RootState) => {
  const allTasks = getAllTasks(state);

  return Object.values(allTasks).sort((a, b) => b.dateCreated.toMillis() - a.dateCreated.toMillis());
};

export const getSortedByPriorityTasks = (state: RootState) => {
  const allTasks = getAllTasks(state);

  return Object.values(allTasks).sort((a, b) => {
    if (a.priority === 'high') {
      return -1;
    }
    if (b.priority === 'high') {
      return 1;
    }
    if (a.priority === 'medium') {
      return -1;
    }
    if (b.priority === 'medium') {
      return 1;
    }
    return 0;
  });
};

export const getFilteredTasks = ({
  searchQuery,
  worker,
  project,
}: {
  searchQuery: string;
  worker: string | null;
  project: string | null;
}) => (state: RootState) => {
  if (!searchQuery && !worker && !project) return [];

  const allTasks = getSortedByPriorityTasks(state);

  return allTasks.filter(
    task =>
      task.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
      (!worker || task.userId === worker) &&
      (!project || task.idProject === project) &&
      !task.isArchive,
  );
};

export const getEditError = (state: RootState) => state.tasks.editError;

export const getTasksByNumbers = (state: RootState): { [taskNumber: number]: TaskStore } => {
  const allTasks = getAllTasks(state);

  return Object.values(allTasks).reduce((acc, task) => ({ ...acc, [task.taskNumber || 0]: task }), {});
};

export const getCurrentTaskByNumber = (number: number) => (state: RootState) => {
  const tasksByNumbers = getTasksByNumbers(state);

  return tasksByNumbers[number] || null;
};

export const getCurrentTaskById = (id: string) => (state: RootState) => {
  const allTasks = getAllTasks(state);

  return allTasks[id] || null;
};

export const getRequestProcessedTasks = (state: RootState) => {
  const allTasks = getSortedByTimeTasks(state);

  return allTasks.filter(task => task.status === 'created' && !task.isArchive);
};

export const getSortedTasks = (sort: SortOptions | null, projectId: string) => (state: RootState) => {
  const allTasks = getFilteredTasks({ searchQuery: '', worker: null, project: projectId })(state);

  if (!sort) return allTasks;

  if (sort === 'dateEnd' || sort === 'dateCreated') {
    return allTasks.sort((a, b) => (b[sort]?.toMillis() || 0) - (a[sort]?.toMillis() || 0));
  }

  if (sort === 'name') {
    return allTasks.sort((a, b) => a.name.localeCompare(b.name));
  }
  return allTasks.sort((a, b) => a.status.length - b.status.length);
};

export const getFilteredTasksByStatuses = (filterData: {
  searchQuery: string;
  worker: string | null;
  project: string | null;
}) => (state: RootState) => {
  const filteredTasks = getFilteredTasks(filterData)(state);

  return getTasksByStatuses(filteredTasks);
};

export const getRecurringTasks = (state: RootState) => state.tasks.recurringTasks;

export const getRecurringTaskById = (id: string) => (state: RootState) => {
  const allTasks = getRecurringTasks(state);

  return allTasks[id] || null;
};

export const getTaskById = (id: string) => (state: RootState) => {
  const allTasks = getAllTasks(state);

  return allTasks[id] || null;
};

export const getCreatedTaskNumber = (state: RootState) => state.tasks.createdTaskNumber;
