import React from 'react';
import { Wrapper } from './styles';

interface AuthProps {
  children?: React.ReactNode;
}

const Auth: React.FC<AuthProps> = ({ children }) => <Wrapper>{children}</Wrapper>;

Auth.defaultProps = {
  children: undefined,
};

export default Auth;
