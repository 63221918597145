import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Container } from './styles';

const LoaderPage = () => (
  <Container>
    <CircularProgress color="primary" size={60} />
  </Container>
);

export default LoaderPage;
