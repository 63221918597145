import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  min-height: 100%;
  overflow: hidden;

`;

export const WrapperContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;

`;

export const TopContainer = styled.div`
  width: 100%;
  background: rgba(0, 74, 255, 0.7);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.14);
  border-radius: 0px;
  padding: 4px 40px 4px 26px;
  display: flex;
  align-items: center;
`;

export const Sidebar = styled.div`
  width: 320px;
  min-width: 320px;
  height: calc(100vh - 35px);
  background: rgba(0, 74, 255, 0.3);
  overflow: auto;
  transition: all .3s;
`;

export const SettingsLink = styled.div`
  margin-top: 15px;
  
  a {
    color: white;
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 2;
`;

export const Logo = styled.img`
  width: 31px;
  height: 28px;
  object-fit: cover;
  margin-right: 10px;
`;

export const LogoName = styled.span`
  font-size: 20px;
  line-height: 23px;
  color: white;
`;

export const RightContent = styled.div`
  background-color: #DFEEFF;
  flex-grow: 1;
  
`;

export const Content = styled.div`
  width: 100%;
  height: 100vh;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-grow: 2;
  display: flex;
  justify-content: space-around;
`;
