/* eslint-disable consistent-return */
export const checkTypesFilesUpload = (
  fileValues: any, mimeTypes: string [],
) => {
  let filteredByTypes: any = [];
  const typesNotAvailable: any = [];

  filteredByTypes = fileValues.filter((el: any) => {
    if ((mimeTypes.some((ch) => ch === el.type)) && (el.size <= 50000000)) {
      return el;
    }
    typesNotAvailable.push(el.name);
    return null;
  });

  return {
    available: filteredByTypes,
    notAvailable: typesNotAvailable,
  };
};
