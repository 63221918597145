import { LOGOUT } from '../users/usersTypes';
import { Action } from 'utils/commonTypes';
import {
  ADD_NOTIFICATION,
  NotificationsState,
  REMOVE_ALL_NOTIFICATIONS,
  REMOVE_NOTIFICATION,
  SET_ALL_NOTIFICATIONS,
} from './notificationsType';

const initialState = {
  notifications: {},
};

const notificationsReducer = (state: NotificationsState = initialState, action: Action): NotificationsState => {
  switch (action.type) {
    case SET_ALL_NOTIFICATIONS:
      return { ...state, notifications: { ...action.payload } };

    case ADD_NOTIFICATION:
      return { ...state, notifications: { ...state.notifications, [action.payload.id]: action.payload } };

    case REMOVE_NOTIFICATION: {
      const newNotifications = { ...state.notifications };
      delete newNotifications[action.payload];

      return { ...state, notifications: { ...newNotifications } };
    }

    case REMOVE_ALL_NOTIFICATIONS:
      return { ...state, notifications: {} };

    case LOGOUT:
      return initialState;

    default:
      return state;
  }
};

export default notificationsReducer;
