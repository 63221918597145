import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { TextField } from '@material-ui/core';
import {
  FormContainer,
  Title,
  FormWrapper,
  useStyles,
  InputWrapper,
  ErrorMessageBlock,
  SendButton,
  SpanText,
  Container,
  TopWrapper,
  Wrapper,
  SelectStyles,
} from './styles';
import SvgSprite from 'components/SvgSprite';
import { Controller, useForm } from 'react-hook-form';
import CustomSelect from 'components/CustomSelect';
import { useSelector } from 'react-redux';
import { getProjects } from 'store/projects/projectsSelectors';
import { getWorkers } from 'store/users/usersSelectors';
import { OptionGroup, Option } from 'utils/commonTypes';
import { STATUSES_OPTIONS, PERIOD_SELECT, DAYS_OF_WEEK_OPTIONS, MONTH_OPTIONS } from 'utils/constants';
import { getHoursAndMinutesFromTime, validateTime } from 'components/InfoTaskHeader/TimelogModal/helpers';
import { PeriodOptions, RecurringTaskCreate, RecurringTaskStore, Status } from 'store/tasks/tasksTypes';
import { BarChartOutlined, GroupOutlined, WatchLaterOutlined } from '@material-ui/icons';
import Select from 'react-select';
import { useHistory } from 'react-router-dom';

interface TimelogModalProps {
  onSubmit: (data: RecurringTaskCreate) => void;
  task?: RecurringTaskStore;
}

const getPeriodOption = (task?: RecurringTaskStore) => {
  if (!task) return DAYS_OF_WEEK_OPTIONS[0];
  if (task.periodSelect === 'week') {
    if (task.periodNumber) return DAYS_OF_WEEK_OPTIONS[task.periodNumber - 1];
    return DAYS_OF_WEEK_OPTIONS[0];
  }
  if (task.periodNumber === 1 || task.periodNumber === 31) return MONTH_OPTIONS[task.periodNumber];
  return MONTH_OPTIONS.custom;
};

const getPeriodNumber = (
  periodSelect: Option,
  periodOption: { label: string; value: number | string },
  periodNumber: { value: string; error: boolean },
): number => {
  if (periodSelect.value === 'week') {
    return Number(periodOption.value);
  }
  if (periodOption.value === 'custom') {
    return Number(periodNumber.value);
  }
  return Number(periodOption.value);
};

const RecurringTasksForm = ({ onSubmit, task }: TimelogModalProps) => {
  const classes = useStyles();

  const [timeError, setTimeError] = useState<string>('');
  const [periodSelect, setPeriodSelect] = useState<Option>(
    task?.periodSelect ? PERIOD_SELECT[task?.periodSelect] : PERIOD_SELECT.week,
  );
  const [periodOption, setPeriodOptions] = useState<{ label: string; value: number | string }>(getPeriodOption(task));
  const [periodNumber, setPeriodNumber] = useState<{ value: string; error: boolean }>({
    value: String(task?.periodNumber || 1),
    error: false,
  });

  const history = useHistory();

  const projects = useSelector(getProjects);
  const workers = useSelector(getWorkers);

  const projectsOptions: OptionGroup = useMemo(
    () =>
      Object.values(projects).reduce((acc, item) => ({ ...acc, [item.id]: { value: item.id, label: item.name } }), {}),
    [projects],
  );

  const workersOptions: OptionGroup = useMemo(
    () =>
      Object.values(workers).reduce((acc, item) => ({ ...acc, [item.id]: { value: item.id, label: item.name } }), {}),
    [workers],
  );

  const { register, errors, handleSubmit, control } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    defaultValues: {
      name: task?.name || '',
      description: task?.description || '',
      project: task?.project ? projectsOptions[task.project] : projectsOptions[Object.keys(projectsOptions)[0]],
      worker: task?.worker ? workersOptions[task.worker] : workersOptions[Object.keys(workersOptions)[0]],
      initialStatus: task?.status ? STATUSES_OPTIONS[task.status] : STATUSES_OPTIONS.backlog,
      estimate: getHoursAndMinutesFromTime(task?.estimate || 0, true),
      deadline: task?.deadline || 0,
      periodSelect: task?.periodSelect ? PERIOD_SELECT[task.periodSelect] : PERIOD_SELECT.week,
      subscribedUsers: task ? task.subscribedUsers.map(item => workersOptions[item]) : [],
    },
    shouldFocusError: true,
  });

  const onSubmitHelper = useCallback(
    (data: {
      name: string;
      description: string;
      project: Option;
      worker: Option;
      initialStatus: Option;
      estimate: string;
      deadline: string;
      periodSelect: Option;
      subscribedUsers: Option[];
    }) => {
      onSubmit({
        name: data.name,
        description: data.description,
        deadline: Number(data.deadline),
        worker: data.worker.value,
        project: data.project.value,
        status: data.initialStatus.value as Status,
        estimate: validateTime(data.estimate).time,
        periodSelect: data.periodSelect.value as PeriodOptions,
        periodNumber: getPeriodNumber(periodSelect, periodOption, periodNumber),
        subscribedUsers: data.subscribedUsers.map(item => item.value),
      });
      history.push('/recurring-tasks');
    },
    [onSubmit, periodNumber, periodOption, periodSelect, history],
  );

  return (
    <FormContainer>
      <Wrapper>
        <Title>{task ? 'Edit' : 'Create'} recurring task</Title>

        <FormWrapper
          className={classes.root}
          onSubmit={e => {
            e.stopPropagation();
            handleSubmit(onSubmitHelper)(e);
          }}
        >
          <TopWrapper>
            <Container>
              <InputWrapper fullWidth>
                <TextField
                  fullWidth
                  name="name"
                  label="Name"
                  variant="filled"
                  color="primary"
                  autoComplete="off"
                  inputRef={register({
                    validate: (value: string): boolean => value.length > 3,
                  })}
                  error={Boolean(errors.name)}
                />
                {errors.name ? <ErrorMessageBlock>Min 4 symbols</ErrorMessageBlock> : null}
                <SvgSprite className="inputIcon" width={28} height={28} fill="#004AFF" name="post_add" />
              </InputWrapper>
            </Container>

            <Container>
              <InputWrapper fullWidth>
                <TextField
                  fullWidth
                  multiline
                  name="description"
                  label="Description"
                  variant="filled"
                  color="primary"
                  autoComplete="off"
                  inputRef={register({
                    validate: (value): boolean => value.length >= 10,
                  })}
                  error={Boolean(errors.description)}
                />
                {errors.description ? <ErrorMessageBlock>Min 10 symbols</ErrorMessageBlock> : null}
                <SvgSprite className="inputIcon" width={28} height={28} fill="#004AFF" name="post_add" />
              </InputWrapper>
            </Container>

            <Container>
              <InputWrapper>
                <Controller
                  as={CustomSelect}
                  defaultValue={
                    task?.project ? projectsOptions[task.project] : projectsOptions[Object.keys(projectsOptions)[0]]
                  }
                  name="project"
                  label="Project"
                  placeholder="Projects"
                  rules={{ required: true }}
                  iconName="folders"
                  error={Boolean(errors.project)}
                  control={control}
                  backspaceRemovesValue
                  escapeClearsValue
                  isSearchable
                  height={16}
                  stroke={errors.project ? '#f44336' : '#004AFF'}
                  width={22}
                  options={Object.values(projectsOptions)}
                />
              </InputWrapper>

              <InputWrapper>
                <TextField
                  fullWidth
                  name="estimate"
                  label="Estimate"
                  variant="filled"
                  color="primary"
                  autoComplete="off"
                  inputRef={register({
                    validate: (value: string): boolean => {
                      const validationResult = validateTime(value);
                      if (validationResult.error) {
                        setTimeError(validationResult.error);
                        return false;
                      }
                      return true;
                    },
                  })}
                  error={Boolean(errors.estimate)}
                />
                {errors.estimate ? (
                  <ErrorMessageBlock>{timeError || 'Should be a valid time'}</ErrorMessageBlock>
                ) : null}
                <SvgSprite className="inputIcon" width={20} height={20} fill="#004AFF" name="calendar" />
              </InputWrapper>
            </Container>
            <Container>
              <InputWrapper>
                <Controller
                  as={CustomSelect}
                  defaultValue={
                    task?.worker ? workersOptions[task.worker] : workersOptions[Object.keys(workersOptions)[0]]
                  }
                  name="worker"
                  label="Worker"
                  placeholder="Worker"
                  rules={{ required: true }}
                  icon={GroupOutlined}
                  error={Boolean(errors.worker)}
                  control={control}
                  backspaceRemovesValue
                  escapeClearsValue
                  isSearchable
                  height={16}
                  stroke={errors.worker ? '#f44336' : '#004AFF'}
                  width={22}
                  options={Object.values(workersOptions)}
                />
              </InputWrapper>

              <InputWrapper>
                <TextField
                  fullWidth
                  name="deadline"
                  label="Deadline (in days)"
                  variant="filled"
                  color="primary"
                  autoComplete="off"
                  inputRef={register({
                    validate: (value: string): boolean => !isNaN(Number(value)),
                  })}
                  error={Boolean(errors.deadline)}
                />
                {errors.deadline ? <ErrorMessageBlock>Should be a valid number</ErrorMessageBlock> : null}
                <SvgSprite className="inputIcon" width={20} height={20} fill="#004AFF" name="calendar" />
              </InputWrapper>
            </Container>

            <Container>
              <InputWrapper>
                <Controller
                  as={CustomSelect}
                  defaultValue={task?.status ? STATUSES_OPTIONS[task.status] : STATUSES_OPTIONS.backlog}
                  name="initialStatus"
                  label="Initial status"
                  placeholder="Initial status"
                  rules={{ required: true }}
                  icon={BarChartOutlined}
                  error={Boolean(errors.initialStatus)}
                  control={control}
                  backspaceRemovesValue
                  escapeClearsValue
                  isSearchable
                  height={16}
                  stroke={errors.initialStatus ? '#f44336' : '#004AFF'}
                  width={22}
                  options={[{ ...STATUSES_OPTIONS.backlog }, { ...STATUSES_OPTIONS.created }]}
                />
              </InputWrapper>
            </Container>

            <Container>
              <InputWrapper>
                <Controller
                  render={({ onChange, onBlur, value, ref }) => (
                    <CustomSelect
                      onChange={(e: Option) => {
                        setPeriodOptions(e.value === 'week' ? DAYS_OF_WEEK_OPTIONS[0] : MONTH_OPTIONS[1]);
                        setPeriodSelect(e);
                        onChange(e);
                      }}
                      onBlur={onBlur}
                      selected={value}
                      ref={ref}
                      defaultValue={task?.periodSelect ? PERIOD_SELECT[task.periodSelect] : PERIOD_SELECT.week}
                      label="Period"
                      placeholder="Period"
                      rules={{ required: true }}
                      icon={WatchLaterOutlined}
                      error={Boolean(errors.initialStatus)}
                      backspaceRemovesValue
                      escapeClearsValue
                      isSearchable
                      height={16}
                      stroke={errors.periodSelect ? '#f44336' : '#004AFF'}
                      width={22}
                      options={Object.values(PERIOD_SELECT)}
                    />
                  )}
                  placeholder="Period"
                  label="Period"
                  control={control}
                  name="periodSelect"
                  string
                />
              </InputWrapper>
            </Container>

            <Container>
              {periodSelect.value === 'week' && (
                <InputWrapper>
                  <CustomSelect
                    selected={periodOption}
                    defaultValue={periodOption}
                    label="Period"
                    placeholder="Period"
                    rules={{ required: true }}
                    icon={WatchLaterOutlined}
                    backspaceRemovesValue
                    escapeClearsValue
                    isSearchable
                    height={16}
                    stroke="#004AFF"
                    width={22}
                    options={DAYS_OF_WEEK_OPTIONS}
                    onChange={(data: Option) => setPeriodOptions(data)}
                  />
                </InputWrapper>
              )}

              {periodSelect.value === 'month' && (
                <InputWrapper>
                  <CustomSelect
                    selected={periodOption}
                    defaultValue={periodOption}
                    label="Period"
                    placeholder="Period"
                    rules={{ required: true }}
                    icon={WatchLaterOutlined}
                    backspaceRemovesValue
                    escapeClearsValue
                    isSearchable
                    height={16}
                    stroke="#004AFF"
                    width={22}
                    options={Object.values(MONTH_OPTIONS)}
                    onChange={(data: Option) => setPeriodOptions(data)}
                  />
                </InputWrapper>
              )}

              {periodSelect.value === 'month' && periodOption.value === 'custom' && (
                <InputWrapper>
                  <TextField
                    fullWidth
                    onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                      const {
                        target: { value },
                      } = e;

                      setPeriodNumber({
                        value,
                        error: isNaN(Number(value)) || Number(value) > 31 || Number(value) <= 0,
                      });
                    }}
                    name="periodNumber"
                    label="Period"
                    variant="filled"
                    color="primary"
                    autoComplete="off"
                    value={periodNumber.value}
                    error={Boolean(periodNumber.error)}
                  />
                  {periodNumber.error ? (
                    <ErrorMessageBlock>Should be a valid number and less than 31</ErrorMessageBlock>
                  ) : null}
                  <SvgSprite className="inputIcon" width={20} height={20} fill="#004AFF" name="calendar" />
                </InputWrapper>
              )}
            </Container>

            <Container>
              <InputWrapper className="subscribers">
                <Controller
                  isMulti
                  options={Object.values(workersOptions)}
                  as={Select}
                  styles={SelectStyles}
                  defaultValue={task ? task.subscribedUsers.map(item => workersOptions[item]) : []}
                  control={control}
                  name="subscribedUsers"
                  label="Subscribers"
                  placeholder="Subscribers"
                  height={16}
                  width={22}
                  stroke="#004AFF"
                />
                <SvgSprite className="inputIcon" width={28} height={28} stroke="#004AFF" name="notifications" />
              </InputWrapper>
            </Container>
          </TopWrapper>

          <SendButton type="submit">
            <SpanText>Submit</SpanText>
          </SendButton>
        </FormWrapper>
      </Wrapper>
    </FormContainer>
  );
};

RecurringTasksForm.defaultProps = {
  task: null,
};

export default RecurringTasksForm;
