import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import styled from 'styled-components';
// Icons
import { ReactComponent as Doc } from 'assets/images/icons/post_add.svg';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        marginBottom: 0,
      },
      '& .MuiInputBase-root': {
        position: 'relative',
        background: 'rgba(255, 255, 255, 0.8)',
        paddingLeft: '40px',
      },
      '& .MuiFormLabel-root': {
        paddingLeft: '44px',
        fontSize: '16px',
        letterSpacing: '0.15px',
        color: '#004AFF',
      },
      '& .MuiFormLabel-root.Mui-error': {
        color: '#f44336',
      },
      '& .MuiFilledInput-input': {
        color: '#001E66',
      },
      '& .css-yk16xz-control': {
        background: 'rgba(255, 255, 255, 0.8)',
        border: 0,
        width: '100%',
      },
    },
  }),
);

export const FormWrapper = styled.form`
  max-width: 510px;

  .role {
    margin-bottom: 20px;
  }

  .projects {
    margin: 8px;
    margin-bottom: 20px;
    width: 100%;
  }

  .inputIcon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 21px;
  }

  .MuiSvgIcon-root {
    fill: #004aff;
  }

  .MuiFormControlLabel-root {
    margin-left: 8px;
  }

  .MuiFormControlLabel-label {
    color: #004aff;
  }
`;

export const SelectStyles = {
  option: (provided: any, state: any) => ({
    ...provided,
    borderBottom: 'none',
    color: state.isSelected ? '#24C9FC' : '#001E66',
    background: 'none',
    cursor: 'pointer',
    '&:hover': {
      color: '#24C9FC',
    },
  }),
  singleValue: (provided: any, state: any) => ({
    ...provided,
    color: '#004AFF',
    padding: '3px 0',
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    width: '100%',
    height: '51px',
    background: 'rgba(255, 255, 255, 0.8)',
    display: 'flex',
    paddingLeft: '47px',
  }),
  container: (provided: any, state: any) => ({
    ...provided,
    height: '100%',
    color: '#fff',
    borderColor: '#004AFF',
    cursor: 'pointer',
    marginBottom: state.selectProps.error ? 0 : 'inherit',
    '&:hover': {
      background: state.selectProps.menuIsOpen ? 'inherit' : '#004AFF',
    },
  }),
  menu: (provided: any, state: any) => ({
    ...provided,
  }),
  dropdownIndicator: (provided: any, state: any) => ({
    ...provided,
    paddingRight: '40px',
    color: state.selectProps.error ? '#f44336' : '#004AFF',
    transition: 'all .2s ease',
    transform: state.selectProps.menuIsOpen ? 'rotateX(180deg)' : null,
  }),
  placeholder: (provided: any, state: any) => ({
    ...provided,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.15px',
    color: state.selectProps.error ? '#f44336' : '#004AFF',
  }),
  indicatorSeparator: () => null,
};

export const InputWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 20px;
`;

export const DocIcon = styled(Doc)`
  top: 20px;
  left: 20px;
  position: absolute;
  width: 28px;
  height: 28px;
  fill: ${props => props.fill};
`;

export const SpanText = styled.span`
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  position: relative;
  z-index: 2;

  svg {
    fill: white;
  }
`;

export const SendButton = styled.button`
  margin: 8px;
  width: 125px;
  height: 51px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #004aff;
  border-radius: 10px;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
  border: none;
  position: relative;
  overflow: hidden;
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90.56deg, #004aff -0.92%, #24c9fc 100%);
    opacity: 0;
    transition: all 0.3s;
  }

  &:hover {
    &:before {
      opacity: 1;
    }
  }

  .MuiCircularProgress-colorPrimary {
    fill: white;
  }
`;

export const ErrorMessageBlock = styled.div`
  font-size: 0.75rem;
  letter-spacing: 0.1px;
  width: 100%;
  height: auto;
  padding-top: 5px;
  padding-left: 10px;
  padding-bottom: 5px;
  color: red;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  left: 0;
  bottom: 0;
  transform: translateY(100%);
`;
