import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 15px;
  border-radius: 3px;
  background-color: white;
  margin-bottom: 15px;
  width: 100%;
  display: flex;
  
  a {
    display: flex;
    width: 90%;
    text-decoration: none;
  }
`;

export const NameProject = styled.div`
  width: 150px;
  margin-right: 10px;
`;

export const TaskName = styled.div`
  width: 200px;
  margin-right: 10px;
`;

export const DateStart = styled.div`
  width: 200px;
  margin-right: 10px;
`;

export const StatusBlock = styled.div`
  margin-left: 30px;
`;

export const ArchiveBlock = styled.div`
    width: 10%;
    text-align: center;
`;

export const Label = styled.span`
  display: block;
  color: silver;
  font-size: 10px;
  text-transform: uppercase;
  margin-bottom: 5px;
`;

export const Title = styled.span`
  font-size: 16px;
  font-weight: 600;
  color: #3a3a3a;
`;

export const ArchiveBtn = styled.button`
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
`;
