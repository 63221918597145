import { TextField } from '@material-ui/core';
import CustomSelect from 'components/CustomSelect';
import SvgSprite from 'components/SvgSprite';
import React, { useCallback, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  FormWrapper,
  useStyles,
  InputWrapper,
  ErrorMessageBlock,
  InputGroupWrapper,
  SendButton,
  SpanText,
} from './styles';
import { RootState } from '../../../store/index';
import { useDispatch, useSelector } from 'react-redux';
import { ProjectsState } from '../../../store/projects/projectsTypes';
import { getDashboardTimelogs } from '../../../store/timelogs/timelogsActions';
import dayjs from 'dayjs';
import useRule from 'hooks/useRule';
import { getCurrentUser } from '../../../store/users/usersSelectors';
import { RULES_OPTIONS } from 'utils/constants';

const getWeekStartAndEnd = () => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const todayDayOfWeek = today.getDay() ? today.getDay() : 7;
  const firstOffset = Math.abs(todayDayOfWeek - 1);
  const firstDay = new Date(today);
  firstDay.setDate(firstDay.getDate() - firstOffset);

  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  tomorrow.setHours(0, 0, 0, 0);

  const lastDay = new Date(firstDay);
  lastDay.setDate(firstDay.getDate() + 6);

  return {
    firstDay: dayjs(firstDay).format('YYYY-MM-DD'),
    lastDay: dayjs(tomorrow.getTime() <= lastDay.getTime() ? today : lastDay).format('YYYY-MM-DD'),
  };
};

const GetTimelogsForm = () => {
  const classes = useStyles();
  const rule = useRule();

  const currentUser = useSelector(getCurrentUser);

  const { projects } = useSelector((state: RootState) => state.projects) as ProjectsState;
  const users = useSelector((state: RootState) => state.users.users);

  const dispatch = useDispatch();

  const defaultDates = useMemo(() => getWeekStartAndEnd(), []);

  const projectsOptions = useMemo(
    () => [
      { value: 'all', label: 'All' },
      ...Object.keys(projects).map(key => ({ value: projects[key].id, label: projects[key].name })),
    ],
    [projects],
  );

  const usersOptions = useMemo(
    () => [
      { value: 'all', label: 'All' },
      ...Object.keys(users).map(key => ({ value: users[key].id, label: users[key].name })),
    ],
    [users],
  );

  const { control, register, errors, handleSubmit } = useForm({
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    defaultValues: {
      from: defaultDates.firstDay,
      to: defaultDates.lastDay,
      project: projectsOptions[0],
      user: usersOptions[0],
    },
  });

  const onSubmit = useCallback(
    (data: {
      user: { value: string; label: string };
      project: { value: string; label: string };
      from: string;
      to: string;
    }) => {
      if (!currentUser) return;
      dispatch(
        getDashboardTimelogs({
          userId: rule === RULES_OPTIONS.admin.value ? data.user.value : currentUser.id,
          projectId: data.project.value,
          from: new Date(data.from),
          to: new Date(data.to),
        }),
      );
    },
    [dispatch, rule, currentUser],
  );

  return (
    <FormWrapper className={classes.root} onSubmit={handleSubmit(onSubmit)}>
      <InputGroupWrapper>
        <InputWrapper>
          <TextField
            fullWidth
            name="from"
            label="From"
            variant="filled"
            color="primary"
            autoComplete="off"
            type="date"
            defaultValue={defaultDates.firstDay}
            InputLabelProps={{
              shrink: true,
            }}
            inputRef={register({
              validate: (value): boolean => {
                const tomorrow = new Date();
                tomorrow.setDate(tomorrow.getDate() + 1);
                tomorrow.setHours(0, 0, 0, 0);
                return !isNaN(Date.parse(value)) && new Date(value) <= tomorrow;
              },
            })}
            error={Boolean(errors.from)}
          />
          {errors.from ? <ErrorMessageBlock>Should be a valid date and less or equal today</ErrorMessageBlock> : null}
          <SvgSprite className="inputIcon" width={20} height={20} fill="#004AFF" name="calendar" />
        </InputWrapper>

        <InputWrapper>
          <TextField
            fullWidth
            name="to"
            label="To"
            variant="filled"
            color="primary"
            autoComplete="off"
            type="date"
            defaultValue={defaultDates.lastDay}
            InputLabelProps={{
              shrink: true,
            }}
            inputRef={register({
              validate: (value): boolean => {
                const tomorrow = new Date();
                tomorrow.setDate(tomorrow.getDate() + 1);
                tomorrow.setHours(0, 0, 0, 0);
                return !isNaN(Date.parse(value)) && new Date(value) <= tomorrow;
              },
            })}
            error={Boolean(errors.to)}
          />
          {errors.to ? <ErrorMessageBlock>Should be a valid date and less or equal today</ErrorMessageBlock> : null}
          <SvgSprite className="inputIcon" width={20} height={20} fill="#004AFF" name="calendar" />
        </InputWrapper>
      </InputGroupWrapper>

      <InputGroupWrapper>
        <InputWrapper>
          <Controller
            as={CustomSelect}
            name="project"
            label="Project"
            defaultValue={projectsOptions[0]}
            options={projectsOptions}
            control={control}
            placeholder="Project"
            height={16}
            width={22}
            error={Boolean(errors.project)}
            stroke="#004AFF"
          />
          <SvgSprite className="inputIcon" width={28} height={28} stroke="#004AFF" name="folders" />
        </InputWrapper>
      </InputGroupWrapper>

      <InputGroupWrapper>
        {rule === RULES_OPTIONS.admin.value && (
          <InputWrapper>
            <Controller
              className="user"
              as={CustomSelect}
              name="user"
              label="User"
              defaultValue={usersOptions[0]}
              options={usersOptions}
              control={control}
              placeholder="User"
              height={16}
              width={22}
              error={Boolean(errors.user)}
              stroke="#004AFF"
            />
            <SvgSprite className="inputIcon" width={28} height={28} stroke="#004AFF" name="folders" />
          </InputWrapper>
        )}

        <SendButton type="submit">
          <SpanText>Submit</SpanText>
        </SendButton>
      </InputGroupWrapper>
    </FormWrapper>
  );
};

export default GetTimelogsForm;
