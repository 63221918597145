import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Wrapper,
  NameProject,
  Label,
  Title,
  TaskName,
  DateStart,
  StatusBlock,
  ArchiveBlock,
  ArchiveBtn,
} from './styles';
import DraftsIcon from '@material-ui/icons/Drafts';
import ArchiveConfirmModal from 'components/ArchiveConfirmModal';
import { useDispatch, useSelector } from 'react-redux';
import { getProjectById } from 'store/projects/projectsSelectors';
import dayjs from 'dayjs';
import { TaskStore } from 'store/tasks/tasksTypes';
import { RULES_OPTIONS, STATUSES_OPTIONS } from 'utils/constants';
import { fromStoreTaskToAPITask } from 'store/tasks/tasksHelpers';
import { updateTask } from 'store/tasks/tasksActions';

interface TaskCardProps {
  task: TaskStore;
  setSnackbarOpen: (value: boolean) => void;
  rule: string | undefined | null;
}

const TaskCard: React.FC<TaskCardProps> = ({ task, setSnackbarOpen, rule }) => {
  const [open, setOpen] = useState<boolean>(false);
  const project = useSelector(getProjectById(task.idProject || ''));

  const dispatch = useDispatch();

  const handleConfirm = useCallback(async () => {
    const { task: newTask, id } = fromStoreTaskToAPITask({ ...task, isArchive: true });

    await dispatch(updateTask(newTask, id));
    setOpen(false);
    setSnackbarOpen(true);
  }, [dispatch, task, setSnackbarOpen]);

  return (
    <Wrapper>
      <Link to={`/project/task/${task.id}`}>
        <NameProject>
          <Label>Project</Label>
          <Title>{project?.name || ''}</Title>
        </NameProject>
        <TaskName>
          <Label>Task name:</Label>
          <Title>{task.name}</Title>
        </TaskName>
        <DateStart>
          <Label>Date start:</Label>
          <Title>{task.dateCreated ? dayjs(task.dateCreated.toDate()).format('MMMM DD YYYY HH:mm') : null}</Title>
        </DateStart>
        <DateStart>
          <Label>Date end:</Label>
          <Title>{task.dateEnd ? dayjs(task.dateEnd.toDate()).format('MMMM DD YYYY HH:mm') : null}</Title>
        </DateStart>
        <StatusBlock>
          <Label>Status:</Label>
          <Title>{STATUSES_OPTIONS[task.status]?.label || ''}</Title>
        </StatusBlock>
      </Link>
      {rule === RULES_OPTIONS.admin.value && (
        <>
          <ArchiveBlock>
            <Label>Archive</Label>
            <ArchiveBtn onClick={() => setOpen(true)}>
              <DraftsIcon />
            </ArchiveBtn>
          </ArchiveBlock>
          <ArchiveConfirmModal
            text="Add task to archive?"
            handleConfirm={handleConfirm}
            open={open}
            setOpen={setOpen}
          />
        </>
      )}
    </Wrapper>
  );
};

export default TaskCard;
