import React, { forwardRef } from 'react';
import Select from 'react-select';
import { SelectWrapper, SelectStyles, Label } from './styles';
import SvgSprite from 'components/SvgSprite';

/**
 * use with Controller from react-form-hook
 */

const CustomSelect = forwardRef((props: any, ref: any) => {
  const { className, iconName, fill, stroke, height, width, error, label, value, icon: Icon, noMargin } = props;

  return (
    <SelectWrapper className={className} error={error} noMargin={noMargin}>
      <Label error={error}>{value && label}</Label>
      <Select {...props} className={className} styles={SelectStyles} error={error} ref={ref} />
      {Icon ? (
        <Icon className="Icon" width={width} height={height} fill={fill} stroke={stroke} />
      ) : (
        <SvgSprite name={iconName} className="Icon" width={width} height={height} fill={fill} stroke={stroke} />
      )}
    </SelectWrapper>
  );
});

export default CustomSelect;
