import React from 'react';
import { RecurringTasks } from 'store/tasks/tasksTypes';
import RecurringTasksItem from './RecurringTasksItem';

interface RecurringTasksListProps {
  tasks: RecurringTasks;
}

const RecurringTasksList = ({ tasks }: RecurringTasksListProps) => (
  <ul>
    {[...Object.values(tasks)]
      .sort((a, b) => b.createdAt.toMillis() - a.createdAt.toMillis())
      .map(item => (
        <RecurringTasksItem key={item.id} task={item} />
      ))}
  </ul>
);

export default RecurringTasksList;
