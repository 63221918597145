/* eslint-disable no-shadow */
import React, { useState, useRef, useCallback, useMemo, ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DragDropContext, DragDropContextProps, Droppable, Draggable } from 'react-beautiful-dnd';
import Dashboard from 'layouts/Dashboard';
import {
  FilterWrapper,
  Column,
  ContentWrapper,
  ContentColumn,
  ContentTitle,
  Content,
  ResetWrapper,
  ResetButton,
  RemoveIcon,
  FilterInput,
  FilterForm,
  ResetContent,
  ResetContentIcon,
  ResetContentWrapper,
  LoaderWrapper,
} from './styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import CustomSelect from 'components/CustomSelect';
import TaskItem from './TaskItem';
import { useLocalStorage } from 'utils/useLocalStorage';
import InfoTaskPopup from 'components/InfoTaskPopup';
import { getWorkers } from 'store/users/usersSelectors';
import { OptionGroup, Option } from 'utils/commonTypes';
import { updateTask, setEditError } from 'store/tasks/tasksActions';
import { getProjects } from 'store/projects/projectsSelectors';
import {
  getEditError,
  getAllTasks as getAllTasksSelector,
  getFilteredTasksByStatuses,
} from 'store/tasks/tasksSelectors';
import { fromStoreTaskToAPITask } from 'store/tasks/tasksHelpers';
import { Status } from 'store/tasks/tasksTypes';

const DashboardPage = () => {
  const [selectedWorker, setSelectedWorker] = useLocalStorage<string | null>('selectedWorker', null);
  const [selectedProject, setSelectedProject] = useLocalStorage<string | null>('selectedProject', null);
  const [searchQueryLocal, setSearchQueryLocal] = useLocalStorage<any>('searchQuery', '');

  const dispatch = useDispatch();
  const workers = useSelector(getWorkers);
  const projects = useSelector(getProjects);
  const allTasks = useSelector(getAllTasksSelector);
  const tasks = useSelector(
    getFilteredTasksByStatuses({ searchQuery: searchQueryLocal, worker: selectedWorker, project: selectedProject }),
  );
  const error = useSelector(getEditError);

  const workerSelectRef = useRef<any>(null);
  const projectSelectRef = useRef<any>(null);

  const [isDrag, setIsDrag] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  const selectWorkersOptions: OptionGroup = useMemo(
    () =>
      Object.values(workers).reduce(
        (acc, worker) => ({ ...acc, [worker.id]: { label: worker.name, value: worker.id } }),
        {},
      ),
    [workers],
  );

  const selectProjectsOptions: OptionGroup = useMemo(
    () =>
      Object.values(projects).reduce(
        (acc, project) => ({ ...acc, [project.id]: { label: project.name, value: project.id } }),
        {},
      ),
    [projects],
  );

  const resetFilters = () => {
    setSearchQueryLocal('');
    workerSelectRef.current.select.clearValue();
    projectSelectRef.current.select.clearValue();
  };

  const handleDragEnd: DragDropContextProps['onDragEnd'] = useCallback(
    async ({ source, destination, draggableId }) => {
      setIsDrag(true);
      dispatch(setEditError(false));
      if (!destination?.droppableId || source.droppableId === destination.droppableId) {
        setIsDrag(false);
        return;
      }
      const el = allTasks[draggableId];
      const { task: normalizedTask, id } = fromStoreTaskToAPITask(el);
      const status = destination.droppableId as Status;

      await dispatch(
        updateTask(
          {
            ...normalizedTask,
            status,
            dateCompleted: status === 'completed' ? new Date() : null,
          },
          id,
        ),
      );

      setIsDrag(false);
      setOpen(true);
    },
    [allTasks, dispatch],
  );

  const handleClose = useCallback((event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  }, []);

  const resetWorker = useCallback(() => {
    if (!selectedWorker) return;
    workerSelectRef.current.select.clearValue();
  }, [selectedWorker]);

  const resetProject = useCallback(() => {
    if (!selectedProject) return;
    projectSelectRef.current.select.clearValue();
  }, [selectedProject]);

  return (
    <Dashboard>
      <FilterWrapper>
        {(selectedWorker || selectedProject || searchQueryLocal) && (
          <ResetWrapper onClick={resetFilters}>
            Reset all filters
            <ResetButton>
              <RemoveIcon name="cancel" />
            </ResetButton>
          </ResetWrapper>
        )}
        <FilterForm>
          <FilterInput
            type="text"
            placeholder={selectedWorker || selectedProject ? 'Enter search query' : 'Select a worker or a project'}
            disabled={!selectedWorker && !selectedProject && true}
            value={searchQueryLocal}
            onChange={(event: ChangeEvent<HTMLInputElement>) => setSearchQueryLocal(event.target.value)}
          />
        </FilterForm>
        <Column>
          <ResetContentWrapper onClick={resetWorker}>
            <ResetContent>
              <ResetContentIcon name="cancel" />
            </ResetContent>
          </ResetContentWrapper>

          <CustomSelect
            ref={workerSelectRef}
            options={Object.values(selectWorkersOptions)}
            value={selectedWorker ? selectWorkersOptions[selectedWorker] : undefined}
            iconName="worker"
            width={25}
            height={25}
            className="select-item"
            placeholder="Filter by worker"
            name="worker"
            onChange={(data: Option) => setSelectedWorker(data ? data.value : null)}
            fill="#004AFF"
          />
        </Column>

        <Column>
          <ResetContentWrapper onClick={resetProject}>
            <ResetContent>
              <ResetContentIcon name="cancel" />
            </ResetContent>
          </ResetContentWrapper>
          <CustomSelect
            ref={projectSelectRef}
            options={Object.values(selectProjectsOptions)}
            value={selectedProject ? selectProjectsOptions[selectedProject] : undefined}
            iconName="folders"
            width={25}
            height={25}
            className="select-item"
            placeholder="Filter by project"
            name="project"
            onChange={(data: Option) => setSelectedProject(data ? data.value : null)}
            stroke="#004AFF"
          />
        </Column>
      </FilterWrapper>
      <LoaderWrapper>{isDrag && <CircularProgress size={28} />}</LoaderWrapper>
      <DragDropContext onDragEnd={handleDragEnd}>
        <ContentWrapper>
          <Droppable droppableId="backlog">
            {(provided, snapshot) => (
              <ContentColumn
                ref={provided.innerRef}
                {...provided.droppableProps}
                isDraggingOver={snapshot.isDraggingOver}
              >
                <ContentTitle>Backlog</ContentTitle>

                <Content>
                  {tasks.backlog.map((task, index) => (
                    <Draggable key={task.id} draggableId={task.id} index={index} isDragDisabled={isDrag}>
                      {provided => (
                        <div
                          key={task.id}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <TaskItem key={task.id} worker={task.userId ? workers[task.userId] : undefined} task={task} />
                        </div>
                      )}
                    </Draggable>
                  ))}
                </Content>
              </ContentColumn>
            )}
          </Droppable>
          <Droppable droppableId="in_work">
            {(provided, snapshot) => (
              <ContentColumn
                ref={provided.innerRef}
                {...provided.droppableProps}
                isDraggingOver={snapshot.isDraggingOver}
              >
                <ContentTitle>In Work</ContentTitle>

                <Content>
                  {tasks.in_work.map((task, index) => (
                    <Draggable key={task.id} draggableId={task.id} index={index} isDragDisabled={isDrag}>
                      {provided => (
                        <div
                          key={task.id}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <TaskItem key={task.id} task={task} worker={task.userId ? workers[task.userId] : undefined} />
                        </div>
                      )}
                    </Draggable>
                  ))}
                </Content>
              </ContentColumn>
            )}
          </Droppable>
          <Droppable droppableId="testing">
            {(provided, snapshot) => (
              <ContentColumn
                ref={provided.innerRef}
                {...provided.droppableProps}
                isDraggingOver={snapshot.isDraggingOver}
              >
                <ContentTitle>Testing</ContentTitle>

                <Content>
                  {tasks.testing.map((task, index) => (
                    <Draggable key={task.id} draggableId={task.id} index={index} isDragDisabled={isDrag}>
                      {provided => (
                        <div
                          key={task.id}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <TaskItem key={task.id} task={task} worker={task.userId ? workers[task.userId] : undefined} />
                        </div>
                      )}
                    </Draggable>
                  ))}
                </Content>
              </ContentColumn>
            )}
          </Droppable>
          <Droppable droppableId="completed">
            {(provided, snapshot) => (
              <ContentColumn
                ref={provided.innerRef}
                {...provided.droppableProps}
                isDraggingOver={snapshot.isDraggingOver}
              >
                <ContentTitle>Completed</ContentTitle>

                <Content>
                  {tasks.completed.map((task, index) => (
                    <Draggable key={task.id} draggableId={task.id} index={index} isDragDisabled={isDrag}>
                      {provided => (
                        <div
                          key={task.id}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <TaskItem key={task.id} worker={task.userId ? workers[task.userId] : undefined} task={task} />
                        </div>
                      )}
                    </Draggable>
                  ))}
                </Content>
              </ContentColumn>
            )}
          </Droppable>
        </ContentWrapper>
      </DragDropContext>
      <InfoTaskPopup open={open} onClose={handleClose} saveError={error} />
    </Dashboard>
  );
};

export default DashboardPage;
