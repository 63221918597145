import dayjs from 'dayjs';

export const getCurrentDate = (format: string = 'MMMM DD YYYY') => {
  const currentDate = dayjs();
  return dayjs(currentDate).format(format);
};

export const dateToFormat = (date: Date | string, format: string = 'MMMM DD YYYY HH:mm:ss') => {
  const currentDate = dayjs(date);
  return dayjs(currentDate).format(format);
};

export const getCurrentMilliseconds = (): number => dayjs().valueOf();

export const getDateMilliseconds = (date: Date | string): number => dayjs(date).valueOf();

export const millisecondsToFormat = (date: number | undefined, format: string = 'MMMM DD YYYY HH:mm') => {
  const currentDate = dayjs(date);
  return dayjs(currentDate).format(format);
};

export const secondsToHour = (secs: number) => {
  const h = Math.floor(secs / 3600);
  const m = Math.floor(secs % 3600 / 60);

  const hDisplay = h >= 10 ? `${h}` : `0${h}`;
  const mDisplay = m >= 10 ? `${m}` : `0${m}`;
  return `${hDisplay}:${mDisplay}`;
};
