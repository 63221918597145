const namespace = 'PROJECTS';

export const SET_PROJECTS = `${namespace}/SET_PROJECTS`;
export const ADD_PROJECT = `${namespace}/ADD_PROJECT`;

export interface Project {
  description: string;
  name: string;
  idTimeDoctor: number;
  isArchive: boolean;
  isEnabled: boolean;
}

export type ProjectStore = Project & {
  id: string;
};

export interface Projects {
  [key: string]: ProjectStore;
}

export interface ProjectsState {
  projects: Projects;
}
