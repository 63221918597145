import styled from 'styled-components';

export const SubscribeButton = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`;

export const TaskWrapper = styled.div`
  background: rgba(255, 255, 255, 0.4);
  margin: 5px;
  padding: 8px;
  color: rgba(39, 54, 134, 0.9);
  font-size: 14px;
  line-height: 18px;
  position: relative;

  a {
    color: inherit;
    text-decoration: initial;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`;

export const Name = styled.span`
  font-size: 14px;
  font-weight: 600;
  margin-right: 5px;
`;

export const Label = styled.span`
  font-size: 13px;
`;

export const Priority = styled.span`
  font-size: 12px;
  font-weight: 600;
`;

export const TaskTitle = styled.div`
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  max-width: 80%;
  padding-bottom: 10px;

  strong {
    font-size: 13px;
    font-weight: 900;
    padding-right: 5px;
    padding-bottom: 5px;
  }
`;

export const TaskDescr = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 12px;
`;

export const TaskWorker = styled.div`
  font-weight: 400;
  padding-top: 5px;
  padding-bottom: 15px;
`;

export const TaksDate = styled.div``;

export const TaskPriority = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 10px;
  & ${Priority} {
    color: ${({ color = '' }: { color?: string }) => color};
  }
`;
