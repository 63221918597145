import firebase from 'firebase/app';

const namespace = 'TIMELOGS';

export const SET_TIMELOGS = `${namespace}/SET_TIMELOGS`;
export const RESET_TIMELOGS = `${namespace}/RESET_TIMELOGS`;
export const ADD_TIMELOG = `${namespace}/ADD_TIMELOG`;
export const REMOVE_TIMELOG = `${namespace}/REMOVE_TIMELOG`;

export interface Timelog {
  id: string;
  creationDate: firebase.firestore.Timestamp;
  workDate: firebase.firestore.Timestamp;
  taskId: string;
  userId: string;
  projectId: string;
  time: number;
  description: string;
}

export interface TimelogState {
  timelogs: Timelog[];
}

export interface GroupedTimelog {
  taskId: string;
  totalTime: number;
  timelogs: Timelog[];
}
