import styled from 'styled-components';
import SvgSprite from 'components/SvgSprite';

export const FilterWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  position: relative;
  align-items: flex-end;
`;

export const FilterButton = styled.button`
  width: 30px;
  height: 30px;
  border-radius: 10px;
  padding: 2px;
  border-color: transparent;
  outline: none;
  background-color: transparent;
  margin-left: 10px;
  margin-right: 20px;
  text-align: center;
  font-size: small;
  cursor: pointer;
  position: absolute;
  right: -10px;
  color: #004aff;

  transition: all 0.3s ease-in-out;

  &:hover {
    width: 36px;
    height: 36px;
    color: #999999;
  }
`;

export const FilterForm = styled.div`
  display: flex;
  align-items: center;
  width: 400px;
  position: relative;
`;
export const FilterInput = styled.input`
  width: 100%;
  height: 56px;
  padding: 10px 40px 10px 18px;
  border: none;
  font-size: 16px;
  font-weight: 400;
  outline: none;
  color: #273686;
  transition: all 0.3s ease-in-out;
  background: ${props => props.disabled && '#fff'};
  &:hover {
    background-color: ${props => !props.disabled && '#CCDBFF'};
  }
  &::-webkit-input-placeholder {
    color: #004aff;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
`;

export const Column = styled.div`
  position: relative;
  width: 24%;
  height: 56px;
  background: #fff;
  display: flex;
  align-items: center;
  max-width: 280px;

  & .select-item {
    margin: 0;

    & .Icon {
      top: 15px;
    }
  }

  & .select-item-date {
    margin-top: 11px !important;
    text-align: center;
  }

  @media (max-width: 1380px) {
    max-width: 250px;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  height: calc(100% - 120px);
`;

export const ContentTitle = styled.div`
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
  background: rgba(0, 74, 255, 0.7);
  padding: 7px 28px 10px 28px;
  height: 38px;
  white-space: nowrap;
`;

export const ContentColumn = styled.div<DroppableRootProps>`
  width: 24%;
  background: rgba(0, 74, 255, 0.3);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-width: 280px;
  min-height: 650px;

  @media (max-width: 1380px) {
    max-width: 250px;
  }
`;
interface DroppableRootProps {
  isDraggingOver: boolean;
}
export const Content = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
`;

export const ResetWrapper = styled.div`
  display: flex;
  align-items: center;
  z-index: 99;
  position: absolute;
  top: -30px;
  right: 0;
  color: rgba(39, 54, 134, 0.9);
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    opacity: 0.6;
  }
`;

export const ResetButton = styled.button`
  display: block;
  border: 1px solid #fff;
  outline: none;
  border-radius: 50%;
  background: #fd6c61;
  margin-left: 10px;
`;

export const RemoveIcon = styled(SvgSprite)`
  fill: #fff;
  width: 10px;
  height: 10px;
  cursor: pointer;
`;

export const ResetContentWrapper = styled.div`
  position: absolute;
  right: 10px;
  top: 17px;
`;

export const ResetContent = styled.button`
  outline: none;
  border: 1px solid #fff;
  border-radius: 50%;
  background-color: #2e6cff;
  cursor: pointer;
  position: relative;
  z-index: 500;
  &:hover {
    opacity: 0.7;
  }
`;
export const ResetContentIcon = styled(SvgSprite)`
  width: 10px;
  height: 10px;
  fill: #fff;
`;

export const LoaderWrapper = styled.div`
  display: flex;
  margin: 0 auto;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
`;
