import { TimelineSeparator } from '@material-ui/lab';
import SvgSprite from 'components/SvgSprite';
import dayjs from 'dayjs';
import React, { MouseEvent, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { NotificationAction, NotificationStore } from 'store/notifications/notificationsType';
import {
  CustomTimelineDot,
  CustomTimelineConnector,
  CustomTimelineContent,
  CustomTimelineItem,
  CustomTimelineOppositeContent,
  ContentWrapper,
  DeleteButton,
} from './styles';
import TaskUpdateContent from './TaskUpdateContent';
import { useHistory } from 'react-router-dom';
import CommentContent from './CommentContent';
import { Close } from '@material-ui/icons';
import { deleteNotification } from 'store/notifications/notificationsActions';

interface NotificationsItemProps {
  notification: NotificationStore;
  isLast: boolean;
}

const taskNotifications: NotificationAction[] = ['projectUpdate', 'statusUpdate', 'workerUpdate'];

const NotificationsItem = ({ notification, isLast }: NotificationsItemProps) => {
  const dispatch = useDispatch();

  const history = useHistory();

  const onContentClick = useCallback(() => {
    history.push(`/project/task/${notification.taskId}`);
  }, [notification, history]);

  const onDeleteClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      dispatch(deleteNotification(notification.id));
    },
    [dispatch, notification],
  );

  return (
    <CustomTimelineItem>
      <CustomTimelineOppositeContent>
        {dayjs(notification.date.toDate()).format('YYYY-MM-DD HH:mm')}
      </CustomTimelineOppositeContent>
      <TimelineSeparator>
        <CustomTimelineDot>
          <SvgSprite name={notification.action} width={17} height={17} />
        </CustomTimelineDot>
        {!isLast && <CustomTimelineConnector />}
      </TimelineSeparator>
      <CustomTimelineContent>
        <ContentWrapper onClick={onContentClick}>
          {taskNotifications.includes(notification.action) ? (
            <TaskUpdateContent
              taskId={notification.taskId}
              action={notification.action}
              message={notification.message}
              projectId={notification.projectId}
            />
          ) : (
            <CommentContent
              action={notification.action}
              message={notification.message}
              taskId={notification.taskId}
              triggeredBy={notification.triggeredBy}
              projectId={notification.projectId}
            />
          )}
          <DeleteButton onClick={onDeleteClick}>
            <Close color="error" />
          </DeleteButton>
        </ContentWrapper>
      </CustomTimelineContent>
    </CustomTimelineItem>
  );
};

export default NotificationsItem;
