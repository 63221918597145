import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
`;

export const SideWrapper = styled.div<{ left?: boolean }>`
  margin-right: ${pops => (pops.left ? '20px' : 0)};
  display: flex;
  flex-direction: column;
`;

export const Highlighted = styled.span`
  color: #004aff;
`;
