import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getTaskById } from 'store/tasks/tasksSelectors';
import { getProjectById } from 'store/projects/projectsSelectors';
import { Wrapper, SideWrapper, Highlighted } from './styles';
import { getUserById } from 'store/users/usersSelectors';
import { STATUSES_OPTIONS } from 'utils/constants';
import { NotificationAction } from 'store/notifications/notificationsType';

interface TaskUpdateContentProps {
  taskId: string;
  action: NotificationAction;
  message: string;
  projectId: string;
}

const TaskUpdateContent = ({ taskId, action, message, projectId }: TaskUpdateContentProps) => {
  const newProject = useSelector(getProjectById(message));
  const oldProject = useSelector(getProjectById(projectId));
  const user = useSelector(getUserById(message));
  const task = useSelector(getTaskById(taskId));

  const status = useMemo(() => {
    const statusOption = message as keyof typeof STATUSES_OPTIONS;

    if (Object.keys(STATUSES_OPTIONS).includes(statusOption)) {
      return STATUSES_OPTIONS[statusOption].label;
    }
    return null;
  }, [message]);

  return (
    <Wrapper>
      <SideWrapper left>
        <span>Task:</span>
        <span>Project:</span>
        <span>
          {action === 'workerUpdate' && 'Assigned to:'}
          {action === 'statusUpdate' && 'Status change:'}
          {action === 'projectUpdate' && 'Moved to:'}
        </span>
      </SideWrapper>
      <SideWrapper>
        <span>{task?.name || ''}</span>
        <span>{oldProject?.name || ''}</span>
        <Highlighted>
          {action === 'workerUpdate' && (user?.name || '')}
          {action === 'projectUpdate' && (newProject?.name || '')}
          {action === 'statusUpdate' && status}
        </Highlighted>
      </SideWrapper>
    </Wrapper>
  );
};

export default TaskUpdateContent;
