import dayjs from 'dayjs';
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Timelog } from 'store/timelogs/timelogsTypes';
import { RootState } from 'store/index';
import { TimeItem, TimeItemCont, EditButton, DeleteButton } from './styles';
import TimelogModal from 'components/InfoTaskHeader/TimelogModal';
import { updateTimelog, deleteTimelog } from 'store/timelogs/timelogsActions';
import ConfirmModal from 'components/ConfirmModal';
import useRule from 'hooks/useRule';
import { getHoursAndMinutesFromTime } from 'components/InfoTaskHeader/TimelogModal/helpers';
import { getCurrentUser } from 'store/users/usersSelectors';
import { RULES_OPTIONS } from 'utils/constants';

const TimelogItem = ({ timelog }: { timelog: Timelog }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const users = useSelector((state: RootState) => state.users.users);
  const currentUser = useSelector(getCurrentUser);
  const dispatch = useDispatch();

  const rule = useRule();

  const onModalClose = () => setModalOpen(false);

  const onTimelogModalSubmit = useCallback(
    async (data: { time: number; workDate: Date; description: string }) => {
      await dispatch(
        updateTimelog({
          id: timelog.id,
          description: data.description,
          time: data.time,
          workDate: data.workDate,
        }),
      );
      onModalClose();
    },
    [dispatch, timelog.id],
  );

  const onDeleteTimelog = useCallback(async () => {
    await dispatch(deleteTimelog(timelog.id));
  }, [dispatch, timelog.id]);

  const closeConfirmModal = () => setConfirmModalOpen(false);

  const handleConfirmDelete = useCallback(() => {
    closeConfirmModal();
    onDeleteTimelog();
  }, [onDeleteTimelog]);

  return (
    <>
      <TimeItem>
        <TimeItemCont>{dayjs(timelog.creationDate.toDate()).format('YYYY-MM-DD HH:mm')}</TimeItemCont>
        <TimeItemCont>{dayjs(timelog.workDate.toDate()).format('YYYY-MM-DD')}</TimeItemCont>
        <TimeItemCont>{users[timelog.userId]?.name}</TimeItemCont>
        <TimeItemCont>{getHoursAndMinutesFromTime(timelog.time)}</TimeItemCont>
        <TimeItemCont>
          <pre>{timelog.description}</pre>
        </TimeItemCont>
        <TimeItemCont buttons>
          {(rule === RULES_OPTIONS.admin.value || timelog.userId === currentUser?.id) && (
            <>
              <EditButton type="button" onClick={() => setModalOpen(true)}>
                Edit
              </EditButton>
              <DeleteButton type="button" onClick={() => setConfirmModalOpen(true)}>
                Delete
              </DeleteButton>
            </>
          )}
        </TimeItemCont>
      </TimeItem>
      <TimelogModal open={modalOpen} handleClose={onModalClose} onSubmit={onTimelogModalSubmit} timelog={timelog} />
      <ConfirmModal
        open={confirmModalOpen}
        handleClose={closeConfirmModal}
        handleConfirm={handleConfirmDelete}
        title="Delete"
        description="Are you sure to delete this timelog?"
      />
    </>
  );
};

export default TimelogItem;
