import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  & .MuiCircularProgress-root {
    margin: 0 auto;
  }
`;

export const Title = styled.h3`
  font-size: 24px;
  line-height: 24px;
  font-weight: 700;
  margin-bottom: 25px;
`;

export const FilterWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 30px;
`;

export const Filter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 300px;
  position: relative;

  & .icon {
    width: 28px;
    height: 28px;
    position: absolute;
    fill: #004AFF;
    left: 95px;
    bottom: 12px;
    z-index: 0;
  }
`;

export const FilterTitle = styled.span`
  width: 100px;
`;

export const SubTitle = styled.span`
  font-size: 23px;
  font-weight: 700;
  display: block;
  margin-bottom: 20px;
`;

export const EmptyMessage = styled.div`
  text-align: center;
  font-size: 24px;
  font-weight: 700;
`;
