import React, { forwardRef } from 'react';
import { TextAreaWrapper, TextAreaHeader, TextAreaContent, ErrorText } from './styles';
import { ReactComponent as Notes } from '../../assets/images/icons/notes.svg';

const CustomTextArea = forwardRef((props: any, ref: any) => {
  const { error } = props;

  return (
    <TextAreaWrapper error={error}>
      <TextAreaHeader error={error}>
        <Notes style={{ marginRight: '16px' }} fill={error ? '#f44336' : '#004AFF'} />
        Description
      </TextAreaHeader>
      <TextAreaContent {...props} placeholder="Write description" ref={ref} />
      {props?.showError && <ErrorText>{error && 'Min description 10 symbols'}</ErrorText>}
    </TextAreaWrapper>
  );
});

export default CustomTextArea;
