import React from 'react';
import Dashboard from 'layouts/Dashboard';
import UserManegmentForm from 'components/UserManegmentForm';

const UserUserPage = () => (
  <Dashboard>
    <UserManegmentForm add />
  </Dashboard>
);

export default UserUserPage;
