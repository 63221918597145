import styled from 'styled-components';
import bgAuth from '../../assets/images/bgAuth.jpg';

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  background-image: url(${bgAuth});
  background-repeat: no-repeat;
  background-size: cover;
  padding: 79px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
