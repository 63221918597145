import React, { useState, useMemo, useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import CustomTextArea from 'components/CustomTextArea';
import CustomSelect from 'components/CustomSelect';
import UploadInput from 'components/UploadInput';
import {
  useStyles,
  FormWrapper,
  InputWrapper,
  DocIcon,
  SendButton,
  SpanText,
  ErrorMessageBlock,
  ErrorMessage,
} from './styles';
import { checkTypesFilesUpload } from 'utils/checkTypesFilesUpload';
import { TYPES_FOR_INPUT_TEXT } from 'utils/typesForCheck';
import { PRIORITY_OPTIONS } from 'utils/constants';
import { getProjects } from 'store/projects/projectsSelectors';
import { createTask, setCreatedTaskNumber } from 'store/tasks/tasksActions';
import { STATUSES_OPTIONS } from '../../../utils/constants';
import { getWorkers } from 'store/users/usersSelectors';
import { getCurrentUser } from '../../../store/users/usersSelectors';
import SvgSprite from 'components/SvgSprite';
import { validateTime } from 'components/InfoTaskHeader/TimelogModal/helpers';
import { getHoursAndMinutesFromTime } from '../../../components/InfoTaskHeader/TimelogModal/helpers';
import dayjs from 'dayjs';
import { Checkbox, FormControlLabel } from '@material-ui/core';

const AddTaskForm = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [timeError, setTimeError] = useState<string>('');

  const [loadingCreated, setLoadingCreated] = useState(false);

  const projects = useSelector(getProjects);

  const projectOptions = useMemo(
    () =>
      Object.keys(projects).map((item: string) => ({
        value: projects[item].name,
        label: projects[item].name,
        id: item,
      })),
    [projects],
  );

  const workers = useSelector(getWorkers);

  const workersOptions = useMemo(
    () => Object.keys(workers).map(item => ({ value: workers[item].id, label: workers[item].name })),
    [workers],
  );

  const currentUser = useSelector(getCurrentUser);

  const { control, register, handleSubmit, errors } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    defaultValues: {
      name: '',
      description: '',
      priority: Object.values(PRIORITY_OPTIONS)[0],
      project: null,
      estimate: getHoursAndMinutesFromTime(0, true),
      dateEnd: dayjs(new Date()).format('YYYY-MM-DD'),
      status: STATUSES_OPTIONS.created,
      worker: null,
      subscribe: true,
    },
    shouldFocusError: true,
  });
  const [filesList, setFilesList] = useState<File[]>([]);

  const handleFileInput = (files: File[]) => setFilesList(prev => [...prev, ...files]);

  const [badTypes, setBadTypes] = useState<[] | null>(null);

  const onSubmit = useCallback(
    async (data: any) => {
      setLoadingCreated(true);
      const subscribedUsers =
        data.worker && data.worker.value !== currentUser?.id ? [currentUser?.id, data.worker.value] : [currentUser?.id];

      const taskNumber = await dispatch(
        createTask({
          name: data.name,
          description: data.description,
          priority: data.priority.value,
          projectName: data.project.label,
          idProject: data.project.id,
          estimate: validateTime(data.estimate, true).time,
          dateEnd: data.dateEnd ? new Date(data.dateEnd) : new Date(),
          status: data.status.value,
          userId: data.worker?.value || null,
          subscribedUsers: data.subscribe && currentUser?.id ? subscribedUsers : [],
          files: filesList,
        }),
      );

      // @ts-ignore
      dispatch(setCreatedTaskNumber(taskNumber));
      setLoadingCreated(false);
      history.push('/request-processed');
    },
    [filesList, dispatch, history, currentUser],
  );

  const getPastedValue = useCallback(async (event: React.ClipboardEvent<HTMLDivElement>) => {
    let prepearedFiles: any = {};

    if (Object.values(event.clipboardData.files).length <= 0) {
      return;
    }
    event.preventDefault();
    const { files } = event.clipboardData;
    const fileValues: any = Object.values(files);
    prepearedFiles = checkTypesFilesUpload(fileValues, TYPES_FOR_INPUT_TEXT);
    if (prepearedFiles.available.length) {
      handleFileInput(prepearedFiles.available);
    }
    if (!prepearedFiles.notAvailable.length) {
      setBadTypes(null);
      return;
    }

    setBadTypes(prepearedFiles.notAvailable);
  }, []);

  if (!projectOptions.length) return null;

  return (
    <FormWrapper className={classes.root} onSubmit={handleSubmit(onSubmit)}>
      <InputWrapper>
        <TextField
          fullWidth
          label="Task name"
          name="name"
          variant="filled"
          color="primary"
          autoComplete="off"
          defaultValue=""
          inputRef={register({
            validate: (value): boolean => value.length > 3,
          })}
          error={Boolean(errors.name)}
        />
        {errors.name ? <ErrorMessage>Minimum 4 symbols</ErrorMessage> : null}
        <DocIcon fill={errors.name ? '#f44336' : '#004AFF'} />
      </InputWrapper>
      <InputWrapper>
        <Controller
          as={CustomSelect}
          defaultValue={null}
          backspaceRemovesValue
          escapeClearsValue
          isSearchable
          name="project"
          label="Project"
          control={control}
          options={projectOptions}
          placeholder="Projects"
          height={16}
          width={22}
          noMargin
          rules={{ required: true }}
          iconName="folders"
          error={Boolean(errors.project)}
          stroke={errors.project ? '#f44336' : '#004AFF'}
        />
        {errors.project ? <ErrorMessage>Select your project</ErrorMessage> : null}
      </InputWrapper>

      <InputWrapper>
        <Controller
          as={CustomTextArea}
          name="description"
          control={control}
          onPaste={(event: React.ClipboardEvent<HTMLDivElement>) => getPastedValue(event)}
          rules={{
            validate: (value): boolean => value.length >= 10,
          }}
          error={Boolean(errors.description)}
          defaultValue=""
        />
        {errors.description ? <ErrorMessage>Minimum 10 symbols</ErrorMessage> : null}
      </InputWrapper>
      <InputWrapper>
        <Controller
          className="priority"
          as={CustomSelect}
          name="priority"
          label="Priority"
          noMargin
          defaultValue={Object.values(PRIORITY_OPTIONS)[0]}
          iconName="equalizer"
          options={Object.values(PRIORITY_OPTIONS)}
          control={control}
          placeholder="Priority"
          height={16}
          width={22}
          error={Boolean(errors.priority)}
          stroke="#004AFF"
        />
        {errors.priority ? <ErrorMessage>Select priority</ErrorMessage> : null}
      </InputWrapper>

      <InputWrapper>
        <TextField
          fullWidth
          name="estimate"
          label="Estimate"
          variant="filled"
          color="primary"
          autoComplete="off"
          inputRef={register({
            validate: (value: string): boolean => {
              const validationResult = validateTime(value, true);
              if (validationResult.error) {
                setTimeError(validationResult.error);
                return false;
              }
              return true;
            },
          })}
          error={Boolean(errors.estimate)}
        />
        {errors.estimate ? <ErrorMessage>{timeError || 'Should be a valid time'}</ErrorMessage> : null}
        <SvgSprite className="inputIcon" width={20} height={20} fill="#004AFF" name="calendar" />
      </InputWrapper>

      <InputWrapper>
        <Controller
          as={CustomSelect}
          noMargin
          defaultValue={STATUSES_OPTIONS.created}
          name="status"
          label="Initial status"
          placeholder="Initial status"
          rules={{ required: true }}
          iconName="equalizer"
          error={Boolean(errors.status)}
          control={control}
          backspaceRemovesValue
          escapeClearsValue
          isSearchable
          height={16}
          stroke={errors.status ? '#f44336' : '#004AFF'}
          width={22}
          options={[{ ...STATUSES_OPTIONS.backlog }, { ...STATUSES_OPTIONS.created }]}
        />
        {errors.status ? <ErrorMessage>Select status</ErrorMessage> : null}
      </InputWrapper>

      <InputWrapper>
        <TextField
          fullWidth
          name="dateEnd"
          label="Deadline"
          variant="filled"
          color="primary"
          autoComplete="off"
          type="date"
          InputLabelProps={{
            shrink: true,
          }}
          defaultValue={dayjs(new Date()).format('YYYY-MM-DD')}
          inputRef={register({
            validate: (value): boolean => {
              const today = new Date();
              today.setHours(0, 0, 0, 0);
              return !isNaN(Date.parse(value)) && new Date(value) >= today;
            },
          })}
          error={Boolean(errors.dateEnd)}
        />
        {errors.dateEnd ? <ErrorMessage>Should be a valid date and more or equal today</ErrorMessage> : null}
        <SvgSprite className="inputIcon" width={20} height={20} fill="#004AFF" name="calendar" />
      </InputWrapper>

      <InputWrapper>
        <Controller
          as={CustomSelect}
          noMargin
          defaultValue={null}
          name="worker"
          label="Worker"
          placeholder="Worker"
          rules={{ required: false }}
          iconName="worker"
          error={Boolean(errors.status)}
          control={control}
          backspaceRemovesValue
          escapeClearsValue
          isSearchable
          height={16}
          stroke={errors.worker ? '#f44336' : '#004AFF'}
          width={22}
          options={workersOptions}
        />
        {errors.worker ? <ErrorMessage>Select worker</ErrorMessage> : null}
      </InputWrapper>

      <InputWrapper>
        <FormControlLabel
          control={<Checkbox defaultChecked />}
          inputRef={register()}
          label="Subscribe creator and worker"
          name="subscribe"
          color="#004AFF"
        />
      </InputWrapper>

      {badTypes && (
        <ErrorMessageBlock>
          <span>This files can not be uploaded {badTypes.join(', ')}. Invalid file type!!!</span>
        </ErrorMessageBlock>
      )}

      <UploadInput isEdit={false} onChange={handleFileInput} filesList={filesList} removeFileList={setFilesList} />
      <SendButton type="submit">
        <SpanText>{loadingCreated ? <CircularProgress /> : 'Add Ticket'}</SpanText>
      </SendButton>
    </FormWrapper>
  );
};

export default AddTaskForm;
