import { db } from 'utils/firebase';
import firebase from 'firebase/app';
import { Timelog } from './timelogsTypes';

const timelogsRef = db.collection('timelogs');

export const getTimelogsHelper = async ({
  userId,
  projectId,
  from,
  to,
}: {
  userId: string;
  projectId: string;
  from: Date;
  to: Date;
}) => {
  if (userId === 'all' && projectId === 'all') {
    const timelogs = await timelogsRef.where('workDate', '>=', from).where('workDate', '<=', to).get();
    return timelogs;
  }
  if (projectId === 'all') {
    const timelogs = await timelogsRef
      .where('workDate', '>=', from)
      .where('workDate', '<=', to)
      .where('userId', '==', userId)
      .get();
    return timelogs;
  }
  if (userId === 'all') {
    const timelogs = await timelogsRef
      .where('workDate', '>=', from)
      .where('workDate', '<=', to)
      .where('projectId', '==', projectId)
      .get();
    return timelogs;
  }
  const timelogs = await timelogsRef
    .where('workDate', '>=', from)
    .where('workDate', '<=', to)
    .where('userId', '==', userId)
    .where('projectId', '==', projectId)
    .get();
  return timelogs;
};

export const normalizeTimelogsData = (timelogs: firebase.firestore.QuerySnapshot): Timelog[] =>
  timelogs.docs.map(item => {
    const timelog = item.data();
    return {
      userId: timelog.userId || '',
      taskId: timelog.taskId || '',
      projectId: timelog.projectId || '',
      time: timelog.time || 0,
      creationDate: timelog.creationDate,
      workDate: timelog.workDate,
      description: timelog.description || '',
      id: item.id,
    };
  });

export const getDateProcessed = ({
  oldTotalTime,
  newTotalTime,
  oldDateProcessed,
}: {
  oldTotalTime: number;
  newTotalTime: number;
  oldDateProcessed: Date | null;
}) => {
  if (newTotalTime && !oldDateProcessed) {
    return new Date();
  }
  if (oldTotalTime && newTotalTime && oldDateProcessed) {
    return new Date(oldDateProcessed);
  }

  return null;
};
