import { Link } from 'react-router-dom';
import styled from 'styled-components';
import SvgSprite from 'components/SvgSprite';

export const ItemBlock = styled.div`
  padding: 10px 20px;
  background-color: white;
  border-radius: 3px;
  margin-bottom: 15px;

  a {
    display: flex;
    text-decoration: none;
  }
`;

export const DisableButton = styled.button<{ onClick: any; isEnabled: boolean }>`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: ${props => (!props.isEnabled ? 'green' : 'red')};
  border: none;
  background: none;
  cursor: pointer;
`;

export const EditButton = styled(Link)`
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #004aff;
  border: none;
  background: none;
  cursor: pointer;
`;

export const ArchiveButton = styled.button`
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
  margin-left: 20px;
`;

export const IconBlock = styled.div`
  width: 40px;
  height: 40px;
  min-width: 40px;
  border-radius: 50%;
  background-color: #bdbdbd;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Icon = styled(SvgSprite)`
  width: 24px;
  height: 24px;
  fill: white;
`;

export const ContentBlock = styled.div`
  flex-grow: 2;
`;

export const ItemName = styled.span`
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  display: block;
  color: black;
  margin-bottom: 5px;
`;

export const Description = styled.span`
  color: silver;
  font-size: 14px;
`;
