export const TYPES_FOR_INPUT_TEXT = [
  'audio/aac',
  'text/csv',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/x-abiword',
  'application/vnd.ms-fontobject',
  'application/epub+zip',
  'application/gzip',
  'application/x-freearc',
  'image/gif',
  'text/html',
  'image/vnd.microsoft.icon',
  'text/calendar',
  'application/java-archive',
  'video/x-msvideo',
  'image/jpeg',
  'text/javascript',
  'application/json',
  'application/ld+json',
  'audio/midi',
  'audio/x-midi',
  'application/vnd.amazon.ebook',
  'text/javascript',
  'audio/mpeg',
  'video/mpeg',
  'application/vnd.apple.installer+xml',
  'application/octet-stream',
  'application/vnd.oasis.opendocument.presentation',
  'application/vnd.oasis.opendocument.spreadsheet',
  'image/bmp',
  'application/vnd.oasis.opendocument.text',
  'audio/ogg',
  'video/ogg',
  'application/ogg',
  'audio/opus',
  'application/x-bzip',
  'font/otf',
  'image/png',
  'application/pdf',
  'application/php',
  'application/vnd.ms-powerpoint',
  'application/x-bzip2',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.rar',
  'application/x-csh',
  'application/rtf',
  'application/x-sh',
  'image/svg+xml',
  'application/x-shockwave-flash',
  'text/css',
  'application/x-tar',
  'image/tiff',
  'video/mp2t',
  'font/ttf',
  'text/plain',
  'application/vnd.visio',
  'audio/wav',
  'audio/webm',
  'video/webm',
  'image/webp',
  'font/woff',
  'font/woff2',
  'application/xhtml+xml',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/xml',
  'text/xml',
  'application/vnd.mozilla.xul+xml',
  'application/zip',
  'video/3gpp',
  'audio/3gpp',
  'video/3gpp2',
  'audio/3gpp2',
  'application/x-7z-compressed',
];

export const TYPES_FOR_DROPZONE = [
  '.exe',
  '.aac',
  '.abw',
  '.arc',
  '.avi',
  '.azw',
  '.bin',
  '.bmp',
  '.bz',
  '.bz2',
  '.csh',
  '.css',
  '.csv',
  '.doc',
  '.docx',
  '.eot',
  '.epub',
  '.gz',
  '.gif',
  '.htm',
  '.html',
  '.ico',
  '.ics',
  '.jar',
  '.jpeg',
  '.jpg',
  '.js',
  '.json',
  '.jsonld',
  '.mid',
  '.midi',
  '.mjs',
  '.mp3',
  '.mpeg',
  '.mpkg',
  '.odp',
  '.ods',
  '.odt',
  '.oga',
  '.ogv',
  '.ogx',
  '.opus',
  '.otf',
  '.png',
  '.pdf',
  '.php',
  '.ppt',
  '.pptx',
  '.rar',
  '.rtf',
  '.sh',
  '.svg',
  '.swf',
  '.tar',
  '.tif',
  '.tiff',
  '.ts',
  '.ttf',
  '.txt',
  '.vsd',
  '.wav',
  '.weba',
  '.webm',
  '.webp',
  '.woff',
  '.woff2',
  '.xhtml',
  '.xls',
  '.xlsx',
  '.xml',
  '.xul',
  '.zip',
  '.3gp',
  '.3g2',
  '.7z',
];
