import { Action } from 'utils/commonTypes';
import {
  SET_ALL_NOTIFICATIONS,
  Notification,
  NotificationAction,
  Notifications,
  NotificationStore,
  ADD_NOTIFICATION,
  REMOVE_NOTIFICATION,
  REMOVE_ALL_NOTIFICATIONS,
} from './notificationsType';
import store, { AppThunk } from '../index';
import { db } from 'utils/firebase';
import API from 'utils/api';
import getIdToken from 'utils/getIdToken';
import { logout } from 'store/users/usersActions';

const notificationsRef = db.collection('notifications');

export const setAllNotifications = (payload: Notifications): Action => ({ type: SET_ALL_NOTIFICATIONS, payload });

export const addNotification = (payload: NotificationStore): Action => ({ type: ADD_NOTIFICATION, payload });

export const removeNotification = (payload: string): Action => ({ type: REMOVE_NOTIFICATION, payload });
export const removeAllNotifications = (): Action => ({ type: REMOVE_ALL_NOTIFICATIONS, payload: null });

const notificationsChangeHandler = (dispatch: any, userId: string) => {
  notificationsRef
    .where('userId', '==', userId)
    .where('isRead', '==', false)
    .onSnapshot(querySnapshot => {
      querySnapshot.docChanges().forEach(change => {
        if (
          (change.type === 'added' &&
            !Object.keys(store.getState().notifications.notifications).includes(change.doc.id)) ||
          change.type === 'modified'
        ) {
          const data = { ...change.doc.data(), id: change.doc.id } as NotificationStore;
          dispatch(addNotification(data));
        }
      });
    });
};

export const getAllNotifications = (userId: string): AppThunk => async dispatch => {
  try {
    const token = await getIdToken();
    if (!token) {
      dispatch(logout());
      throw Error('Auth failed');
    }

    const notifications = await notificationsRef.where('userId', '==', userId).where('isRead', '==', false).get();

    const notificationsData: Notifications = notifications.docs.reduce(
      (acc, item) => ({ ...acc, [item.id]: { ...item.data(), id: item.id } }),
      {},
    );

    dispatch(setAllNotifications(notificationsData));

    notificationsChangeHandler(dispatch, userId);
  } catch (error) {
    console.log(error);
  }
};

export const setIsRead = (value: boolean, id: string, currentUserId: string): AppThunk => async dispatch => {
  try {
    const token = await getIdToken();
    if (!token) {
      dispatch(logout());
      throw Error('Auth failed');
    }
    await API.put(`/notification/${id}`, { isRead: value });

    dispatch(getAllNotifications(currentUserId));
  } catch (error) {
    console.log(error);
  }
};

export const createNotifications = ({
  action,
  message,
  taskId,
  triggeredBy,
  subscribers,
  projectId,
}: {
  action: NotificationAction;
  message: string;
  taskId: string;
  triggeredBy: string;
  subscribers: string[];
  projectId: string;
}): AppThunk => async dispatch => {
  const token = await getIdToken();
  if (!token) {
    dispatch(logout());
    throw Error('Auth failed');
  }
  const notifications = subscribers.reduce((acc, subscriberId) => {
    if (subscriberId === triggeredBy) return acc;
    return [
      ...acc,
      { action, message, triggeredBy, taskId, userId: subscriberId, isRead: false, projectId } as Notification,
    ];
  }, [] as Notification[]);

  try {
    await API.post('/notification/many', notifications);
  } catch (error) {
    console.log(error);
  }
};

export const deleteNotification = (id: string): AppThunk => async dispatch => {
  try {
    const token = await getIdToken();
    if (!token) {
      dispatch(logout());
      throw Error('Auth failed');
    }
    await API.delete(`/notification/${id}`);

    dispatch(removeNotification(id));
  } catch (error) {
    console.log(error);
  }
};

export const deleteAllNotifications = (): AppThunk => async dispatch => {
  try {
    const token = await getIdToken();
    if (!token) {
      dispatch(logout());
      throw Error('Auth failed');
    }

    await API.delete(`/notification`);

    dispatch(removeAllNotifications());
  } catch (error) {
    console.log(error);
  }
};
