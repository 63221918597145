import styled, { css } from 'styled-components';
import SvgSprite from '../SvgSprite';

export const Author = styled.span`
  font-size: 12px;
  display: block;
  margin-bottom: 5px;
  font-weight: 700;
`;

export const Date = styled.span`
  display: block;
  font-size: 10px;
  margin-bottom: 5px;
`;

export const MessageWrapper = styled.div`
  width: 100%;
  margin-bottom: 15px;
  overflow: hidden;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  display: flex;
  flex-direction: column;

  a {
    overflow: hidden;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
  }

  ${({ side }: { side: boolean }) =>
    side &&
    css`
      align-items: flex-end;
    `}
`;

export const FilesListWrapper = styled.div`
  width: 100%;
  margin: 5px 0;
  font-size: 14px;
  max-width: calc(50% - 15px);

  .file-list-wrapper.right {
    justify-content: flex-end;
  }

  .file-item {
    width: 50px;
    height: 50px;
    margin-bottom: 15px;
    cursor: pointer;

    .remove-button {
      width: 15px;
      height: 15px;
      right: -5px;
      top: -5px;

      svg {
        position: relative;
        top: -1px;
        width: 6px;
        height: 6px;
      }
    }

    .file-docs {
      width: 15px;
      height: 15px;
    }

    .file-name {
      font-size: 10px;
    }
  }
`;

export const TextMessage = styled.div`
  width: 100%;
  font-size: 14px;
  line-height: 16px;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
`;

export const MessageFlex = styled.div`
  flex-grow: 2;
  max-width: 100%;
`;

export const EditBlock = styled.div`
  margin-left: 5px;
  cursor: pointer;
`;

export const EditIcon = styled(SvgSprite)`
  width: 15px;
  height: 15px;
  fill: white;
`;

export const MessageBlock = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  padding: 10px;
  /* min-width: 80px; */
  background-color: white;
  border-bottom-left-radius: 15px;
  border-top-right-radius: 15px;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  /* width: fit-content; */
  margin-bottom: 2px;

  ${({ side }: { side: boolean }) =>
    side &&
    css`
      padding: 10px 5px 10px 10px;
      background-color: #3f51b5;
      color: white;
      border-radius: 0;
      border-bottom-right-radius: 15px;
      border-top-left-radius: 15px;

      ${Author} {
        text-align: left;
      }
    `}
`;

export const ShowBlock = styled.div`
  position: absolute;
  right: 20px;
  padding: 0;
  background-color: white;
  box-shadow: rgb(0 0 0 / 8%) 0px 4px 12px;
  border-radius: 3px;
  overflow: hidden;
  transition: all 0.3s;
`;

export const ShowItem = styled.div`
  display: block;
  padding: 5px 6px;
  color: black;
  font-size: 12px;
  cursor: pointer;
  font-weight: 600;
  background-color: white;
  transition: all 0.3s;

  &:hover {
    background-color: #e4e4e4;
  }
`;
