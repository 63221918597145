import React, { useCallback, useEffect } from 'react';
import { Route, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getIsLoggedIn, getIsLoading } from 'store/users/usersSelectors';

const UnregisteredRoute = (props: any) => {
  const isLoggedIn = useSelector(getIsLoggedIn);
  const isLoading = useSelector(getIsLoading);
  const history = useHistory();

  const redirectAuth = useCallback(() => {
    if (isLoggedIn && !isLoading) {
      history.push('/dashboard');
    }
  }, [isLoggedIn, history, isLoading]);

  useEffect(() => {
    redirectAuth();
  }, [isLoggedIn, redirectAuth]);

  return <Route {...props} />;
};

export default UnregisteredRoute;
