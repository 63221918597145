import React, { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, Label, Name, Coll, CollPriority, CollStatus, ArchiveBtn } from './styles';
import DraftsIcon from '@material-ui/icons/Drafts';
import ArchiveConfirmModal from 'components/ArchiveConfirmModal';
import { TaskStore } from 'store/tasks/tasksTypes';
import { PRIORITY_OPTIONS, STATUSES_OPTIONS } from 'utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { getProjectById } from 'store/projects/projectsSelectors';
import { fromStoreTaskToAPITask } from 'store/tasks/tasksHelpers';
import { updateTask } from 'store/tasks/tasksActions';

interface ITaskCard {
  task: TaskStore;
  setSnackbarOpen: (value: boolean) => void;
}

const TaskCard = ({ task, setSnackbarOpen }: ITaskCard) => {
  const colorPriority = useMemo(() => {
    switch (PRIORITY_OPTIONS[task.priority].label) {
      case 'Medium':
        return '#ffb60c';
      case 'Low':
        return '#15771d';
      default:
        return '#FF0C3E';
    }
  }, [task.priority]);

  const [openConfirm, setOpenConfirm] = useState(false);

  const dispatch = useDispatch();

  const project = useSelector(getProjectById(task.idProject));

  const handleConfirm = useCallback(async () => {
    const { task: newTask, id } = fromStoreTaskToAPITask({ ...task, isArchive: true });

    await dispatch(updateTask(newTask, id));
    setOpenConfirm(false);
    setSnackbarOpen(true);
  }, [dispatch, task, setSnackbarOpen]);

  return (
    <Card>
      <Link to={`/project/task/${task.id}`}>
        <Coll>
          <Label>Project</Label>
          <Name>{project?.name || null}</Name>
        </Coll>
        <Coll className="description">
          <Label>Task</Label>
          <Name>{task.name}</Name>
        </Coll>
        <CollStatus>
          <Label>Status</Label>
          <Name>{STATUSES_OPTIONS[task.status].label}</Name>
        </CollStatus>
        <CollPriority color={colorPriority}>
          <Label>Priority</Label>
          <Name>{PRIORITY_OPTIONS[task.priority].label}</Name>
        </CollPriority>
      </Link>
      <Coll>
        <Label>Archive</Label>
        <ArchiveBtn onClick={() => setOpenConfirm(true)}>
          <DraftsIcon />
        </ArchiveBtn>
      </Coll>
      <ArchiveConfirmModal
        text="Add task to archive?"
        open={openConfirm}
        handleConfirm={handleConfirm}
        setOpen={setOpenConfirm}
      />
    </Card>
  );
};

export default TaskCard;
