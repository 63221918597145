import styled from 'styled-components';
import SvgSprite from 'components/SvgSprite';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import { StylesConfig } from 'react-select';

export const SelectStyles: StylesConfig = {
  container: styles => ({ ...styles, width: 'calc(100% - 10px)' }),
};

export const ErrorMessageBlock = styled.div`
  font-size: 0.6rem;
  letter-spacing: 0.1px;
  width: 100%;
  height: auto;
  padding-top: 5px;
  padding-left: 0;
  padding-bottom: 5px;
  color: red;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  left: 0;
  bottom: 0;
  transform: translateY(100%);
`;

export const AddTimelogButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #004aff;
  border-radius: 10px;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
  border: none;
  position: relative;
  overflow: hidden;
  cursor: ${props => (props.disabled ? 'auto' : 'pointer')};
  padding: 9px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 50px;
  position: absolute;
  transform: translateX(100%);
  right: -20px;
  top: 0;

  .icon {
    z-index: 2;
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90.56deg, #004aff -0.92%, #24c9fc 100%);
    opacity: 0;
    transition: all 0.3s;
  }

  &:hover:not(:disabled) {
    &:before {
      opacity: 1;
    }
  }
`;

export const FlexWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 25px;
  border-bottom: 1px solid #b2c7fe;
  margin-bottom: 20px;
  min-width: 484px;
  max-width: 918px;

  .timeIndicator {
    width: 101px;
    height: 16px;
  }
`;

export const BottomWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 25px;
  border-bottom: 1px solid #b2c7fe;
  margin-bottom: 20px;

  .item {
    width: 50%;
  }

  .timeIndicator {
    width: 101px;
    height: 16px;
    margin: 0 auto;
  }

  @media screen and (min-width: 1001px) {
    display: none;
  }
`;

export const ColumnWrapper = styled.div<{ bottom?: boolean }>`
  width: calc(100% / 3);

  @media screen and (max-width: 1000px) {
    display: ${props => (props.bottom ? 'none' : 'block')};
    width: calc(100% / 2);
  }
`;

export const ProjectBlock = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;

  &.timeIndicatorBlock {
    height: 40px;
  }
`;

export const ProjectIconBlock = styled.div<{ cursor?: boolean; borderColor?: string }>`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${props => (props.cursor ? 'none' : '1px solid #4c7ffd')};
  border-radius: 50%;
  margin-right: 10px;
  cursor: ${props => (props.cursor ? 'pointer' : 'auto')};
  border-color: ${props => (props.borderColor ? props.borderColor : '#4c7ffd')};
`;

export const ProjectIcon = styled(SvgSprite)`
  width: 22px;
  height: 22px;

  fill: ${({ fill = '#4c7ffd' }: { fill?: string }) => fill};
`;

export const ProjectContent = styled.div`
  position: relative;
  width: calc(100% - 80px);

  .dateInput {
    position: absolute;
  }

  .MuiInputBase-input {
    padding: 0 !important;
  }
`;

export const NameProject = styled.span`
  font-size: 14px;
  font-weight: 600;
  display: block;
  margin-bottom: 5px;
  display: flex;
`;

export const DescriptionProject = styled.span`
  font-size: 12px;
  font-weight: 400;
  display: block;
  color: #565656;
`;

export const Wrapper = styled.div`
  position: relative;
  ${NameProject} {
    margin-bottom: initial;
  }
`;

export const ShowTimeButton = styled(Button)`
  &.MuiButton-root {
    padding: 0;
    line-height: initial;
    color: #ff5722;
    font-weight: 600;
  }
`;

export const TimeText = styled.span`
  font-size: 12px;
  font-weight: 400;
  display: block;
  color: #565656;
  margin-top: 6px;
`;

export const CircularWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 3px;

  & svg {
    color: #ff5722;
  }
`;

export const StyledInputName = styled(Input)`
  width: 100%;

  &.MuiInputBase-root {
    height: 12px;
    font-size: 12px;
    font-weight: 600;
    line-height: 24px;
  }

  &.MuiInputBase-root.Mui-disabled {
    color: initial;
    border: none;
  }

  &.MuiInput-underline.Mui-disabled:before {
    display: none;
  }
`;
