import { auth } from 'utils/firebase';
import { setAuth } from './api';

const getIdToken = async () => {
  const token = await auth.currentUser?.getIdToken();
  if (!token) return null;
  setAuth(token);
  return token;
};

export default getIdToken;
