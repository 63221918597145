import React, { useCallback, useMemo, useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { User } from 'store/users/usersTypes';
import { RootState } from 'store/index';
import { ProjectsState } from 'store/projects/projectsTypes';
import { ButtonsContainer, DisableButton, EditButton, ItemStyled, LeftWrapper, NameStyled, RoleStyled } from './styles';
import { useHistory } from 'react-router-dom';
import { toggleDisabled, deleteFromTimeDoctor, inviteUser } from 'store/users/usersActions';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { RULES_OPTIONS } from 'utils/constants';
import { getTimeDoctorUsers, getTimedoctorConfig } from 'store/timedoctor/timedoctorSelectors';
import { getTimeDoctorUsers as getTimeDoctorUsersAction } from 'store/timedoctor/timedoctorActions';

interface Props {
  user: User;
}

const UserManagementItem = ({ user }: Props) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [timeDoctorDialogOpen, setTimeDoctorDialogOpen] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const { projects } = useSelector((state: RootState) => state.projects) as ProjectsState;

  const timeDoctorUsers = useSelector(getTimeDoctorUsers);
  const timeDoctorConfig = useSelector(getTimedoctorConfig);

  const history = useHistory();

  const dispatch = useDispatch();

  const projectNamesString = useMemo(() => {
    const projectNames = user.projects.reduce((acc: string[], projectId: string) => {
      const project = projects[projectId];
      return project ? [...acc, project.name] : acc;
    }, []);
    const joinedProjectNames = projectNames.join(', ');
    return joinedProjectNames.length >= 27 ? `${joinedProjectNames.slice(0, 24)}...` : joinedProjectNames;
  }, [projects, user.projects]);

  const onDialogOpen = () => setDialogOpen(true);
  const onDialogClose = () => setDialogOpen(false);
  const onToggleDisabled = useCallback(async () => {
    await dispatch(toggleDisabled(user.id));
    onDialogClose();
  }, [user.id, dispatch]);

  const onTimeDoctorDialogOpen = () => setTimeDoctorDialogOpen(true);
  const onTimeDoctorDialogClose = () => setTimeDoctorDialogOpen(false);

  const onDeleteFromTimeDoctor = useCallback(async () => {
    await dispatch(deleteFromTimeDoctor(user.id));
    await dispatch(getTimeDoctorUsersAction());
    onTimeDoctorDialogClose();
  }, [user.id, dispatch]);

  const resetPassword = useCallback(async () => {
    await dispatch(inviteUser(user.id));
    setSnackOpen(true);
  }, [dispatch, user]);

  return (
    <ItemStyled>
      <LeftWrapper>
        <NameStyled>
          {user.name} ({user.email})
        </NameStyled>
        <RoleStyled>{RULES_OPTIONS[user.rule].label}</RoleStyled>
        <div>{projectNamesString}</div>
      </LeftWrapper>

      <ButtonsContainer>
        {timeDoctorUsers.includes(user.email) && timeDoctorConfig?.enabled && (
          <DisableButton type="button" onClick={onTimeDoctorDialogOpen} isDelete={false}>
            Delete user from TimeDoctor
          </DisableButton>
        )}

        <EditButton type="button" onClick={resetPassword}>
          Reset password
        </EditButton>

        <EditButton type="button" onClick={() => history.push(`/users/${user.id}`)}>
          Edit
        </EditButton>
        <DisableButton type="button" onClick={onDialogOpen} isDelete={user.isDelete}>
          {user.isDelete ? 'Enable' : 'Disable'}
        </DisableButton>
      </ButtonsContainer>

      <Dialog open={dialogOpen} onClose={onDialogClose} aria-labelledby="draggable-dialog-title">
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {user.isDelete ? 'Enable' : 'Disable'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure to {user.isDelete ? 'enable' : 'disable'} the user?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={onDialogClose}>
            Cancel
          </Button>
          <Button
            onClick={onToggleDisabled}
            style={{
              color: user.isDelete ? 'green' : 'red',
            }}
          >
            {user.isDelete ? 'Enable' : 'Disable'}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={timeDoctorDialogOpen} onClose={onTimeDoctorDialogClose} aria-labelledby="draggable-dialog-title">
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          Delete user from TimeDoctor
        </DialogTitle>
        <DialogContent>
          <DialogContentText>User can be restored only from admin panel. Are you sure to do that?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={onTimeDoctorDialogClose}>
            Cancel
          </Button>
          <Button
            onClick={onDeleteFromTimeDoctor}
            style={{
              color: 'red',
            }}
          >
            Delete user
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackOpen}
        autoHideDuration={5000}
        onClose={() => setSnackOpen(false)}
      >
        <MuiAlert variant="filled" severity="success">
          User will receive a reset password link
        </MuiAlert>
      </Snackbar>
    </ItemStyled>
  );
};

export default UserManagementItem;
