import { RootState } from '../index';

export const getAllNotifications = (state: RootState) =>
  Object.values(state.notifications.notifications).sort((a, b) => b.date.toMillis() - a.date.toMillis());

export const getUnreadNotificationsLength = (state: RootState) => {
  const allNotifications = getAllNotifications(state);

  return allNotifications.reduce((acc, item) => (item.isRead ? acc : acc + 1), 0);
};
